
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject, ElementRef } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { OrdersService } from 'src/app/shared/orders.service';

declare var google: any; // Declara la variable global de Google Maps

export interface DialogDataAgregar {
  file: any;
  costototal:String;
  
}
@Component({
  selector: 'app-DialogAgregarOrdenTransporte',
  templateUrl: './DialogAgregarOrdenTransporte.component.html',
  styleUrls: ['./DialogAgregarOrdenTransporte.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarOrdenTransporte implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MatPaginatorDetalle', { static: false }) paginatordetelle: MatPaginator;
  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  map!: google.maps.Map;
  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public detalleCrearOrden: any;
  public pedidosCantidad: any=0;
  public detalleCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public numeroOperacionSiguiente: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  private valorTotalDetalleTemporal: any=0;
  public isaprobar = false;
  public ismenudetalle = false;
  public iscargado = false;
  public iscontenido = true;
  private s_ano;
  private s_mes;
  private s_dia;
  private anos;
  private dias;
  private meses;
  public usuariosUnicos: any;
  subscription: Subscription;
  subscriptionenvio: Subscription;
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile

  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();
  tareasPorUsuario: { usuario: string, tareas: any[] }[] = [];
  constructor(public dialogRef: MatDialogRef<DialogAgregarOrdenTransporte>,private configuracionesService: ConfiguracionesService,private orderServiceTareas: OrdersService,private orderService: TareasTemporalService,     @Inject(MAT_DIALOG_DATA) public data: DialogDataAgregar, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog) {

  }
  seleccionarTodos: boolean = true; // No selecciona todos al inicio
  usuarioSeleccionado: any = null;
   displayedColumns: string[] = ['NumeroPed', 'RutaVLI', 'Placa', 'NombredeCliente', 'Direccion',
    'CostoMerc'];

    displayedColumnsDetalle: string[] = ['codigoArticulo', 'descripcion', 'ean', 'cantidad', 'valorNetoConIva',
    'numeroPedido'];
  
    dataSource = new MatTableDataSource();

    dataSourceDetalle = new MatTableDataSource();

  ngOnInit(): void {
    var negocio=localStorage.getItem("negocio");
    var agencia=localStorage.getItem("agencia");
    this.negocioAgencia=negocio+"-"+agencia
   this.parseExcel(this.data.file)
    this.descargar()
    this.rutasTemporales=0;
    this.valorTotalDetalleTemporal=0
    this.descargaranos()
    this.descargardias()
    this.descargarmeses()

    this.fechaTareasTemporal = localStorage.getItem("pedidoFecha");

    this.dialog = new Dialog({
      // Enables the header
      header: 'ERROR VALIDAR FORMATO',
      // Enables the close icon button in header
      showCloseIcon: true,
      visible: false,
      // Dialog content

      // The Dialog shows within the target element
      target: document.getElementById("container"),
      // Dialog width
      width: '500px',
      height: '1000px'

    });

    this.dialog.appendTo('#dialog');

   
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSourceDetalle.paginator=this.paginatordetelle

    const colombiaCenter = { longitud: 4.5709, latitud: -74.2973 }; // 🔹 Centro de Colombia
    this.initMap(colombiaCenter);


   
  }

  

  getcreate(order: any) {
    this.orderService.createTareaTemporal(order);
  }

  onNoClick(): void {

    this.dialogRef.close();

  }

  onDetelle(): void {
  
   
  
  }

  decodePolyline(encoded: string): { lat: number; lng: number }[] {
    let index = 0;
    const len = encoded.length;
    const array: { lat: number; lng: number }[] = [];
    let lat = 0;
    let lng = 0;
  
    while (index < len) {
      let b: number;
      let shift = 0;
      let result = 0;
  
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
  
      const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;
  
      shift = 0;
      result = 0;
  
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
  
      const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;
  
      array.push({ lat: lat * 1e-5, lng: lng * 1e-5 });
    }
  
    return array;
  }

  createConvexHull(points: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral[] {
    if (points.length < 3) return points;
  
    // Eliminar puntos duplicados
    const uniquePoints = Array.from(new Set(points.map(p => `${p.lat},${p.lng}`)))
      .map(p => {
        const [lat, lng] = p.split(',');
        return { lat: parseFloat(lat), lng: parseFloat(lng) };
      });
  
    // Ordenar puntos por latitud y luego por longitud
    const sorted = uniquePoints.sort((a, b) => a.lat === b.lat ? a.lng - b.lng : a.lat - b.lat);
  
    // Función para calcular el producto cruzado
    const crossProduct = (o: google.maps.LatLngLiteral, a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral) =>
      (a.lat - o.lat) * (b.lng - o.lng) - (a.lng - o.lng) * (b.lat - o.lat);
  
    // Construir la parte inferior del convex hull
    const lower: google.maps.LatLngLiteral[] = [];
    for (const point of sorted) {
      while (lower.length >= 2 && crossProduct(lower[lower.length - 2], lower[lower.length - 1], point) < 0) {
        lower.pop();
      }
      lower.push(point);
    }
  
    // Construir la parte superior del convex hull
    const upper: google.maps.LatLngLiteral[] = [];
    for (const point of sorted.reverse()) {
      while (upper.length >= 2 && crossProduct(upper[upper.length - 2], upper[upper.length - 1], point) < 0) {
        upper.pop();
      }
      upper.push(point);
    }
  
    // Eliminar el último punto de cada parte porque está duplicado
    upper.pop();
    lower.pop();
  
    // Combinar las partes inferior y superior
    return lower.concat(upper);
  }
  

  addGeozona(recorridos: any[], color:String): void {
    const points: google.maps.LatLngLiteral[] = recorridos.map(location => ({
      lat: location.lat,
      lng: location.lon
    }));



   
    
  
    // 🔹 1. Calcular el Convex Hull (envolvente convexa)
    const geozonaCoords = this.createConvexHull(points);
  
    // 🔹 2. Generar un color dinámico

  
    // 🔹 3. Dibujar la geozona en el mapa
    const geozona = new google.maps.Polygon({
      paths: geozonaCoords,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.3
    });
  
  
    geozona.setMap(this.map);

    recorridos.forEach((usuario: any) => {

      const   marker =  new google.maps.Marker({
        position: { lat: usuario.lat, lng: usuario.lon },
        map: this.map,
        text:usuario.orden.toString(), // Muestra el número en el centro
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: color,
          fillOpacity: 1,
          strokeWeight: 1
        }
      });   
      
   new google.maps.Marker({
        position: { lat: usuario.lat, lng: usuario.lon },
        map: this.map,
        label: {
          text:usuario.orden.toString(), // Muestra el número en el centro
          color: "#000", // Color del número
          fontSize: "8px",
          fontWeight: "normal",
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 0, // Hace invisible el ícono, solo se verá el texto
        }
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `
            <div style="
                color: black; /* Color del texto */
                font-size: 6px;
                padding: 1px;
                max-width: 160px;
                border-radius: 2px;
                text-align: center;
            ">
                <h4 style="margin: 0; font-size: 14px;">${usuario.nombre}</h4>
                <p style="margin: 0px 0; font-size: 12px;"><strong>Dirección:</strong> ${usuario.direccion}</p>
            </div>
        `
    });
    
    
      // Hacer zoom y centrar el mapa al hacer clic en el marcador
      marker.addListener("click", () => {
          this.map.setCenter(marker.getPosition() as google.maps.LatLng);
          this.map.setZoom(14); // Nivel de zoom al hacer clic
          infoWindow.open(this.map, marker);
      });
    
    });
  }


  initMap(center: any): void {
    const options: google.maps.MapOptions = {
      center: { lat: center.longitud, lng: center.latitud }, // 🔹 Centro en Colombia
      zoom: 7 // 🔹 Zoom inicial (ajusta según sea necesario)
  };

    this.map = new google.maps.Map(this.mapContainer.nativeElement, options);

 
  }
  descargaranos() {


    let fechaActual = new Date();
let anofechas = [];


  var ano = {
    fecha:    "2025",
   
  }

  anofechas.push(ano);

  this.anos = anofechas
  }

  descargarmeses() {

let fechaActual = new Date();
let mesesfechas = [];

var numerodia = String(fechaActual.getMonth()+ 1)
  if ( (fechaActual.getMonth()+ 1)<10){
    numerodia = "0" + String(fechaActual.getMonth() + 1)
  }

  var mes = {
    mes:    numerodia,
   
  }

  mesesfechas.push(mes);

  this.meses=mesesfechas

  }

  descargardias() {



    let fechaActual = new Date();
    let dias = [];
    let ultimoDia = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
    
    for (let i = fechaActual.getDate(); i <= ultimoDia.getDate(); i++) {

      var numerodia = String(i)
      if ( i<10){
        numerodia = "0" + String(i)
      }

      var dia = {
        dia:    numerodia,
       
      }
      dias.push(dia);
    }
    this.dias = dias  
  }


  sfdia(dato: any) {

    this.s_dia=dato

  }
  sfmes(dato: any) {

    this.s_mes=dato

  }

  sfano(dato: any) {

    this.s_ano=dato

  }

  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);

        let data = JSON.parse(json_object);

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

      

            };


            this.valorTotalTareasTemporal = 0;
            this.pesoKG=0;
            this.pedidosCantidad=0;
            var groupBy = function (xs, key) {
              return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };
            var rutasTareas = groupBy(data, 'Ruta')
            var zonasareas = groupBy(data, 'Zona')
            this.rutasTemporales=0;
            this.zonasTemporales=0;

            for (let item of Object.keys(rutasTareas)) {
           
              this.rutasTemporales = this.rutasTemporales + 1
            }

            for (let item of Object.keys(zonasareas)) {
           
              this.zonasTemporales = this.zonasTemporales + 1
            }

            this.dataSource.data = data;

            this.tareasCrearOrden=data

            const camposAValidar = [
              { campo: 'NombredeCliente', mensaje: 'Nombre de Cliente (Texto)', tipo: 'string' },
              { campo: 'Direccion', mensaje: 'Dirección(Texto)', tipo: 'string' },
              { campo: 'RutaVLI', mensaje: 'Ruta VLI(Texto)', tipo: 'string' },
              { campo: 'Placa', mensaje: 'Placa(Texto)', tipo: 'string' },
              { campo: 'NumeroPed', mensaje: 'Numero de Pedido(Texto)', tipo: 'any' }, 
              { campo: 'Zona', mensaje: 'Zona(Texto)', tipo: 'string' },
              { campo: 'Transporte', mensaje: 'Transporte(Texto)', tipo: 'string' },
              { campo: 'usuario', mensaje: 'Usuario(Texto)', tipo: 'any' },
              { campo: 'CostoMerc', mensaje: 'CostoMerc(Númerico)', tipo: 'number' },
              { campo: 'horaentrega', mensaje: 'horaentrega(Númerico)', tipo: 'number' },
              { campo: 'orden', mensaje: 'orden(Númerico)', tipo: 'number' },
              { campo: 'lat', mensaje: 'lat(Númerico)', tipo: 'number' },
              { campo: 'lon', mensaje: 'lon(Númerico)', tipo: 'number' },
              { campo: 'Peso neto', mensaje: 'Peso neto(Númerico)', tipo: 'number' },
              { campo: 'ventanaInicialAm', mensaje: 'ventanaInicialAm(Númerico)', tipo: 'number' },
              { campo: 'ventanaFinalAm', mensaje: 'ventanaFinalAm(Númerico)', tipo: 'number' },
            ];
            
            for (const { campo, mensaje, tipo } of camposAValidar) {
            
              const invalido = this.tareasCrearOrden.some(item => {
                const valor = item[campo];
            
                // Si no existe o es null/undefined
                if (valor === null || valor === undefined) {
                  return true;
                }
            
                // Si el tipo es "any", no validamos el tipo, solo que exista
                if (tipo === 'any') {
                  return false;
                }
            
                // Validación por tipo
                if (typeof valor !== tipo) {
                  return true;
                }
            
                // Validación adicional si es string (vacío o espacios)
                if (tipo === 'string' && valor.trim() === '') {
                  return true;
                }
            
                return false;
              });
            
              if (invalido) {
                this.toastr.errorToastr(`Hay registros sin ${mensaje} válido. Verifica los datos.`, 'Notificación');
                return; // Sale si encuentra algún error
              }
            }
            

            this.agruparTareasPorUsuario();
             this.dibujarGeozonas();

             this.usuariosUnicos = Array.from(
              new Set(this.tareasCrearOrden.map(item => item.usuario))
            ).map(usuario => ({ usuario }));
          
            console.log( "usuario uniso" ,this.usuariosUnicos);
          

            for (let tarea of data) {

              this.pedidosCantidad = this.pedidosCantidad+1 
              this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
              this.pesoKG = this.pesoKG + tarea.PesoNeto
          
          
            }

            this.valorTotalTareasTemporal= Math.trunc(this.valorTotalTareasTemporal)
            this.toastr.successToastr('Por favor cargar el archivo de detalles.', 'Notificación');
            this.ismenudetalle=true


          });


        });



        this.uploadObj.clearAll();



      }).bind(this), this);
    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  parseExcelDetalle(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });

      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log("detalle carga json_object",json_object);
        let data = JSON.parse(json_object);

        console.log("detalle carga",data);

        this.detalleCrearOrden=data;

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

              this.negocioAgencia=elemento.negocio

            };


            this.dataSourceDetalle.data = data;

            this.valorTotalDetalleTemporal = 0;
            this.detalleCantidad=0;
      

            for (let tarea of data) {

              this.detalleCantidad = this.detalleCantidad +1 
              this.valorTotalDetalleTemporal =  this.valorTotalDetalleTemporal + tarea.valorNetoConIva;

        
            }

            this.isaprobar=true


          });


        });


        this.uploadObj.clearAll();



      }).bind(this), this);

    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };
  public onSuccess(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);
  }

  public onSuccessDetalle(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcelDetalle(files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

 applyFilterDetalle(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDetalle.filter = filterValue.trim().toLowerCase();
  }
  descargar() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription =   this.orderService.getConsecutivo().subscribe(consecutivos => {

      for (let item of consecutivos) {
           
        var numeroSiguiente=1
        numeroSiguiente=numeroSiguiente+item.conoperacion
        this.numeroOperacion="OT"+numeroSiguiente
        this.numeroOperacionSiguiente=numeroSiguiente
      }      
      
     this.subscription.unsubscribe()
  
    });
  }

  consultarFecha() {


   
  }
  toggleTodos(): void {
    if (this.seleccionarTodos) {
      this.usuarioSeleccionado = null; // Desactivar la selección de usuario
    }
  }
  verificarSeleccion(): void {
    if (this.usuarioSeleccionado) {
      this.seleccionarTodos = false; // Desactivar "Seleccionar Todos"
    }
  }

  dibujarGeozonas(): void {

    const colombiaCenter = { longitud: 4.5709, latitud: -74.2973 }; // 🔹 Centro de Colombia
    this.initMap(colombiaCenter);


    const colores = [
      '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#800000', '#008000', '#000080', '#808000',
      '#800080', '#008080', '#C0C0C0', '#FFA500', '#FFC0CB', '#FFD700', '#E6E6FA', '#ADD8E6', '#F08080', '#90EE90',
      '#D3D3D3', '#FFB6C1', '#FFA07A', '#20B2AA', '#87CEFA', '#778899', '#B0C4DE', '#FFFFE0', '#00FF7F', '#FF4500',
      '#DA70D6', '#EEE8AA', '#98FB98', '#AFEEEE', '#DB7093', '#FFEFD5', '#FFDAB9', '#CD853F', '#FF6347', '#6A5ACD'
    ];
  
    if (this.seleccionarTodos) {
      // Dibujar geozonas para todos los usuarios
      const usuarios = this.crearModeloUsuarios();
      usuarios.forEach((usuario, index) => {
        const color = colores[index % colores.length]; // Asignar un color único
        const recorridos = usuario.tareas.map(tarea => ({
          lat: tarea.lat,
          lon: tarea.lon,
          orden: tarea.orden,
          nombre: tarea.NombredeCliente,
          direccion: tarea.Direccion
        }));
        this.addGeozona(recorridos, color);
      });
    } else if (this.usuarioSeleccionado) {
  
  
      // Filtrar las tareas del usuario seleccionado desde tareasCrearOrden
      const tareasUsuario = this.tareasCrearOrden.filter(tarea => tarea.usuario === this.usuarioSeleccionado.usuario);

  
      if (tareasUsuario.length > 0) {
        const color = colores[0]; // Usar el primer color de la lista
        const recorridos = tareasUsuario.map(tarea => ({
          lat: tarea.lat,
          lon: tarea.lon,
          orden: tarea.orden,
          nombre: tarea.NombredeCliente,
          direccion: tarea.Direccion
        }));
        this.addGeozona(recorridos, color);
      } else {
        console.log('El usuario seleccionado no tiene tareas.');
      }
    } else {
      console.log('Selecciona un usuario o marca "Seleccionar Todos".');
    }
  }

  crearModeloUsuarios(): { usuario: string, tareas: any[] }[] {
    const tareasAgrupadas = this.agruparTareasPorUsuario();
    return Object.keys(tareasAgrupadas).map(usuario => {
      return {
        usuario: usuario,
        tareas: tareasAgrupadas[usuario]
      };
    });
  }

  agruparTareasPorUsuario(): { [key: string]: any[] } {
    return this.tareasCrearOrden.reduce((acc, tarea) => {
      const usuario = tarea.usuario;
      if (!acc[usuario]) {
        acc[usuario] = [];
      }
      acc[usuario].push(tarea);
      return acc;
    }, {} as { [key: string]: any[] });
  }


  crearTipoDocumento(data: any) {

    console.log("this.valorTotalTareasTemporal",this.valorTotalTareasTemporal)
    console.log("this.data.costototal",this.data.costototal)

    const camposAValidar = [
      { campo: 'NombredeCliente', mensaje: 'Nombre de Cliente (Texto)', tipo: 'string' },
      { campo: 'Direccion', mensaje: 'Dirección(Texto)', tipo: 'string' },
      { campo: 'RutaVLI', mensaje: 'Ruta VLI(Texto)', tipo: 'string' },
      { campo: 'Placa', mensaje: 'Placa(Texto)', tipo: 'string' },
      { campo: 'NumeroPed', mensaje: 'Numero de Pedido(Texto)', tipo: 'any' }, 
      { campo: 'Zona', mensaje: 'Zona(Texto)', tipo: 'string' },
      { campo: 'Transporte', mensaje: 'Transporte(Texto)', tipo: 'string' },
      { campo: 'usuario', mensaje: 'Usuario(Texto)', tipo: 'any' },
      { campo: 'CostoMerc', mensaje: 'CostoMerc(Númerico)', tipo: 'number' },
      { campo: 'horaentrega', mensaje: 'horaentrega(Númerico)', tipo: 'number' },
      { campo: 'orden', mensaje: 'orden(Númerico)', tipo: 'number' },
      { campo: 'lat', mensaje: 'lat(Númerico)', tipo: 'number' },
      { campo: 'lon', mensaje: 'lon(Númerico)', tipo: 'number' },
      { campo: 'Peso neto', mensaje: 'Peso neto(Númerico)', tipo: 'number' },
      { campo: 'ventanaInicialAm', mensaje: 'ventanaInicialAm(Númerico)', tipo: 'number' },
      { campo: 'ventanaFinalAm', mensaje: 'ventanaFinalAm(Númerico)', tipo: 'number' },
    ];
    
    for (const { campo, mensaje, tipo } of camposAValidar) {
    
      const invalido = this.tareasCrearOrden.some(item => {
        const valor = item[campo];
    
        // Si no existe o es null/undefined
        if (valor === null || valor === undefined) {
          return true;
        }
    
        // Si el tipo es "any", no validamos el tipo, solo que exista
        if (tipo === 'any') {
          return false;
        }
    
        // Validación por tipo
        if (typeof valor !== tipo) {
          return true;
        }
    
        // Validación adicional si es string (vacío o espacios)
        if (tipo === 'string' && valor.trim() === '') {
          return true;
        }
    
        return false;
      });
    
      if (invalido) {
        this.toastr.errorToastr(`Hay registros sin ${mensaje} válido. Verifica los datos.`, 'Notificación');
        return; // Sale si encuentra algún error
      }
    }
    
  
    // Validación de costos
    if (this.valorTotalTareasTemporal === Number(data.costototal)) {
  
      // Validación de fecha
      if (this.s_ano && this.s_mes && this.s_dia) {
        this.crearOperacion();
        this.toastr.successToastr('Costo correcto.', 'Notificación');
  
      } else {
        this.toastr.errorToastr('Todos los campos de fecha (año, mes, día) son obligatorios.', 'Notificación');
      }
  
    } else {
      this.toastr.errorToastr('El total del costo es diferente.', 'Notificación');
    }
  
  }
  

  
  


  crearOperacion():void {

    setTimeout(() => {
      this.dialogRef.close();
      localStorage.setItem("ordenTransporte",this.numeroOperacion);
      this.route.navigate(['/Planilla']);
   
    
     }, 3500);

    this.iscontenido=false
    this.iscargado=true
    this.isaprobar=false
    this.toastr.successToastr('Carga en progreso.', 'Notificación');
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    localStorage.setItem("ordenTransporteFecha", this.s_ano+"-"+this.s_mes+"-"+this.s_dia);
    var operacion = {
      conoperacion:    this.numeroOperacionSiguiente,
      contarea:   this.numeroOperacionSiguiente,

    }


    this.orderService.incrementarCodigo(operacion);
    var hoy = new Date();
    var minuto=  hoy.getMinutes() 
    var segundos=hoy.getSeconds()

    var minutefinal="00"
    var segundofinal="00"
    if(minuto<10){

      minutefinal="0"+minuto
    }else{
      minutefinal=String(minuto)
    }
    if(segundos<10){

      segundofinal="0"+segundos
    }else{
      segundofinal=String(segundos)
    }

    this.pedidosCantidad=0;
    this.valorTotalTareasTemporal = 0;

    this.pesoKG=0
    for (let tarea of this.tareasCrearOrden) {
      var ruta = {
        ruta: tarea.RutaVLI,
        usuario: String(tarea.usuario),
        id:1,
        estado:'disponible',
        placa: 'pendiente',
        fecha: this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio"),
      }
   
      console.log("negocio si llega ", localStorage.getItem("negocio"));
      console.log("agencia si llega ", localStorage.getItem("agencia"));
      console.log("tareasCrearOrden si llega ", this.tareasCrearOrden);
      this.orderServiceTareas.createRuta(ruta);
      this.pedidosCantidad = this.pedidosCantidad+1 
      this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
  
              this.pesoKG = this.pesoKG + tarea.PesoNeto
              tarea.idpedido = 'PENDIENTE';
              tarea.estado = 'PENDIENTE';
              tarea.observaciones = '';
              tarea.codigoOperacion=  this.numeroOperacion,
              tarea.codigoPlanilla=  this.numeroOperacion,
              tarea.codigoRegistro= " "
              tarea.fechaVL =  this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
              tarea.fechaoperacion=cValue,

              tarea.negocio = localStorage.getItem("negocio");
              tarea.agencia = localStorage.getItem("agencia");  
              tarea.lonTarea=0.0;
              tarea.latTarea=0.0;
              tarea.lonEnvio=0.0;
              tarea.latEnvio=0.0;
              tarea.devolucion=0.0;
              tarea.metros_reporte=0.0;
              tarea.metros_envio=0.0;
              tarea.sitio_reporte='';
              tarea.sitio_envio='';
              tarea.direccion_geo='';
              tarea.hora_envio='';
              tarea.hora_reporte='';
              tarea.usuario_app='';
              tarea.reporte='';
              tarea.foto_cliente='';
              tarea.foto_factura='';
              tarea.url_foto_cliente='';
              tarea.url_foto_factura='';
              tarea.estado_foto_cliente='';
              tarea.estado_foto_factura='';
              tarea.id_foto_cliente='';
              tarea.id_foto_factura='';
              tarea.geo_zona='';
              tarea.causal_estado='';
              tarea.estadoEnvio='';
              tarea.estadoEnvio='';
              tarea.recaudo=0.0;
              tarea.porcetaje_entrega=0.0;
              tarea.porcetaje_entrega_peso=0.0;
              tarea.porcetaje_novedad_peso=0.0;
              tarea.porcetaje_novedad=0.0;
              tarea.entrega_peso=0.0;
              tarea.novedad_peso=0.0;
              tarea.longitud_transporte=0.0;
              tarea.latitud_transporte=0.0;
              tarea.direccion_transporte='';
              tarea.hora_transporte='';
              tarea.metros_transporte='';
              tarea.estadoEnvio='PENDIENTE';
              console.log(tarea);
              
      //this.orderService.numeroRegistro(tarea);
      this.orderServiceTareas.createOrder(tarea);

    }
    var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
    var ordendetranspote = {
      codigodeorden:    this.numeroOperacion,
      tareas:    this.pedidosCantidad,
      valorconiva:   this.valorTotalTareasTemporal,
      rutas:   this.rutasTemporales,
      zonas:   this.zonasTemporales,
      peso:   this.pesoKG,
      fechacreacion:   this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      fechaoperacion:   this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      horacreacion:hora,
      negocio:localStorage.getItem("negocio"),
      agencia:localStorage.getItem("agencia"),
      estado:'Creada'
    }

    this.orderService.crearOrdenTransporte(ordendetranspote);

   
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };


    let planillas = groupBy(this.tareasCrearOrden, 'RutaVLI')


      for (let item of Object.keys(planillas)) {

      let tareasplanillas = this.tareasCrearOrden.filter(tarea => tarea.RutaVLI == item)

      var totalCosto=0
      var pesoKG=0
      var tareas=0
      var planilla=""
      var usuario=""
      
      for (let tareaplanilla of tareasplanillas) {
   
        totalCosto = totalCosto+tareaplanilla.CostoMerc
        pesoKG = pesoKG+tareaplanilla.PesoNeto
        planilla=tareaplanilla.RutaVLI
        usuario=tareaplanilla.RutaVLI
        tareas=tareas+1
  
      }

    
    var planillacrear = {
      planilla: planilla+this.numeroOperacionSiguiente,
      agencia:localStorage.getItem("agencia"),
      negocio:localStorage.getItem("negocio"),
      CostoMerc: totalCosto,
      fecha: this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      fechaoperacion:   this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      pesoKG:pesoKG,
      usuario:usuario,
      ordentransporte:this.numeroOperacion,
      estado:"En Tramite",
      tareas:tareas
     

    };

 
    this.orderService.createPlanillas(planillacrear);

    }
  

}



  
}
class ColorGenerator {
  private usedColors: Set<string> = new Set();

  generarColorFosforescenteOscuro(): string {
    let color: string;
    let attempts = 0; // Evita bucles infinitos

    do {
      // Colores base oscuros pero vibrantes
      const baseColors = [
        [180, 30, 30],  // Rojo profundo
        [30, 180, 30],  // Verde intenso
        [30, 30, 180],  // Azul eléctrico
        [160, 30, 160], // Púrpura oscuro
        [30, 160, 160], // Cian profundo
        [180, 100, 30]  // Naranja oscuro
      ];

      // Selecciona un color base aleatorio
      const base = baseColors[Math.floor(Math.random() * baseColors.length)];

      // Genera una variación fuerte dentro de un rango oscuro
      const r = Math.max(30, Math.min(200, base[0] + Math.floor(Math.random() * 80 - 40))); 
      const g = Math.max(30, Math.min(200, base[1] + Math.floor(Math.random() * 80 - 40))); 
      const b = Math.max(30, Math.min(200, base[2] + Math.floor(Math.random() * 80 - 40))); 
      const alpha = 0.9;

      color = `rgba(${r}, ${g}, ${b}, ${alpha})`;
      attempts++;

      // Si se generan demasiados intentos sin éxito, se rompe el bucle
      if (attempts > 10) break;

    } while (this.usedColors.has(color)); // Verifica si ya existe en el historial

    // Agrega el color al historial para evitar duplicados
    this.usedColors.add(color);

    return color;
  }

  resetColors(): void {
    this.usedColors.clear(); // Limpia el historial si es necesario
  }
  

  
}