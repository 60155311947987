import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-popups';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ellipse } from 'pdfmake-wrapper';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';

@Component({
  selector: 'app-fragmentIndicadoContent',
  templateUrl: './fragmentIndicadoContent.component.html',
  styleUrls: ['./fragmentIndicadoContent.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadoContent implements OnInit {

  meses: string[] = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  tipoSeleccion: 'hoy' | 'mes' = 'hoy';
  seleccionValor: string = '';

  seleccionado: string = 'hoy';
  fechaInicio: string = new Date().toISOString().split('T')[0]; // Fecha actual
  fechaFin: string = new Date().toISOString().split('T')[0]; // Fecha actual
  iscargado = false;
  mesSeleccionado: number = new Date().getMonth() + 1; // Mes actual
  tareas: any[] = [];
  selectedTabIndex: number = 0;
  private negocio: any;
  private agencia: any;
  tabs = [
    { label: 'Gestión' },
    { label: 'Costo' },
    { label: 'Costo Devolución' },
    { label: 'Cumplimiento' },
    { label: 'Ubicación' }
  ];
  


  selectTab(index: number): void {
    this.selectedTabIndex = index;
  }


  Remove(args): void {
    args.cancel = true;
  }

  constructor(private route: Router,private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager,private resumenService: ResumenTareasService) {

  }

  


  ngOnInit(): void {

    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this.negocio = localStorage.getItem("negocio");
      this.agencia = localStorage.getItem("agencia");
     
 
    localStorage.setItem("pedidoFecha",cValue);

  
    if (localStorage.getItem('rutaindicador') == 'si') {

      localStorage.setItem("rutaindicador", "no");

      



    }

    if (localStorage.getItem('pedidoFecha').length > 3) {


      

    } else {

      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      localStorage.setItem("pedidoFecha", cValue);

    }


  }

  cambiarMes(event: any) {
    const nuevoMes = Number(event.target.value);
    this.orderService.setMes(nuevoMes);
  }

  consultarTareas() {

    this.iscargado = true
    this.orderService.setRangoFechas(this.fechaInicio, this.fechaFin)
    //this.orderService.ejecutarConsulta()

    this.resumenService.getResumenTareas(this.negocio, this.agencia, this.fechaInicio, this.fechaFin)
    .subscribe({
      next: (data) => { 
        this.iscargado = false
        console.log('📌 Resumen consultarTareas:', data);

      },
      error: (error) => {
        this.iscargado = false
        console.error('❌ Error al obtener resumen:', error);
      }
    });
  }

}

