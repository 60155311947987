import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';

interface EstadoResumen {
  estado: string;
  count: number;
  totalPesoNeto: number;
  totalCosto: number;
}

interface RutaResumen {
  rutaVLI: string;
  estados: EstadoResumen[];
}

 interface ResumenTareas {
  rutas: RutaResumen[];
  totalPorEstado: EstadoResumen[];
  totalDeTareas: number;
  totalDelCosto: number;
}

interface ResultadoFiltrado {
  rutasFiltradas: RutaResumen[];
  novedades: EstadoResumen[];
  totalNovedades: number;
  totalCostoNovedades: number;
}

interface RutaResumen {
  ruta: string;
  txtTotalFac: number;
  tareas: number;
  CanExi: number;
  CanPar: number;
  CanPen: number;
  CanNov: number;
}

interface EstadoTareas {
  count: number;
  totalCosto: number;
}

@Component({
  selector: 'app-fragmentIndicadoresapidevolucion',
  templateUrl: './fragmentIndicadoresapidevolucion.component.html',
  styleUrls: ['./fragmentIndicadoresapidevolucion.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadoresapidevolucion implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('MatPaginator2', { static: false }) paginatordev: MatPaginator;
  @ViewChild('MatPaginator3', { static: false }) paginatorzona: MatPaginator;
  @ViewChild('MatPaginator4', { static: false }) paginatornov: MatPaginator;
  @ViewChild('MatPaginator5', { static: false }) paginatortipo: MatPaginator;
  @ViewChild('MatPaginator6', { static: false }) paginatorestado: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator9', { static: false }) paginatorestadovalor: MatPaginator;

  private negocio: any;
  private agencia: any;
  private usuario: any;
  private txtTotalFac: any;
  private txtTotalFacRuta: any;
  private txtPesoKG: any;
  private txtPesoKGDe: any;
  private txtPedidos: any;
  private txtPedidosRuta: any;
  private txtDias: any;
  private txtRutas: any;
  private txtNovedades: any;
  private txtPedidosDev: any;
  private txtPedidosDevZona: any;
  private txtCanGrupo: any;
  private txtDevGrupo: any;
  private txtGrupoPedidos: any;
  private txtGrupoDev: any;
  private txttipoNovedad: any;
  private txtFecha: any;


  //novedades

  private txtCanCliente: any;
  private txtCanComercial: any;
  private txtCanPlanta: any;
  private txtCanTransporte: any;
  private txtCanTerceros: any;

  private txtCosCliente: any;
  private txtCosComercial: any;
  private txtCosPlanta: any;
  private txtCosTransporte: any;
  private txtCosTerceros: any;

  private totalParcial: any;
  private canParcial: any;

  private totalParcialExi: any;
  private canParcialExi: any;

  private totalExitoso: any;
  private canExitoso: any;

  private totalPendiente: any;
  private canPendiente: any;

  private totalNovedad: any;
  private canNovedad: any;

  private totalGeneral: any;
  private canGeneral: any;

  private datosNovCan: any;
  private datosNovValor: any;
  private datosPedidosRutas: any;
  private datosPedidosRutasValor: any;
  private datosEstadosValor: any;
  private datosZonas: any;
  private datosTipoNov: any;
  private datosNov: any;


  private tareasDescarga: any;
  private rutasDescarga: any;
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0

  estadoPendientec=0
  estadoExitosac=0
  estadoNovedadc=0
  estadoParcialc=0
  estadoRepisarc=0

  private subscription: Subscription;

  fechaInicio: string = '';
  fechaFin: string = '';

  myData = [
    { data: [1, 1, 3], name: "first" }
  ];

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args): void {
    args.cancel = true;
  }

  constructor(private route: Router,private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager,public resumenTareasService: ResumenTareasService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
       
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
 
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });
  }


  displayedColumnsDev: string[] = ['Zona', 'NombredeCliente', 'agencia', 'Peso neto', 'CostoMerc'];
  displayedColumnsDevZona: string[] = ['zona', 'totaltareas', 'txtTotalFac', 'CostoMercNov', 'CanNov', 'Porcentaje'];
 
 
  dataSourceDev = new MatTableDataSource();
  dataSourceDevZona = new MatTableDataSource();



  ngOnInit(): void {

    if (!this.subscription) {
      this.subscription = this.resumenTareasService.resumen$.subscribe((data) => {
        console.log('Usuario costo:', data);
        this.procesarDatos(data)
      });
    }




  }


  procesarDatos(data: any) {
         
    if (data !== null){

      this.txtFecha = `${this.orderService.getFechaInicio()} / ${this.orderService.getFechaFin()}`
      const novedades = this.filterNovedades(data);
      const exitosa = this.filterExitosa(data);
      const parcial = this.filterParcial(data);
      const repisar = this.filterRepisar(data);
      const pendientes = this.filterPendientes(data);
      this.txtTotalFac = data.totalDelCosto
      this.txtPedidos = data.totalDeTareas
      this.txtRutas = data.rutas.length
      this.txtPedidosDev = novedades.count
      this.txtNovedades = novedades.totalCosto
      this.txtPesoKG = data.totalPesoNeto
      this.txtPesoKGDe = novedades.totalPesoNeto

        this.dataSourceDevZona.data = data.tareaszona
        this.dataSourceDev.data = data.tareasdevolucioneszona
        
        this.estadoExitosa = exitosa.totalCosto
        this.estadoParcial = parcial.totalCosto
        this.estadoNovedad = novedades.totalCosto
        this.estadoPendiente = pendientes.totalCosto
        this.estadoRepisar = repisar.totalCosto
    }
  }
  filterNovedades(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "NOVEDAD");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  filterExitosa(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "EXITOSA");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  filterParcial(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "PARCIAL");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  filterRepisar(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "REPISAR");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }


  filterPendientes(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "PENDIENTE");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  ngAfterViewInit() {

    this.dataSourceDev.paginator = this.paginatordev;
    this.dataSourceDevZona.paginator = this.paginatorzona;

  }

 
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      console.log('Suscripción cancelada');
    }
  }

  atras():void {

    this.route.navigate(['/OrdenTransporte']);
    
  }

  clickExitosa():void {

   // this.route.navigate(['/TareasExitosas']);
    
  }

}

