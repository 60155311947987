import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';


@Component({
  selector: 'app-widget-areavgeo',
  templateUrl: './areavgeo.component.html',
  styleUrls: ['./areavgeo.component.scss']
})
export class Areavgeo implements OnInit {

  public negocio: any;
  public agencia: any;
  public usuario: any;
  public txtTotalFac: any;
  public txtTotalFacRuta: any;
  public txtPesoKG: any;
  public txtPesoKGDe: any;
  public txtPedidos: any;
  public txtPedidosRuta: any;
  public txtDias: any;
  public txtRutas: any;
  public txtNovedades: any;
  public txtPedidosDev: any;
  public txtPedidosDevZona: any;
  public txtCanGrupo: any;
  public txtDevGrupo: any;
  public txtGrupoPedidos: any;
  public txtGrupoDev: any;
  public txttipoNovedad: any;
  public txtFecha: any;


  //novedades

  public txtCanCliente: any;
  public txtCanComercial: any;
  public txtCanPlanta: any;
  public txtCanTransporte: any;
  public txtCanTerceros: any;

  public txtCosCliente: any;
  public txtCosComercial: any;
  public txtCosPlanta: any;
  public txtCosTransporte: any;
  public txtCosTerceros: any;
  public conteoSitio: any;
  public conteoNoSitio: any;
  public totalParcial: any;
  public canTotalExitosa: any;

  public canParcial: any;

  public totalParcialExi: any;
  public canParcialExi: any;

  public totalExitoso: any;
  public canExitoso: any;

  public totalPendiente: any;
  public canPendiente: any;

  public totalNovedad: any;
  public canNovedad: any;

  public totalGeneral: any;
  public canGeneral: any;

  public datospruebas: any;
  public datatrasnportes: any;
  public dataruta: any;

  public tareasDescarga: any;
  public rutasDescarga: any;


  subscriptionTransporte: Subscription;
  subscriptionTareas: Subscription;
  fechaInicio: string = '';
  fechaFin: string = '';
  chartOptions: {};
  @Input() data: any = [];
  private subscription: Subscription;
  Highcharts = Highcharts;

  constructor(private orderService: OrdersService, private userd: AuthService,private resumenService: ResumenTareasService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
  }

  ngOnInit() {

    if (!this.subscription) {
      this.subscription = this.resumenService.resumen$.subscribe((data) => {
        if (data !== null){
          this.chartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Avance'
            },
            xAxis: {
              categories: data.nombrerutas
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: 'bold',
                  color: ['#c23b22 ', '#77dd77', '#efa94a'],
                }
              }
            }, legend: {
              align: 'right',
              x: -30,
              verticalAlign: 'top',
              y: 10,
              floating: true,
              backgroundColor:
                ['#c23b22 ', '#77dd77', '#efa94a'],
              borderColor: '#CCC',
              borderWidth: 1,
              shadow: false
            }, tooltip: {
              headerFormat: '<b>{point.x}</b><br/>',
              pointFormat: '{series.name}: {point.y} ({point.percentage:.1f}%)<br/>Total: {point.stackTotal}'
      
            },
            plotOptions: {
              column: {
                stacking: 'percent',
                dataLabels: {
                  enabled: true,
                  formatter: function() {
                    return Math.round(this.percentage) + '%';
                  }
                }
              }
            }, series: [{
              name: 'En Progreso',
              data: data.listapendientes,
              color: "#e23a3a"
            }, {
              name: 'No Sitio',
              data: data.listanositio,
              color: "#d05bff"
            }, {
              name: 'Sitio',
              data: data.listasitio,
              color: "#439e37"
            }]
      
          };
        }else{
          this.chartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Avance'
            },
            xAxis: {
              categories: []
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: 'bold',
                  color: ['#c23b22 ', '#77dd77', '#efa94a'],
                }
              }
            }, legend: {
              align: 'right',
              x: -30,
              verticalAlign: 'top',
              y: 10,
              floating: true,
              backgroundColor:
                ['#c23b22 ', '#77dd77', '#efa94a'],
              borderColor: '#CCC',
              borderWidth: 1,
              shadow: false
            }, tooltip: {
              headerFormat: '<b>{point.x}</b><br/>',
              pointFormat: '{series.name}: {point.y} ({point.percentage:.1f}%)<br/>Total: {point.stackTotal}'
      
            },
            plotOptions: {
              column: {
                stacking: 'percent',
                dataLabels: {
                  enabled: true,
                  formatter: function() {
                    return Math.round(this.percentage) + '%';
                  }
                }
              }
            }, series: [{
              name: 'En Progreso',
              data: [],
              color: "#e23a3a"
            }, {
              name: 'No Sitio',
              data: [],
              color: "#d05bff"
            }, {
              name: 'Sitio',
              data: [],
              color: "#439e37"
            }]
      
          };
        } 
      
      
    
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      console.log('Suscripción cancelada');
    }
  }

}
