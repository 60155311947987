import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { HttpModule, JsonpModule } from '@angular/http';
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { AgmCoreModule } from '@agm/core';
import { TableModule } from 'primeng/table';
// Firebase 
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { CheckBoxModule  } from '@syncfusion/ej2-angular-buttons';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { SharedModule } from 'src/app/shared/shared.module';
import {APP_BASE_HREF} from '@angular/common';
import { AdminModule } from './components/admin/admin.module';
import { LoginModule } from './components/auth/login/login.module';
import { MatFormFieldModule, MatInputModule ,MatDialogModule,MatButtonModule,MatDialogActions,MatDialogContent,MatDialogClose,MatDialogTitle, MatToolbarModule, MatListModule, MatDividerModule, MatIconModule, MatMenuModule, MatProgressBarModule} from '@angular/material';
import { ToastrModule } from 'ng6-toastr-notifications';
import { DialogEditarBanco } from './modules/slcweb/dialogEditarBanco/dialogEditarBanco.component';
import { DialogAgregarBanco } from './modules/slcweb/dialogAgregarBanco/dialogAgregarBanco.component';
import { DialogEliminarBanco } from './modules/slcweb/dialogEliminarBanco/dialogEliminarBanco.component';
import { DialogAgregarTipoDoc } from './modules/slcweb/tipoDoc/dialogAgregarTipoDoc/dialogAgregarTipoDoc.component';
import { DialogEliminarTipoDoc } from './modules/slcweb/tipoDoc/dialogEliminarTipoDoc/dialogEliminarTipoDoc.component';
import { DialogEditarTipoDoc } from './modules/slcweb/tipoDoc/dialogEditarTipoDoc/dialogEditarTipoDoc.component';
import { DialogAgregarTipoTel } from './modules/slcweb/tipoTel/dialogAgregarTipoTel/dialogAgregarTipoTel.component';
import { DialogEliminarTipoTel } from './modules/slcweb/tipoTel/dialogEliminarTipoTel/dialogEliminarTipoTel.component';
import { DialogEditarTipoTel } from './modules/slcweb/tipoTel/dialogEditarTipoTel/dialogEditarTipoTel.component';
import { DialogAgregarTipoOP } from './modules/slcweb/tipoOpereacion/dialogAgregarTipoOp/dialogAgregarTipoOp.component';
import { DialogEliminarTipoOp } from './modules/slcweb/tipoOpereacion/dialogEliminarTipoOp/dialogEliminarTipoOp.component';
import { DialogEditarTipoOp } from './modules/slcweb/tipoOpereacion/dialogEditarTipoOp/dialogEditarTipoOp.component';
import { DialogAgregarTipoVehiculo } from './modules/slcweb/tipoveniculos/dialogAgregarTipoVehiculo/dialogAgregarTipoVehiculo.component';
import { DialogEliminarTipoVehiculo } from './modules/slcweb/tipoveniculos/dialogEliminarTipoVehiculo/dialogEliminarTipoVehiculo.component';
import { DialogEditarTipoVehiculo } from './modules/slcweb/tipoveniculos/dialogEditarTipoVehiculo/dialogEditarTipoVehiculo.component';
import { DialogAgregarMarca } from './modules/slcweb/marcavehiculo/dialogAgregarMarca/dialogAgregarMarca.component';
import { DialogEliminarMarca } from './modules/slcweb/marcavehiculo/dialogEliminarMarca/dialogEliminarMarca.component';
import { DialogEditarMarca } from './modules/slcweb/marcavehiculo/dialogEditarMarca/dialogEditarMarca.component';
import { DialogAgregarVehiculo } from './modules/slcweb/vehiculo/dialogAgregarVehiculo/dialogAgregarVehiculo.component';
import { DialogAgregarConfiVh } from './modules/slcweb/configuracionVehiculo/dialogAgregarConfiVh/dialogAgregarConfiVh.component';
import { DialogEliminarConfiVh } from './modules/slcweb/configuracionVehiculo/dialogEliminarConfiVh/dialogEliminarConfiVh.component';
import { DialogEditarConfiVh } from './modules/slcweb/configuracionVehiculo/dialogEditarConfiVh/dialogEditarConfiVh.component';
import { DialogEditarVehiculo } from './modules/slcweb/vehiculo/dialogEditarVehiculo/dialogEditarVehiculo.component';
import { DialogAgregarTercero } from './modules/slcweb/tercero/dialogAgregarTercero/dialogAgregarTercero.component';
import { DialogAgregarDepartamentos } from './modules/slcweb/localizacion/deparmentos/dialogAgregarDepartamentos/dialogAgregarDepartamentos.component';
import { DialogEliminarDepartamento } from './modules/slcweb/localizacion/deparmentos/dialogEliminarDepartamento/dialogEliminarDepartamento.component';
import { DialogEditarDepartamento } from './modules/slcweb/localizacion/deparmentos/dialogEditarDepartamento/dialogEditarDepartamento.component';
import { DialogAgregarCiudades } from './modules/slcweb/localizacion/ciudad/dialogAgregarCiudades/dialogAgregarCiudades.component';
import { DialogEliminarCiudades } from './modules/slcweb/localizacion/ciudad/dialogEliminarCiudades/dialogEliminarCiudades.component';
import { DialogEditarCiudad } from './modules/slcweb/localizacion/ciudad/dialogEditarCiudad/dialogEditarCiudad.component';
import { DialogAgregarBarrio } from './modules/slcweb/localizacion/barrios/dialogAgregarBarrio/dialogAgregarBarrio.component';
import { DialogEliminarBarrio } from './modules/slcweb/localizacion/barrios/dialogEliminarBarrio/dialogEliminarBarrio.component';
import { DialogEditarBarrio } from './modules/slcweb/localizacion/barrios/dialogEditarBarrio/dialogEditarBarrio.component';
import { DialogEditarTercero } from './modules/slcweb/tercero/dialogEditarTercero/dialogEditarTercero.component';
import { DialogAgregarTransportadores } from './modules/slcweb/transportador/dialogAgregarTransportadores/dialogAgregarTransportadores.component';
import { DialogAgregarVehiculoTrans } from './modules/slcweb/transportador/dialogAgregarVehiculoTrans/dialogAgregarVehiculoTrans.component';
import { DialogAgregarPropietario } from './modules/slcweb/vehiculo/dialogAgregarPropietario/dialogAgregarPropietario.component';
import { DialogAgregarTerceroVh } from './modules/slcweb/vehiculo/dialogAgregarTerceroVh/dialogAgregarTerceroVh.component';
import { dialogAgregarPropietarioVehiculo } from './modules/slcweb/transportador/dialogAgregarPropietarioVehiculo/dialogAgregarPropietarioVehiculo.component';
import { DialogAgregarConductor } from './modules/slcweb/transportador/dialogAgregarConductor/dialogAgregarConductor.component';
import { DialogAgregarTerceroC } from './modules/slcweb/transportador/dialogAgregarTerceroC/dialogAgregarTerceroC.component';
import { DialogAgregarDocumentos } from './modules/slcweb/transportador/dialogAgregarDocumentos/dialogAgregarDocumentos.component';
import { DialogAgregarTenedor } from './modules/slcweb/vehiculo/dialogAgregarTenedor/dialogAgregarTenedor.component';
import { DialogAgregarTerceroTenedor } from './modules/slcweb/vehiculo/dialogAgregarTerceroTenedor/dialogAgregarTerceroTenedor.component';
import { DialogAgregarTerceroLicencia } from './modules/slcweb/transportador/dialogAgregarTerceroLicencia/dialogAgregarTerceroLicencia.component';
import { DialogAgregarOrdenTransporte } from './modules/slcweb/ordenTransporte/dialogAgregarOrdenTransporte/dialogAgregarOrdenTransporte.component';
import { DialogAgregarArchivo } from './modules/slcweb/ordenTransporte/dialogAgregarArchivo/dialogAgregarArchivo.component';
import { DialogAgregarDetalleOrden } from './modules/slcweb/ordenTransporte/dialogAgregarDetalleOrden/dialogAgregarDetalleOrden.component';
import { DialogAgregarArchivoDetalle } from './modules/slcweb/ordenTransporte/dialogAgregarArchivoDetalle/dialogAgregarArchivoDetalle.component';
import { DialogAprobarPlanilla } from './modules/slcweb/planilla/dialogAprobarPlanilla/dialogAprobarPlanilla.component';
import { DialogAprobarConsignacion } from './modules/slcweb/planilla/dialogAprobarConsignacion/dialogAprobarConsignacion.component';
import { DialogAgregarArticulo } from './modules/slcweb/maestros/dialogAgregarArticulo/dialogAgregarArticulo.component';
import { DialogPuntosMpas} from './modules/slcweb/fragmentMapsOT/dialogPuntosMpas/dialogPuntosMpas.component';
import { DialogNovedades} from './modules/slcweb/fragmentIndicadoresVrt/dialogNovedades/dialogNovedades.component';
import { DialogPendientes} from './modules/slcweb/fragmentIndicadoresVrt/dialogPendientes/dialogPendientes.component';
import { DialogExitosas } from './modules/slcweb/fragmentIndicadoresVrt/dialogExitosas/dialogExitosas.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { DialogAgregarArchivoGeo } from './modules/slcweb/ordenTransporte/dialogAgregarArchivoGeo/dialogAgregarArchivoGeo.component';
import { DialogAgregarOrdenTransporteGeo } from './modules/slcweb/ordenTransporte/dialogAgregarOrdenTransporteGeo/dialogAgregarOrdenTransporteGeo.component';
import { DialogAgregarEncuesta } from './modules/slcweb/tercero/dialogAgregarEncuesta/dialogAgregarEncuesta.component';
import { DialogAgregarEncuestaDetalle } from './modules/slcweb/tercero/dialogAgregarEncuestaDetalle/dialogAgregarEncuestaDetalle.component';
import { DialogAgregarEncuestaFecha } from './modules/slcweb/tercero/dialogAgregarEncuestaFecha/dialogAgregarEncuestaFecha.component';
import { DialogAgregarEncuestaSeleccion } from './modules/slcweb/tercero/dialogAgregarEncuestaSeleccion/dialogAgregarEncuestaSeleccion.component';
import { DialogEditarEncuestaDetalle } from './modules/slcweb/tercero/dialogEditarEncuestaDetalle/dialogEditarEncuestaDetalle.component';
import { DialogEditarEncuestaSeleccion } from './modules/slcweb/tercero/dialogEditarEncuestaSeleccion/dialogEditarEncuestaSeleccion.component';
import { DialogEditarEncuestaFecha } from './modules/slcweb/tercero/dialogEditarEncuestaFecha/dialogEditarEncuestaFecha.component';
import { DialogEliminarEncuesta } from './modules/slcweb/tipoDoc/dialogEliminarEncuesta/dialogEliminarEncuesta.component';
import { DialogEliminarEncuestaDetalle } from './modules/slcweb/tipoDoc/dialogEliminarEncuestaDetalle/dialogEliminarEncuestaDetalle.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DialogAgregarEncuestaPdf } from './modules/slcweb/tercero/dialogAgregarEncuestaPdf/dialogAgregarEncuestaPdf.component';
import { DialogEditarTarea } from './modules/slcweb/tercero/dialogEditarTarea/dialogEditarTarea.component';
import { ConfirmacionDialogComponent } from './confirmacion-dialog/confirmacion-dialog.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    
  ],
  imports: [
    BrowserModule,
    GridModule ,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    ToastrModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MatButtonModule,
    ScrollingModule,
    MatToolbarModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    TableModule,
    MatProgressBarModule,
    MatIconModule,
    MatMenuModule,
    AngularFireModule.initializeApp(environment.configFirebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey : 'AIzaSyDOSjwGUQPGkTHYgp3mrXGHzwhlPGPHfXM'
    }),
  
    
    SharedModule,MatCardModule,MatFormFieldModule,MatInputModule, CheckBoxModule,GridAllModule, UploaderModule, DialogModule, FormsModule, CommonModule, ReactiveFormsModule, HttpModule, JsonpModule, BrowserModule, AdminModule, LoginModule

  ],
 
  providers: [{provide: APP_BASE_HREF, useValue: '/'}],
  entryComponents:[DialogEditarBanco,DialogAgregarBanco,DialogEliminarBanco,DialogAgregarTipoDoc,DialogEliminarTipoDoc,DialogEditarTipoDoc,
    DialogAgregarTipoTel,DialogEliminarTipoTel,DialogEditarTipoTel,
    DialogAgregarTipoOP,DialogEliminarTipoOp,DialogEditarTipoOp,
    DialogAgregarTipoVehiculo,DialogEliminarTipoVehiculo,DialogEditarTipoVehiculo,
    DialogAgregarMarca,DialogEliminarMarca,DialogEditarMarca,DialogAgregarVehiculo,
    DialogAgregarConfiVh,DialogEliminarConfiVh,DialogEditarConfiVh,DialogEditarVehiculo,
    DialogAgregarTercero,DialogAgregarDepartamentos,DialogEliminarDepartamento,
    DialogEditarDepartamento,DialogAgregarCiudades,DialogEliminarCiudades,
    DialogEditarCiudad,DialogAgregarBarrio,DialogEliminarBarrio,DialogEditarBarrio,
    DialogEditarTercero, DialogAgregarTransportadores,DialogAgregarVehiculoTrans,
    DialogAgregarPropietario,DialogAgregarTerceroVh,dialogAgregarPropietarioVehiculo,
    DialogAgregarConductor,DialogAgregarTerceroC,DialogAgregarDocumentos,
    DialogAgregarTenedor,DialogAgregarTerceroTenedor,DialogEditarEncuestaFecha,
    DialogAgregarTerceroLicencia,DialogAgregarOrdenTransporte,DialogEditarTarea,
    DialogEditarEncuestaDetalle,DialogEditarEncuestaSeleccion,ConfirmacionDialogComponent,
    DialogEliminarEncuesta,DialogEliminarEncuestaDetalle,DialogAgregarEncuestaPdf,
    DialogAgregarArchivo,DialogAgregarDetalleOrden,DialogAgregarArchivoDetalle,
    DialogAprobarPlanilla,DialogAprobarConsignacion,DialogAgregarArticulo,DialogPuntosMpas,
    DialogNovedades,DialogPendientes,DialogExitosas,DialogAgregarArchivoGeo,DialogAgregarEncuestaSeleccion,
    DialogAgregarOrdenTransporteGeo,DialogAgregarEncuestaFecha,DialogAgregarEncuesta,DialogAgregarEncuestaDetalle],
  bootstrap: [AppComponent]
})
export class AppModule { }
