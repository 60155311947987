import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmacion-dialog',
  templateUrl: './confirmacion-dialog.component.html',
  styleUrls: ['./confirmacion-dialog.component.scss']
})
export class ConfirmacionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmacionDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: any // Datos de la tarea
  ) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  crearTipoDocumento(data: any): void {
    // Lógica para guardar los cambios
    this.dialogRef.close(data);
  }
}
