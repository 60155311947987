
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { DialogAgregarDetalleOrden } from '../dialogAgregarDetalleOrden/dialogAgregarDetalleOrden.component';
export interface DialogDataAgregar {
  file: any;
  costototal:String;
  
}
@Component({
  selector: 'app-DialogAgregarArchivoDetalle',
  templateUrl: './DialogAgregarArchivoDetalle.component.html',
  styleUrls: ['./DialogAgregarArchivoDetalle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarArchivoDetalle implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MatPaginatorDetalle', { static: false }) paginatordetelle: MatPaginator;
  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public detalleCrearOrden: any;
  public pedidosCantidad: any=0;
  public detalleCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public numeroOperacionSiguiente: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  private valorTotalDetalleTemporal: any=0;
  public isaprobar = false;
  public ismenudetalle = false;
  public iscargado = false;
  public iscontenido = true;

  subscription: Subscription;
  subscriptionenvio: Subscription;
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile

  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataAgregar,public dialogRef: MatDialogRef<DialogAgregarArchivoDetalle>,private orderService: TareasTemporalService, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog) {

  }

   displayedColumns: string[] = ['NumeroPed', 'Ruta', 'Usuario', 'NombredeCliente', 'Direccion',
    'CostoMerc'];

    displayedColumnsDetalle: string[] = ['codigoArticulo', 'descripcion', 'ean', 'cantidad', 'valorNeto',
    'numeroPedido'];
  
    dataSource = new MatTableDataSource();

    dataSourceDetalle = new MatTableDataSource();

  ngOnInit(): void {
     var negocio=localStorage.getItem("negocio");
    var agencia=localStorage.getItem("agencia");
    this.negocioAgencia=negocio+"-"+agencia
    this.descargar()
    this.rutasTemporales=0;
    this.valorTotalDetalleTemporal=0
  

    this.fechaTareasTemporal = localStorage.getItem("pedidoFecha");

    this.dialog = new Dialog({
      // Enables the header
      header: 'ERROR VALIDAR FORMATO',
      // Enables the close icon button in header
      showCloseIcon: true,
      visible: false,
      // Dialog content

      // The Dialog shows within the target element
      target: document.getElementById("container"),
      // Dialog width
      width: '500px',
      height: '1000px'

    });

    this.dialog.appendTo('#dialog');
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSourceDetalle.paginator=this.paginatordetelle

  }


  getcreate(order: any) {
    this.orderService.createTareaTemporal(order);
  }


  parseExcel(file) {

    
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);

        let data = JSON.parse(json_object);

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

              this.negocioAgencia=elemento.negocio

            };


            this.valorTotalTareasTemporal = 0;
            this.pesoKG=0;
            this.pedidosCantidad=0;
            var groupBy = function (xs, key) {
              return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };
            var rutasTareas = groupBy(data, 'Ruta')
            var zonasareas = groupBy(data, 'Zona')
            this.rutasTemporales=0;
            this.zonasTemporales=0;

            for (let item of Object.keys(rutasTareas)) {
           
              this.rutasTemporales = this.rutasTemporales + 1
            }

            for (let item of Object.keys(zonasareas)) {
           
              this.zonasTemporales = this.zonasTemporales + 1
            }

            this.dataSource.data = data;

            this.tareasCrearOrden=data

            for (let tarea of data) {

              this.pedidosCantidad = this.pedidosCantidad+1 
              this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
              this.pesoKG = this.pesoKG + tarea["Peso neto"]
          
          
            }
            this.toastr.successToastr('Por favor cargar el archivo de detalles.', 'Notificación');
            this.ismenudetalle=true


          });


        });



        this.uploadObj.clearAll();



      }).bind(this), this);
    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  parseExcelDetalle(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });

      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log("detalle carga json_object",json_object);
        let data = JSON.parse(json_object);

        console.log("detalle carga",data);

        this.detalleCrearOrden=data;

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

              this.negocioAgencia=elemento.negocio

            };


            this.dataSourceDetalle.data = data;

            this.valorTotalDetalleTemporal = 0;
            this.detalleCantidad=0;
      

            for (let tarea of data) {

              this.detalleCantidad = this.detalleCantidad +1 
              this.valorTotalDetalleTemporal =  this.valorTotalDetalleTemporal + tarea.valorNeto;

        
            }

            this.isaprobar=true


          });


        });


        this.uploadObj.clearAll();



      }).bind(this), this);

    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };
  public onSuccess(args: any): void {
    var files = args.target.files; // FileList object

   // this.parseExcel(files[0]);
    this.dialogagregar.open(DialogAgregarDetalleOrden, {
      width: '1100px',
      height:'600px',
      data: {file:files[0],
            filetareas:this.data.file
      },
    
      });

      this.dialogRef.close();
    
  }

  public onSuccessDetalle(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcelDetalle(files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

 applyFilterDetalle(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDetalle.filter = filterValue.trim().toLowerCase();
  }
  descargar() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription =   this.orderService.getConsecutivo().subscribe(consecutivos => {

      for (let item of consecutivos) {
           
        var numeroSiguiente=1
        numeroSiguiente=numeroSiguiente+item.conoperacion
        this.numeroOperacion="OT"+numeroSiguiente
        this.numeroOperacionSiguiente=numeroSiguiente
      }      
      
     this.subscription.unsubscribe()
  
    });
  }


  crearOperacion():void {

    setTimeout(() => {
      this.route.navigate(['/OrdenTransporte']);
     }, 50000);

    this.iscontenido=false
    this.iscargado=true
    this.isaprobar=false
    this.toastr.successToastr('Carga en progreso.', 'Notificación');
    const cValue = localStorage.getItem("pedidoFecha");


    var operacion = {
      conoperacion:    this.numeroOperacionSiguiente,
      contarea:   this.numeroOperacionSiguiente,

    }

    this.orderService.incrementarCodigo(operacion);
    var hoy = new Date();
    var minuto=  hoy.getMinutes() 
    var segundos=hoy.getSeconds()

    var minutefinal="00"
    var segundofinal="00"
    if(minuto<10){

      minutefinal="0"+minuto
    }else{
      minutefinal=String(minuto)
    }
    if(segundos<10){

      segundofinal="0"+segundos
    }else{
      segundofinal=String(segundos)
    }
    var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
    var ordendetranspote = {
      codigodeorden:    this.numeroOperacion,
      tareas:    this.pedidosCantidad,
      valorconiva:   this.valorTotalTareasTemporal,
      rutas:   this.rutasTemporales,
      zonas:   this.zonasTemporales,
      peso:   this.pesoKG,
      fechacreacion:   cValue,
      fechaoperacion:   cValue,
      horacreacion:hora,
      negocio:localStorage.getItem("negocio"),
      agencia:localStorage.getItem("agencia"),
      estado:'Creada'
    }

    this.orderService.crearOrdenTransporte(ordendetranspote);

    this.pedidosCantidad=0;
    for (let tarea of this.tareasCrearOrden) {

      this.pedidosCantidad = this.pedidosCantidad+1 
      this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
      this.pesoKG = this.pesoKG + tarea["Peso neto"]
      tarea.idpedido = 'Pendiente';
      tarea.estado = 'Pendiente';
      tarea.observaciones = '';
      tarea.codigoOperacion=  this.numeroOperacion
      tarea.codigoPlanilla=  this.numeroOperacion+"RT"+tarea.Ruta
      tarea.codigoRegistro= this.numeroOperacion+"TA"+tarea.NumeroTarea
      tarea.fecha = cValue;
      tarea.negocio = localStorage.getItem("negocio");
      tarea.agencia = localStorage.getItem("agencia");  
      tarea.lonTarea=0.0;
      tarea.latTarea=0.0;
      tarea.lonEnvio=0.0;
      tarea.latEnvio=0.0;
      tarea.devolucion=0.0;
      tarea.metros_reporte=0.0;
      tarea.metros_envio=0.0;
      tarea.sitio_reporte='';
      tarea.sitio_envio='';
      tarea.direccion_geo='';
      tarea.hora_envio='';
      tarea.hora_reporte='';
      tarea.usuario_app='';
      tarea.reporte='';
      tarea.foto_cliente='';
      tarea.foto_factura='';
      tarea.url_foto_cliente='';
      tarea.url_foto_factura='';
      tarea.estado_foto_cliente='';
      tarea.estado_foto_factura='';
      tarea.id_foto_cliente='';
      tarea.id_foto_factura='';
      tarea.geo_zona='';
      tarea.causal_estado='';
      tarea.recaudo=0.0;
      console.log(tarea);
     
      this.orderService.numeroRegistro(tarea);


    }


    this.detalleCantidad= 0;
    for (let tarea of this.detalleCrearOrden) {

      this.detalleCantidad = this.detalleCantidad+1 
      tarea.estado = 'Pendiente';
      tarea.registro=  this.numeroOperacionSiguiente+ this.detalleCantidad 
      tarea.fecha = cValue;
      tarea.negocio = localStorage.getItem("negocio");
      tarea.agencia = localStorage.getItem("agencia");  
      tarea.codigoOperacion=  this.numeroOperacion
      this.orderService.detalleRegistro(tarea);

    }
   
  }
  
}
