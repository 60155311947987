import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { OrdersService } from 'src/app/shared/orders.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogEditarTarea } from '../../tercero/dialogEditarTarea/dialogEditarTarea.component';
export interface DialogDataVh {
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}
@Component({
  selector: 'app-FragmentPlanilla',
  templateUrl: './FragmentPlanilla.component.html',
  styleUrls: ['./FragmentPlanilla.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentPlanilla implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MatPaginatorDetalle', { static: false }) paginatordetelle: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private userd: AuthService,private orderService: OrdersService,private route: Router,private configuracionesService: TareasTemporalService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  sano: string;
  smes: string;
  sdia: string;
  vdia: string;
  vano: string;
  vmes: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
  idTercero: string;
  idPropietario: string;
  nombrePropietario: string;
  apellidosPropietario: string
  

  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public detalleCrearOrden: any;
  public pedidosCantidad: any=0;
  public detalleCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public numeroOperacionSiguiente: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  private valorTotalDetalleTemporal: any=0;
  public isaprobar = false;
  public ismenudetalle = false;
  public iscontenido = true;
  public ordenTransporte = "";
  private s_ano;
  private s_mes;
  private s_dia;
  private anos;
  private dias;
  private meses;
  subscription: Subscription;
  subscriptionenvio: Subscription;

   displayedColumns: string[] = ['usuario','agencia','estado','fecha','negocio','ordentransporte','pesoKG','tareas','CostoMerc','planilla','recaudo'];
   dataSource = new MatTableDataSource();


   displayedColumnstareas: string[] = ['NumeroPed', 'RutaVLI', 'Placa', 'NombredeCliente', 'Direccion','estado',
    'CostoMerc','recaudo','lat'];
    dataSourcetareas = new MatTableDataSource();
 
  ngOnInit(): void {
    this.ordenTransporte=localStorage.getItem("ordenTransporte");
    this.descargarConfiguraciones()
   this.getAllOrders()

   this.dataSource = new MatTableDataSource();


  
   this.dataSourcetareas = new MatTableDataSource();
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSourcetareas.paginator = this.paginatordetelle;
  }


  editarTarea(tarea: any): void {
    const dialogRef = this.dialog.open(DialogEditarTarea, {
      width: '600px',
      data: tarea
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Tarea editada:', result);
        this.orderService.createOrder(tarea);
      }
    });
  }

  getAllOrders() {


    this.subscription= this.orderService.getOrders().subscribe(data => {
      this.dataSourcetareas.data = data;
      this.subscription.unsubscribe();
      var i = 0;

      this.valorTotalTareasTemporal = 0;
      this.pesoKG=0;
      this.pedidosCantidad=0;
      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      var rutasTareas = groupBy(data, 'RutaVLI')
      var zonasareas = groupBy(data, 'Zona')
      this.rutasTemporales=0;
      this.zonasTemporales=0;

      for (let item of Object.keys(rutasTareas)) {
     
        this.rutasTemporales = this.rutasTemporales + 1
      }

      for (let item of Object.keys(zonasareas)) {
     
        this.zonasTemporales = this.zonasTemporales + 1
      }



      this.tareasCrearOrden=data

      for (let tarea of data) {

        this.pedidosCantidad = this.pedidosCantidad+1 

        var cototarea=tarea.CostoMerc
        if(isNaN(cototarea)){
          cototarea=0
        }
        this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + cototarea;
        
        var pesotarea=tarea.PesoNeto
        if(isNaN(pesotarea)){
          pesotarea=0
        }
        this.pesoKG = this.pesoKG + tarea.pesotarea
    
    
      }

      this.valorTotalTareasTemporal= Math.trunc(this.valorTotalTareasTemporal)
    

    });
  }

  getAllOrdersCambio() {
    this.route.navigate(['/fototarea']);

    /*
    this.subscription= this.orderService.getOrdersDetalleFotos().subscribe(data => {
      
      this.dataSourcetareas = new MatTableDataSource();
      this.dataSourcetareas.data = data;
     
    this.toastr.successToastr('Tarea con fotos.', 'Notificación');
    this.subscription.unsubscribe();
     this.route.navigate(['/fototarea']);

    });
    */
  }
  
  descargarConfiguraciones() {

    this.configuracionesService.getPlanillas().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;


    

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  applyFilterdetalle(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log("filterValue",filterValue)
    this.dataSourcetareas.filter = filterValue.trim().toLowerCase();
  }

  applyFilterdetallecambio() {
    const filterValue = "";
    console.log("filterValue",filterValue)
    this.dataSourcetareas.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {
    localStorage.setItem("codigoplanilla", tipo.planilla);
    this.route.navigate(['/PlanillaDetalle']);

  }

  recuado(tipo: any) {
    localStorage.setItem("codigoplanilla", tipo.planilla);
    this.route.navigate(['/RecaudoPlanilla']);

  }


  nuevo():void {
 
  
 
    }

  deletebanco(tipo: any) {

  
  }

  
  atras():void {

    this.route.navigate(['/OrdenTransporte']);
    
  }

  exportAsXLSXgeneral():void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
  
    this.subscription= this.orderService.getOrders().subscribe(res => {
      this.datas=res;
      this.orderService.exportAsExcelFile(this.datas, 'tareas_vrt_');
      this.subscription.unsubscribe();
  

    }); 
  

  }
  
  fotos(order: any){
    console.log("order seleccionada",order)
    localStorage.setItem("tareafotoid", order.NumeroPed);
  
    if  (order.agencia=="pruebas234"){
    window.open("https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/FotosPDF%2F"+localStorage.getItem("ordenTransporteFecha")+"%2F"+localStorage.getItem("negocio")+"%2F"+localStorage.getItem("agencia")+"%2FE"+order.NumeroPed +"1.pdf?alt=media", '_blank');
    }else{
      this.getAllOrdersCambio()
  }
  
  }
}
