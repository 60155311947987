import { BarriosService } from '../../shared/barrios.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';


@Component({

  selector: 'app-FragmentRoutingRecursos',
  templateUrl: './FragmentRoutingRecursos.component.html',
  styleUrls: ['./FragmentRoutingRecursos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentRoutingRecursos implements OnInit {

  recurso = {
    nombre: '',
    tipo: '',
    capacidad: null,
    volume: null,
    boxes: null,
    weight: null,
    agencia: '',
    negocio: '',
  };

  recursos: any[] = [];
  Remove(args): void {
    args.cancel = true;
  }
  selectedOption: string = 'model1'; // Opción inicial
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  

  constructor(private resumenTareasService: ResumenTareasService, private userd: AuthService, public toastr: ToastrManager) {

  }

  guardarRecurso() {
    this.recurso.negocio = localStorage.getItem("negocio");
    this.recurso.agencia = localStorage.getItem("agencia");
    this.resumenTareasService.guardarRecurso(this.recurso).subscribe(
      (res) => {
        // Agregar el recurso a la lista y reiniciar el formulario
        this.recursos.push({ ...this.recurso, id: res.id });
        this.resetFormulario();
      },
      (error) => {
        console.error('Error al guardar el recurso', error);
      }
    );
  }

  obtenerRecursos() {
    this.resumenTareasService.getRecursos().subscribe(
      (data) => {
        this.recursos = data;
      },
      (error) => {
        console.error('Error al obtener recursos', error);
      }
    );
  }

  eliminarRecurso(id: string, index: number) {
    if (confirm('¿Está seguro de eliminar este recurso?')) {
      this.resumenTareasService.eliminarRecurso(id).subscribe(
        (res) => {
          this.recursos.splice(index, 1);
        },
        (error) => {
          console.error('Error al eliminar el recurso', error);
        }
      );
    }
  }

  resetFormulario() {
    this.recurso = {
      nombre: '',
      tipo: '',
      capacidad: null,
      agencia: '',
      negocio: '',
      boxes: null,
      weight: null,
      volume: null,
    };
  }
  ngOnInit(): void {
    this.obtenerRecursos()

  }


}
