import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarTercero } from '../dialogAgregarTercero/dialogAgregarTercero.component';
import { DialogEditarTercero } from '../dialogEditarTercero/dialogEditarTercero.component';

export interface DialogDataVh {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  correo: string;
  ncelular: string;
  fdia: string;
  fmes: string;
  fano: string;
  departamento: string;
  ciudad: string;
  barrio: string;
  descripcion: string;
  detalle: string;
}
@Component({
  selector: 'app-FragmentTercero',
  templateUrl: './FragmentTercero.component.html',
  styleUrls: ['./FragmentTercero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentTercero implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  correo: string;
  ncelular: string;
  fdia: string;
  fmes: string;
  fano: string;
  departamento: string;
  ciudad: string;
  barrio: string;
  descripcion: string;
  detalle: string;

   displayedColumns: string[] = ['imgEstado','nombres','apellidos','tipoDoc','identificacion','ncelular','descripcion','correo','estado','editar'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getTerceros().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getAddConTerceros().subscribe(tipos => {

      for (let tipo of tipos) {

           this.codigo=tipo.con
      
      }  


    });
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {
    console.log('ttipo tercero', tipo);
    this.dialogagregar.open(DialogEditarTercero, {
      width: '950px',
      data: {id: tipo.id,
        nombres: tipo.nombres,
        apellidos: tipo.apellidos,
        tipoDoc: tipo.tipoDoc,
        correo: tipo.correo,
        identificacion:tipo.identificacion,
        ncelular: tipo.ncelular,
        fdia: tipo.fdia,
        fmes: tipo.fmes,
        fano: tipo.fano,
        departamento: tipo.departamento,
        ciudad: tipo.ciudad,
        barrio: tipo.barrio,
        descripcion:tipo.descripcion,
        detalle:tipo.detalle,
        estado: tipo.estado,
        imgEstado: tipo.imgEstado,
        dentrega: tipo.entregas,
        dsupervisor: tipo.supervisor,
        dgestionvial: tipo.vial,
        dencuestas: tipo.encuestas,
        dgeoreferenciar: tipo.geo,
        dinformes: tipo.informes,
        dfotos:tipo.fotos
      },
    
    });

  }

  nuevo():void {
    console.log('this.codigo tercero',this.codigo);
    this.dialogagregar.open(DialogAgregarTercero, {
      width: '950px',
      data: {id: this.codigo},
    
    });
   
    }

  deletebanco(tipo: any) {

  
  }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaTercero']);
    
  }
  
}
