import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';


@Component({
  selector: 'app-widget-areavcumplimientoOftif',
  templateUrl: './areavcumplimientoOftif.component.html',
  styleUrls: ['./areavcumplimientoOftif.component.scss']
})
export class AreavcumplimientoOntifComponent implements OnInit {

  public negocio: any;
  public agencia: any;
  public usuario: any;
  public txtTotalFac: any;
  public txtTotalFacRuta: any;
  public txtPesoKG: any;
  public txtPesoKGDe: any;
  public txtPedidos: any;
  public txtPedidosRuta: any;
  public txtDias: any;
  public txtRutas: any;
  public txtNovedades: any;
  public txtPedidosDev: any;
  public txtPedidosDevZona: any;
  public txtCanGrupo: any;
  public txtDevGrupo: any;
  public txtGrupoPedidos: any;
  public txtGrupoDev: any;
  public txttipoNovedad: any;
  public txtFecha: any;

  public offtime: any;
  public ontime: any;
  public infull: any;
  public otif: any;
  public outfull: any;
  public Nootif: any;

  //novedades

  public txtCanCliente: any;
  public txtCanComercial: any;
  public txtCanPlanta: any;
  public txtCanTransporte: any;
  public txtCanTerceros: any;

  public txtCosCliente: any;
  public txtCosComercial: any;
  public txtCosPlanta: any;
  public txtCosTransporte: any;
  public txtCosTerceros: any;

  public totalParcial: any;
  public canParcial: any;

  public totalParcialExi: any;
  public canParcialExi: any;

  public totalExitoso: any;
  public canExitoso: any;

  public totalPendiente: any;
  public canPendiente: any;

  public totalNovedad: any;
  public canNovedad: any;

  public totalGeneral: any;
  public canGeneral: any;

  public datospruebas: any;
  public datatrasnportes: any;
  public dataruta: any;

  public tareasDescarga: any;
  public rutasDescarga: any;

  private subscription: Subscription;
  subscriptionTransporte: Subscription;
  subscriptionTareas: Subscription;
  fechaInicio: string = '';
  fechaFin: string = '';
  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;

  constructor(private orderService: OrdersService, private userd: AuthService,private resumenService: ResumenTareasService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
  }

  ngOnInit() {
    if (!this.subscription) {
      this.subscription = this.resumenService.resumen$.subscribe((data) => {
        if (data !== null){
          this.chartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Otif'
            },
            xAxis: {
              categories: data.nombrerutas
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: 'bold',
                  color: ['#c23b22 ', '#77dd77', '#efa94a'],
                }
              }
            }, legend: {
              align: 'right',
              x: -30,
              verticalAlign: 'top',
              y: 10,
              floating: true,
              backgroundColor:
                ['#c23b22 ', '#77dd77', '#efa94a'],
              borderColor: '#CCC',
              borderWidth: 1,
              shadow: false
            }, tooltip: {
              headerFormat: '<b>{point.x}</b><br/>',
              pointFormat: '{series.name}: {point.y} ({point.percentage:.1f}%)<br/>Total: {point.stackTotal}'
      
            },
            plotOptions: {
              column: {
                stacking: 'percent',
                dataLabels: {
                  enabled: true,
                  formatter: function() {
                    return Math.round(this.percentage) + '%';
                  }
                }
              }
            }, series: [{
              name: 'In Progress',
              data: data.listapendientes,
              color: "#e23a3a"
            }, {
              name: 'Off Otif',
              data: data.listaNootif,
              color: "#d05bff"
            }, {
              name: 'Otif',
              data: data.listaotif,
              color: "#439e37"
            }]
      
          };
              
        }else{

          this.chartOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: 'Otif'
            },
            xAxis: {
              categories: []
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: 'bold',
                  color: ['#c23b22 ', '#77dd77', '#efa94a'],
                }
              }
            }, legend: {
              align: 'right',
              x: -30,
              verticalAlign: 'top',
              y: 10,
              floating: true,
              backgroundColor:
                ['#c23b22 ', '#77dd77', '#efa94a'],
              borderColor: '#CCC',
              borderWidth: 1,
              shadow: false
            }, tooltip: {
              headerFormat: '<b>{point.x}</b><br/>',
              pointFormat: '{series.name}: {point.y} ({point.percentage:.1f}%)<br/>Total: {point.stackTotal}'
      
            },
            plotOptions: {
              column: {
                stacking: 'percent',
                dataLabels: {
                  enabled: true,
                  formatter: function() {
                    return Math.round(this.percentage) + '%';
                  }
                }
              }
            }, series: [{
              name: 'In Progress',
              data: [],
              color: "#e23a3a"
            }, {
              name: 'Off Otif',
              data: [],
              color: "#d05bff"
            }, {
              name: 'Otif',
              data: [],
              color: "#439e37"
            }]
      
          };
              

        }
 
      });
    }

    /*

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    console.log(`Consultando datos entre ${this.fechaInicio} y ${this.fechaFin}`);
  
    this.orderService.consultar$.subscribe(({ inicio, fin }) => {
      this.fechaInicio = inicio;
      this.fechaFin = fin;
      this.resumenService.getResumenTareas(this.negocio, this.agencia, this.fechaInicio, this.fechaFin)
      .subscribe({
        next: (data) => {
        
    
        
      
        },
        error: (error) => {
          console.error('❌ Error al obtener resumen:', error);
        }
      });
    });

    */


  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      console.log('Suscripción cancelada');
    }
  }
}

