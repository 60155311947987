import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';


@Component({
  selector: 'app-widget-areavcumplimiento',
  templateUrl: './areavcumplimiento.component.html',
  styleUrls: ['./areavcumplimiento.component.scss']
})
export class AreavcumplimientoComponent implements OnInit {

  public negocio: any;
  public agencia: any;
  public usuario: any;
  public txtTotalFac: any;
  public txtTotalFacRuta: any;
  public txtPesoKG: any;
  public txtPesoKGDe: any;
  public txtPedidos: any;
  public txtPedidosRuta: any;
  public txtDias: any;
  public txtRutas: any;
  public txtNovedades: any;
  public txtPedidosDev: any;
  public txtPedidosDevZona: any;
  public txtCanGrupo: any;
  public txtDevGrupo: any;
  public txtGrupoPedidos: any;
  public txtGrupoDev: any;
  public txttipoNovedad: any;
  public txtFecha: any;

  public offtime: any;
  public ontime: any;
  public infull: any;
  public otif: any;
  public outfull: any;
  public Nootif: any;

  //novedades

  public txtCanCliente: any;
  public txtCanComercial: any;
  public txtCanPlanta: any;
  public txtCanTransporte: any;
  public txtCanTerceros: any;

  public txtCosCliente: any;
  public txtCosComercial: any;
  public txtCosPlanta: any;
  public txtCosTransporte: any;
  public txtCosTerceros: any;

  public totalParcial: any;
  public canParcial: any;

  public totalParcialExi: any;
  public canParcialExi: any;

  public totalExitoso: any;
  public canExitoso: any;

  public totalPendiente: any;
  public canPendiente: any;

  public totalNovedad: any;
  public canNovedad: any;

  public totalGeneral: any;
  public canGeneral: any;

  public datospruebas: any;
  public datatrasnportes: any;
  public dataruta: any;

  public tareasDescarga: any;
  public rutasDescarga: any;


  subscriptionTransporte: Subscription;
  subscriptionTareas: Subscription;
  private subscription: Subscription;
  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;
  fechaInicio: string = '';
  fechaFin: string = '';

  constructor(private orderService: OrdersService, private userd: AuthService,private resumenService: ResumenTareasService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
  }

  ngOnInit() {
    console.log('Cumplimiento inits');
    if (!this.subscription) {
      this.subscription = this.resumenService.resumen$.subscribe((data) => {
        console.log('Cumplimiento data',data);

        if (data !== null){
        this.chartOptions = {
          chart: {
            type: 'column'
          },
          title: {
            text: 'On Time'
          },
          xAxis: {
            categories: data.nombrerutas
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold',
                color: ['#c23b22 ', '#77dd77', '#efa94a'],
              }
            }
          }, legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 10,
            floating: true,
            backgroundColor:
              ['#c23b22 ', '#77dd77', '#efa94a'],
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
          }, tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y} ({point.percentage:.1f}%)<br/>Total: {point.stackTotal}'
    
          },
          plotOptions: {
            column: {
              stacking: 'percent',
              dataLabels: {
                enabled: true,
                formatter: function() {
                  return Math.round(this.percentage) + '%';
                }
              }
            }
          }, series: [{
            name: 'In Progress',
            data: data.listapendientes,
            color: "#e23a3a"
          }, {
            name: 'Off Time',
            data: data.listaofftime,
            color: "#d05bff"
          }, {
            name: 'On Time',
            data: data.listaontime,
            color: "#439e37"
          }]
    
        };
      }else{
        this.chartOptions = {
          chart: {
            type: 'column'
          },
          title: {
            text: 'On Time'
          },
          xAxis: {
            categories: []
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold',
                color: ['#c23b22 ', '#77dd77', '#efa94a'],
              }
            }
          }, legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 10,
            floating: true,
            backgroundColor:
              ['#c23b22 ', '#77dd77', '#efa94a'],
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
          }, tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y} ({point.percentage:.1f}%)<br/>Total: {point.stackTotal}'
    
          },
          plotOptions: {
            column: {
              stacking: 'percent',
              dataLabels: {
                enabled: true,
                formatter: function() {
                  return Math.round(this.percentage) + '%';
                }
              }
            }
          }, series: [{
            name: 'In Progress',
            data: [],
            color: "#e23a3a"
          }, {
            name: 'Off Time',
            data: [],
            color: "#d05bff"
          }, {
            name: 'On Time',
            data: [],
            color: "#439e37"
          }]
    
        };
   }
      });

      
    }


  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      console.log('Suscripción cancelada');
    }
  }
  
}
