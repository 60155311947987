import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class OrdenTransporteService {
  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private ordeTransporteCollection: AngularFirestoreCollection<any>;
  ordentransporte: Observable<any[]>;

  private encuentasCollection: AngularFirestoreCollection<any>;
  encuestas: Observable<any[]>;
  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");

  constructor(private readonly afs: AngularFirestore, private userd: AuthService) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const cValue= localStorage.getItem("pedidoFecha");
    this.ordeTransporteCollection = afs.collection<any>('SCL_OrdenTransporte/orden/ordentransporte/');
   
  }


  getOrdenTransporte() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const cValue= localStorage.getItem("pedidoFecha");
    this.ordeTransporteCollection = this.afs.collection<any>('SCL_OrdenTransporte/orden/ordentransporte/', res=>res
    .where('agencia',"==",this.agencia).where('negocio',"==",this.negocio).orderBy('fechacreacion', 'desc'));
    this.ordentransporte = this.ordeTransporteCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.ordentransporte

  }

  
  getEncuentas() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.encuentasCollection = this.afs.collection<any>(
      `SLC_Encuestas/${this.negocio}_${this.agencia}/encuentas`,
      ref => ref.orderBy('fecha', 'desc')
    );
    this.encuestas = this.encuentasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.encuestas;


  }
  
}