import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { OrdersService } from '../shared/orders.service';
import { Label } from 'tns-core-modules';
export interface Vehicle {
  id: string;
  start: [number, number]; // [lon, lat]
  end?: [number, number];  // Opcional
}

export interface Waypoint {
  id: string;
  location: [number, number];
}

export interface OptimizationRequest {
  version: number;
  options: {
    roundtrip: boolean;
    source?: string;
    destination?: string;
  };
  vehicles: Vehicle[];
  waypoints: Waypoint[];
}

export interface OptimizationResponse {
  code: string;
  waypoints: Array<{
    id: string;
    vehicle_id: string;
    waypoint_index: number;
  }>;
  trips: Array<{
    vehicle_id: string;
    geometry: string;
    distance: number;
    duration: number;
    waypoints: number[];
  }>;
}

@Injectable({
  providedIn: 'root'
})

export class ResumenTareasService {
  private apiUrl = 'https://us-central1-appvlbogota-43289.cloudfunctions.net/app';
  private apiUrlmapbox = 'https://api.mapbox.com/optimized-trips/v1';
  private accessToken = 'sk.eyJ1IjoidnJ0LWFwcCIsImEiOiJjbThwYTY0ejcwOG1pMmtwc2dwczBlNzgyIn0.g_7RAIZMl9gG2hgZwh-HOw'; 

  
  private resumenSubject = new BehaviorSubject<any | null>(null);
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  private ubicacionesSubject = new BehaviorSubject<any | null>(null);
  resumen$: Observable<any> = this.resumenSubject.asObservable();
  resumenUbi$: Observable<any> = this.ubicacionesSubject.asObservable();
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();
  private consultar = new Subject<{ inicio: string, fin: string }>();
  consultar$ = this.consultar.asObservable();

  
  constructor(private http: HttpClient,private orderService: OrdersService) {}


  getTripStatus(tripId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/token-optimized-trip/${tripId}`);
  }


  getOptimizedTrip(requestBody: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/optimized-trip`, requestBody);
  }

  optimizeRoute(coordinates: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/optimize-route-mapbox?coordinates=${coordinates}`);
  }
  optimizeRoutev1(coordinates: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/optimize-route-mapbox?coordinates=${coordinates}`);
  }
  // Método para realizar la solicitud HTTP
  getResumenTareas(negocio: string, agencia: string, startDate: string, endDate: string): Observable<any> {
    // Activa la bandera de carga
    this.isLoadingSubject.next(true);

    // Realiza la solicitud HTTP
    return this.http.get<any>(`${this.apiUrl}/getResumenTareas/${negocio}/${agencia}/${startDate}/${endDate}`).pipe(
      tap((data) => {
        this.resumenSubject.next(data); // Guarda la nueva respuesta en el BehaviorSubject
        this.isLoadingSubject.next(false); // Desactiva la bandera de carga
       
      }),
      catchError((error) => {
        this.isLoadingSubject.next(false); // Desactiva la bandera de carga en caso de error
        throw error; // Propaga el error para que los suscriptores lo manejen
      })
    );
  }

  // Método para obtener los datos actuales (sin realizar una nueva solicitud HTTP)
  getCurrentResumen(): Observable<any> {
    return this.resumen$; // Devuelve el Observable del BehaviorSubject
  }

  getUbicaciones(negocio: string, agencia: string, date: string): Observable<any> {
    // Activa la bandera de carga
    this.isLoadingSubject.next(true);

    // Realiza la solicitud HTTP
    return this.http.get<any>(`${this.apiUrl}/v2/getUbicacionDiaRuta/${negocio}_${agencia}/${date}`).pipe(
      tap((data) => {
        this.ubicacionesSubject.next(data); // Guarda la nueva respuesta en el BehaviorSubject
        this.isLoadingSubject.next(false); // Desactiva la bandera de carga
       
      }),
      catchError((error) => {
        this.isLoadingSubject.next(false); // Desactiva la bandera de carga en caso de error
        throw error; // Propaga el error para que los suscriptores lo manejen
      })
    );
  }

  getModelos(negocio?: string, agencia?: string): Observable<any> {
    let params = new HttpParams();
    if (negocio) {
      params = params.set('negocio', negocio);
    }
    if (agencia) {
      params = params.set('agencia', agencia);
    }
    return this.http.get<any>(`${this.apiUrl}/getModel`, { params });
  }

  deletePlanilla(fecha: string, negocio: string, ordenDeTransporte: string): Observable<any> {
    const url = `${this.apiUrl}/deleteOrdenTransporte/${fecha}/${negocio}/${ordenDeTransporte}`;
    return this.http.get(url); // <-- Aquí estás usando GET porque tu backend usa GET
    // Si fuera DELETE (mejor práctica), sería: this.http.delete(url);
  }

  eliminarModelo(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/deleteModel/${id}`);
  }

  deleteTareas(fecha: string, negocio: string, ordenDeTransporte: string): Observable<any> {
    const url = `${this.apiUrl}/deleteTareas/${fecha}/${negocio}/${ordenDeTransporte}`;
    return this.http.get(url); // <-- Aquí estás usando GET porque tu backend usa GET
    // Si fuera DELETE (mejor práctica), sería: this.http.delete(url);
  }

  getUbicacionesUsuario(negocio: string, agencia: string, date: string,usaurio: string): Observable<any> {
    // Activa la bandera de carga
    this.isLoadingSubject.next(true);

    // Realiza la solicitud HTTP
    return this.http.get<any>(`${this.apiUrl}/v2/getUbicacionDiaRutaUsuarioDos/${negocio}_${agencia}/${date}/${usaurio}`).pipe(
      tap((data) => {
        this.ubicacionesSubject.next(data); // Guarda la nueva respuesta en el BehaviorSubject
        this.isLoadingSubject.next(false); // Desactiva la bandera de carga
       
      }),
      catchError((error) => {
        this.isLoadingSubject.next(false); // Desactiva la bandera de carga en caso de error
        throw error; // Propaga el error para que los suscriptores lo manejen
      })
    );
  }
  getUbicacionesResumen(): Observable<any> {
    return this.resumenUbi$; // Devuelve el Observable del BehaviorSubject
  }

  private apiKey = 'AIzaSyDOSjwGUQPGkTHYgp3mrXGHzwhlPGPHfXM'; // Reemplaza con tu clave de API


  getRoute(origin: string, destination: string, waypoints: string[]) {
    const waypointsStr = waypoints.join('|');
    const params = {
      origin: origin,
      destination: destination,
      waypoints: waypointsStr,
    };

    return this.http.get(`${this.apiUrl}/directionsApi/`, { params });
  }

  optimizeRoutes(shipments: any[], pickups: any[]): Observable<any> {

    const data = {
      model: {
        shipments,
        vehicles: [
          {
            label:"uno",
            startLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            endLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            costPerKilometer: 1.0,
            loadLimits:{
              units: {
                "maxLoad": "20"
              }
            },
          },
          {
            label:"dos",
            startLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            endLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            costPerKilometer: 1.0,
            loadLimits:{
              units: {
                "maxLoad": "20"
              }
            }
          },
          {
            label:"tres",
            startLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            endLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            costPerKilometer: 1.0,
            loadLimits:{
              units: {
                "maxLoad": "20"
              }
            }
          },
          {
            label:"cuatro",
            startLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            endLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            costPerKilometer: 1.0,
            loadLimits:{
              units: {
                "maxLoad": "20"
              }
            }
          },
          {
            label:"cinco",
            startLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            endLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            costPerKilometer: 1.0,
            loadLimits:{
              units: {
                "maxLoad": "20"
              }
            }
          },
          {
            label:"seis",
            startLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            endLocation: {
              latitude: 6.2553554,
              longitude: -75.5807607
            },
            costPerKilometer: 1.0,
            loadLimits:{
              units: {
                "maxLoad": "40"
              }
            }
          }
        ],
        globalStartTime: "2025-03-23T06:00:00.000Z",
        globalEndTime: "2025-03-23T23:00:00.000Z"
      }
    };
    console.log('✅ loca del data:', data);

    return this.http.post(`${this.apiUrl}/demo-optimize-route`, data);
  }

  guardarModelo(modelo: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createModel`, modelo);
  }

  getRouteApi(originLat: number, originLng: number, destLat: number, destLng: number): Observable<any> {
    const params = new HttpParams()
      .set('originLat', originLat.toString())
      .set('originLng', originLng.toString())
      .set('destLat', destLat.toString())
      .set('destLng', destLng.toString());
  
    return this.http.get<any>(`${this.apiUrl}/routesApi`, { params });
  }

  guardarRecurso(recurso: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createRecurso`, recurso);
  }

  getRecursos(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getRecurso`);
  }

  eliminarRecurso(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/deleteRecurso/${id}`);
  }

  guardarEsquema(esquema: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createEsquema`, esquema);
  }

  getEsquemas(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getEsquema`);
  }

  eliminarEsquema(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/deleteEsquema/${id}`);
  }
  
}