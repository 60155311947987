import { BarriosService } from '../../shared/barrios.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';
interface Recurso {
  nombre: string;
  cantidad: number;
  id: string;
  recurso: any;
}

@Component({

  selector: 'app-FragmentRoutingEsquema',
  templateUrl: './FragmentRoutingEsquema.component.html',
  styleUrls: ['./FragmentRoutingEsquema.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentRoutingEsquema implements OnInit {

  
  esquema = {
    nombre: '',
    modeloGestion: { id: null, nombre: '', modelo: null },
    recursos: [] as Array<Recurso>,
    agencia: '',
    negocio: '',
  };
  selectedOption: string = 'model1'; // Opción inicial
  // Array para almacenar los esquemas obtenidos (para la lista)
  esquemas: any[] = [];
   // Opciones para el select "modelo de gestión"
   availableModelos: any[] = [];

   // Opciones para el select de recursos en cada item
   availableRecursos: any[] = [];
  Remove(args): void {
    args.cancel = true;
  }

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  

  constructor(private resumenTareasService: ResumenTareasService, private userd: AuthService, public toastr: ToastrManager) {

  }

  ngOnInit(): void {
    this.obtenerEsquemas();
    this.obtenerRecursosDisponibles()
    this.obtenerModelosGestion()
  }

  // Agrega un nuevo recurso al esquema (con campos vacíos)
  agregarRecurso() {
    this.esquema.recursos.push({
      nombre: '',
      cantidad: null, // o null si prefieres
      id: "", // o '', si quieres dejarlo vacío
      recurso: null, // o '', si quieres dejarlo vacío
    });
  }
  
  onRecursoSeleccionado(recursoId: string, index: number) {
    const recursoSeleccionado = this.availableRecursos.find(r => r.id === recursoId);
    
    if (recursoSeleccionado) {
      this.esquema.recursos[index].recurso = recursoSeleccionado;
      this.esquema.recursos[index].nombre = recursoSeleccionado.nombre;
    }
  }
  
  

  // Elimina un recurso de la lista del esquema
  eliminarRecurso(index: number) {
    this.esquema.recursos.splice(index, 1);
  }

  // Guarda el nuevo esquema mediante el servicio
  guardarEsquema() {
    this.esquema.negocio = localStorage.getItem("negocio");
    this.esquema.agencia = localStorage.getItem("agencia");
    this.resumenTareasService.guardarEsquema(this.esquema).subscribe(
      res => {
        // Agrega el esquema guardado a la lista y reinicia el formulario

        
        this.esquemas.push({ ...this.esquema, id: res.id });
        this.resetFormulario();
      },
      error => {
        console.error('Error al guardar el esquema', error);
      }
    );
  }


  obtenerModelosGestion() {
    this.resumenTareasService.getModelos(localStorage.getItem("negocio"), localStorage.getItem("agencia")).subscribe(

      data => {
        this.availableModelos = data;
      },
      error => {
        console.error('Error al obtener modelos de gestión', error);
      }
    );
  }

  // Cargar recursos disponibles desde la API
  obtenerRecursosDisponibles() {
    this.resumenTareasService.getRecursos().subscribe(
      data => {
        this.availableRecursos = data;
      },
      error => {
        console.error('Error al obtener recursos disponibles', error);
      }
    );
  }

  // Obtiene la lista de esquemas (puedes implementar filtros en el servicio si se requiere)
  obtenerEsquemas() {
    this.resumenTareasService.getEsquemas().subscribe(
      data => {
        this.esquemas = data;
      },
      error => {
        console.error('Error al obtener esquemas', error);
      }
    );
  }

  // Elimina un esquema
  eliminarEsquema(id: string, index: number) {
    console.error('eliminarEsquema', id);
    if (confirm('¿Está seguro de eliminar este esquema?')) {
      this.resumenTareasService.eliminarEsquema(id).subscribe(
        res => {
          this.esquemas.splice(index, 1);
        },
        error => {
          console.error('Error al eliminar el esquema', error);
        }
      );
    }
  }

  // Reinicia el formulario de esquema
  resetFormulario() {
    this.esquema = { nombre: '',  modeloGestion: { id: null, nombre: '', modelo: null }, recursos: [] , agencia: '',
    negocio: '',};
  }


}
