import { Component, OnInit, Input,SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../../shared/orders.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Options } from "highcharts";
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { ClientesService } from '../../clientes.service';
import { PedidoService } from '../../pedidos.service';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';
@Component({
  selector: 'app-widget-reloj',
  templateUrl: './reloj.component.html',
  styleUrls: ['./reloj.component.scss']

})
export class RelojComponent implements OnInit {
  private subscription: Subscription;
  @Input() data: any = [];
  updateFlag = false;
  private rutasDescarga: any;
  myConfiges: any
  enviados=0
  operacion=0
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0
  constructor(private ClientesService: ClientesService,private pedidoService: PedidoService,private indicadoresService: OrdersService,private resumenTareasService: ResumenTareasService) { }
  fechaInicio: string = '';
  fechaFin: string = '';
  private negocio: any;
  private agencia: any;



  ngOnInit(): void {

    if (!this.subscription) {
      this.subscription = this.resumenTareasService.resumen$.subscribe((data) => {
    
        console.log('📌 Resumen recibido:', data);
        this.estadoPendiente=0
        this.estadoExitosa=0
        this.estadoNovedad=0
        this.estadoRepisar=0
        this.estadoParcial=0

        if (data !== null){
          for (let tarea of data.totalPorEstado) {
  
            if(tarea.estado=="PENDIENTE"){
    
              this.estadoPendiente=tarea.totalCosto
          
             }
    
             if(tarea.estado=="EXITOSA"){
    
              this.estadoExitosa=tarea.totalCosto
          
             }
    
    
             if(tarea.estado=="NOVEDAD"){
    
              this.estadoNovedad=tarea.totalCosto + this.estadoNovedad
          
             }
    
    
             if(tarea.estado=="REPISAR"){
    
              this.estadoNovedad=tarea.totalCosto + this.estadoNovedad
          
             }
    
             if(tarea.estado=="PARCIAL"){
    
              this.estadoNovedad=tarea.totalCosto +  this.estadoNovedad
          
             }
    
          }
          this.myConfiges = {
            type: "ring",
            plot: {
              borderColor: "#2B313B",
              borderWidth: 0.8,
              slice: "50%",
            
              tooltip: {
                fontSize: '18',
                fontFamily: "Open Sans",
                padding: "5 10",
                text: "%npv%"
              },
              animation: {
                effect: 2,
                method: 5,
                speed: 900,
                sequence: 1,
                delay: 3000
              }
            },
            source: {
              text: '',
              fontColor: "#8e99a9",
              fontFamily: "Open Sans"
            },
           
            plotarea: {
              margin: "0 0 0 0"
            },
            series: [{
                values: [this.estadoPendiente],
                text: "Pendiente",
                backgroundColor: '#ee630d',
              },
              {
                values: [this.estadoExitosa],
                text: 'Exitosa',
                backgroundColor: '#468189',
                detached: true
              },
              {
                text: 'Novedad',
                values: [this.estadoNovedad],
                backgroundColor: '#FFA060'
              }
            ]
          };
        }
      });
    }

   
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}