import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { OptimizationRequest, ResumenTareasService } from 'src/app/services/resumen-tareas.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as turf from '@turf/turf';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { delay, switchMap } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var google: any; // Declara la variable global de Google Maps
var negocio: any;
var agencia: any;
interface PuntoConOrden extends google.maps.LatLngLiteral {
  ordenVisita: number;
}

export interface Location {
  id:number;
  latitude: number;
  longitude: number;
}

export interface GeoZone {
  id: number;
  locations: Location[];
}


interface Visit {
  isPickup: boolean;
  startTime: string;
  detour: number;
  nombreRuta:String
}

interface Transition {
  travelDuration: string;
  travelDistanceMeters: number;
  waitDuration: string;
  totalDuration: string;
  startTime: string;
}

interface Metrics {
  performedShipmentCount: number;
  travelDuration: string;
  waitDuration: string;
  delayDuration: string;
  breakDuration: string;
  visitDuration: string;
  totalDuration: string;
  travelDistanceMeters: number;
}

interface Route {
  vehicleStartTime: string;
  vehicleEndTime: string;
  visits: Visit[];
  transitions: Transition[];
  metrics: Metrics;
  routeCosts: number[];
  routeTotalCost: number;


}

interface Response {
  routes: Route[];
}
@Component({
  selector: 'app-fragmentMapaGeneralRuteo',
  templateUrl: './fragmentMapaGeneralRuteo.component.html', // Usa solo templateUrl
  styleUrls: ['./fragmentMapaGeneralRuteo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FragmentMapaGeneralRuteo implements OnInit, OnDestroy,AfterViewInit {
  fechaInicio: string = new Date().toISOString().split('T')[0]; // Fecha actual
  intervalo: any;
  ultimaUbicacionlista : any[] =  []
  response: any; // Almacena la respuesta de la API
  routes: any[] = []; // Almacena las rutas procesadas

  mergedData: any[] = []; // Almacena las rutas procesadas
  seleccionarTodos: boolean = true; // No selecciona todos al inicio
  usuarioSeleccionado: any = null;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  token: string = "";

  esquemas: any[]= []
  esquemasView: any[]= []
  isPrintinginProgress = true
  isRecarga = false
  vehiclesesquemaview:any[]= []

  visitsFromResponse:any[]= []
  result: any;
  esquemaSeleccionado: any;
  error: any;
  private coloresUsuarios: Record<string, string> = {}; // Colores por usuario
  tableData: any[] = [];
  displayedColumns: string[] = [
    'visitLabel', 'Name', 
    'Address', 'Latitude', 'Longitude', 'ServiceDuration',
    'StartTime', 'EndTime', 'Q1', 'Q2', 'SKILL'
  ];

  dataSource = new MatTableDataSource(); 
  permitirReabastecimiento: boolean = false; // Desactivado por defecto

  tiposOptimizacion = [
    { id: 'min-total-travel-duration', nombre: 'Minimizar Tiempo Total' },
    { id: 'min-schedule-completion-time', nombre: 'Minimizar Itinerario Más Largo' }
  ];

  tipoOptimizacionSeleccionado = this.tiposOptimizacion[0].id; // Opción por defecto

  

  constructor(private resumenService: ResumenTareasService, public toastr: ToastrManager) {

  }

  data: any[] = [];
  criterioReabastecimiento: string = 'paquete'; // Valor por defecto
// En tu componente
mostrarRecursos: boolean = false;
fileName: string = '';
errorArchivo: string = '';
toggleRecursos() {
  this.mostrarRecursos = !this.mostrarRecursos;
}

eliminarArchivo(event: Event): void {
  event.stopPropagation();
  this.fileName = '';
  this.errorArchivo = '';
  this.data = []; // Limpiar datos si es necesario
  // Puedes agregar aquí cualquier otra limpieza necesaria
}

// Método auxiliar para resetear el input
private resetFileInput(input: HTMLInputElement): void {
  input.value = '';
  this.fileName = '';
}

// Mantener tu función existente
agruparRecursos(recursos: any[], itemsPorFila: number): any[] {
  const grupos = [];
  for (let i = 0; i < recursos.length; i += itemsPorFila) {
    grupos.push(recursos.slice(i, i + itemsPorFila));
  }
  return grupos;
}


onpermitirReabastecimiento() {
  console.log('permitirReabastecimiento seleccionado:', this.permitirReabastecimiento);
  // Aquí puedes agregar lógica adicional cuando cambia la selección
}
onTipoDeOptimizacion() {
  console.log('tipoOptimizacionSeleccionado seleccionado:', this.tipoOptimizacionSeleccionado);
  // Aquí puedes agregar lógica adicional cuando cambia la selección
}
  onCriterioChange() {
    console.log('Criterio seleccionado:', this.criterioReabastecimiento);
    // Aquí puedes agregar lógica adicional cuando cambia la selección
  }
  // Esta función se llama cuando seleccionas un archivo

  
  onFileChangeDos(event: any): void {
    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length !== 1) {
      console.error('Solo puedes cargar un archivo a la vez.');
      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      // Obtenemos el nombre de la primera hoja
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      // Convertimos la hoja a JSON
      this.data = XLSX.utils.sheet_to_json(ws);

      this.dataSource.data =  this.data;
      //this.dataSource.paginator = this.paginator;

      //optimizar

      this.isPrintinginProgress = false
      const locations: Location[] = this.data.map(task => ({
        id:task.IdJob,
        latitude: task.Latitude,
        longitude: task.Longitude,
      }));

      
      /*
      const geoZones = groupLocationsByGeoZone(locations);
       console.log("geoZones",geoZones);

       const optimizedRoutes = geoZones.map(zone => ({
        id: zone.id,
        orderedLocations: findOptimalRoute(zone.locations)
      }));
      

      console.log("optimizedRoutes",optimizedRoutes);

      */
      
      //const startLocationId = 1; // Punto de inicio (ejemplo: Nueva York)
    //const shortestPathMap = dijkstra(locations, startLocationId);

//console.log("Mapa de rutas más cortas:", shortestPathMap);
 
      
      const shipments: any[] = [];

    

    
    // Ejemplo de uso con una lista de visitas
  
    
   // const requestBody = mapVisitsToRequest(this.data);
   // console.log(JSON.stringify(requestBody, null, 2));
    
      this.data.forEach((location) => {
        const shipment: any = {
          loadDemands: {
            weight: {
              amount: location.Q1 // o la lógica que necesites
            }
          }
        };
      
        const serviceDurationInSeconds = location.ServiceDuration * 86400; // de días a segundos
      
        const startTimeHours = this.getDateTimeWithStartTime(location.StartTime)  
        const endTimeHours = this.getDateTimeWithStartTime(location.EndTime)  
      
        // Formateamos la fecha/hora en ISO 8601 (simulada en 1970)
     
      
        const timeWindow = {
          start_time:startTimeHours,
          end_time: endTimeHours
        };

        const loadDemands = {
          units:{
            "amount": "1"
          }
        }
     
        
        const stop = {
          arrivalLocation: {
            latitude: location.Latitude,
            longitude: location.Longitude
          },
          duration: `${Math.round(serviceDurationInSeconds)}s`,
          label:location.visitLabel,

          //time_windows: [timeWindow],
          loadDemands: loadDemands
        };
      
        if (location.Type === 'P') {
          shipment.pickups = [stop];
        } else if (location.Type === 'D') {
          shipment.deliveries = [stop];
        }
      
        shipments.push(shipment);
      });

      
      /* google
      this.data.forEach((location) => {
        const shipment: any = {
          loadDemands: {
            weight: {
              amount: location.Q1 // o la lógica que necesites
            }
          }
        };
      
        const serviceDurationInSeconds = location.ServiceDuration * 86400; // de días a segundos
      
        const startTimeHours = this.getDateTimeWithStartTime(location.StartTime)  
        const endTimeHours = this.getDateTimeWithStartTime(location.EndTime)  
      
        // Formateamos la fecha/hora en ISO 8601 (simulada en 1970)
     
      
        const timeWindow = {
          start_time:startTimeHours,
          end_time: endTimeHours
        };

        const loadDemands = {
          units:{
            "amount": "1"
          }
        }
     
        const stop = {
          arrivalLocation: {
            latitude: location.Latitude,
            longitude: location.Longitude
          },
          duration: `${Math.round(serviceDurationInSeconds)}s`,
          label:location.visitLabel,

          //time_windows: [timeWindow],
          loadDemands: loadDemands
        };
      
        if (location.Type === 'P') {
          shipment.pickups = [stop];
        } else if (location.Type === 'D') {
          shipment.deliveries = [stop];
        }
      
        shipments.push(shipment);
      });

      */

      
      //console.log("shipments",shipments);
      

      /*
      this.resumenService
      .optimizeRoutes(shipments,shipments)
      .subscribe(
        (response) => {
          console.log('✅ Respuesta del backend:', response);
          this.response = response;
          this.processResponse(response); // Procesar la respuesta
        },
        (error) => {
          console.error('❌ Error:', error);
        }
      );
      
      */  

  
     // this.fetchOptimizedTrip()
      console.log('Datos del Excel:', this.data);

      
      
    };

    reader.readAsBinaryString(target.files[0]);
  }

  // Variables en tu componente


// Método para manejar el cambio de archivo
onFileChange(event: any): void {
  const target: DataTransfer = <DataTransfer>(event.target);
  const file: File = target.files[0];
  
  // Resetear mensajes de error
  this.errorArchivo = '';
  
  // Validaciones básicas
  if (!file) {
    return;
  }

  if (file.size > 5 * 1024 * 1024) { // 5MB máximo
    this.errorArchivo = 'El archivo es demasiado grande (máx. 5MB)';
    this.resetFileInput(event.target);
    return;
  }

  if (!['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)) {
    this.errorArchivo = 'Formato de archivo no válido';
    this.resetFileInput(event.target);
    return;
  }

  // Procesar el archivo
  this.fileName = file.name;
  const reader: FileReader = new FileReader();

  reader.onload = (e: any) => {
    try {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      // Convertimos la hoja a JSON
      this.data = XLSX.utils.sheet_to_json(ws);

      this.dataSource.data =  this.data;
      //this.dataSource.paginator = this.paginator;

      //optimizar

      this.isPrintinginProgress = false
      // Tu lógica de procesamiento del archivo aquí...
      console.log('Archivo cargado correctamente:', this.fileName);
      
    } catch (error) {
      console.error('Error al procesar el archivo:', error);
      this.errorArchivo = 'Error al leer el archivo';
      this.resetFileInput(event.target);
    }
  };

  reader.onerror = () => {
    this.errorArchivo = 'Error al leer el archivo';
    this.resetFileInput(event.target);
  };

  reader.readAsBinaryString(file);
}


  mapVisitsToRequest(visits: any[]): any {

    const esquemaObj = this.esquemas.find(e => e.id === this.esquemaSeleccionado);
    const init =  [esquemaObj.modeloGestion.modelo.longitudInicial, esquemaObj.modeloGestion.modelo.latitudInicial]
    const end =  [esquemaObj.modeloGestion.modelo.longitudFinal, esquemaObj.modeloGestion.modelo.latitudFinal]
  

    const origen = { lat: esquemaObj.modeloGestion.modelo.latitudInicial, lng: esquemaObj.modeloGestion.modelo.longitudInicial,}; // Nueva York
    const distanciaKm = 50;
    const direccion = 90; // 0° Norte, 90° Este, 180° Sur, 270° Oeste


   const nuevoPunto = this.calculateDestination(origen.lat, origen.lng, distanciaKm, direccion);
    console.log(`Nueva ubicación: Lat ${nuevoPunto.lat}, Lng ${nuevoPunto.lng}`);
  
    const locations = visits.map(visit => ({
      name: visit.visitLabel,
      coordinates: [visit.Longitude, visit.Latitude]
    }));

    if (this.permitirReabastecimiento){
      locations.push({ name:"init", coordinates: init})
      locations.push({ name:"end", coordinates: end})
    }else{
      locations.push({ name:"init", coordinates: [nuevoPunto.lng,nuevoPunto.lat]})
      locations.push({ name:"end", coordinates: end})
    }

    const vehicles =  this.vehiclesesquemaview

    
    const shipments = visits.map((visit, index) => ({
      name: String(index),
      from: "init", // Primer punto como origen
      to: visit.visitLabel,
      size: { 
        amount:1,
      },
      pickup_duration: visit.pickup_duration,  //13.5 = x
      dropoff_duration:visit.dropoff_duration
    }))
    
    
    /*
    const services = visits.map((visit, index) => ({
      name: String(index),
      location: visit.visitLabel,
      duration:1400
    }))

    */
    

//min-schedule-completion-time rellena
//min-total-travel-duration reparte
    const options = {
      objectives: [this.tipoOptimizacionSeleccionado]
    }
    return { version: 1, locations, vehicles, shipments, options};
  }

 

  

  
  ngOnDestroy(): void {
    if (this.intervalo) {
      clearInterval(this.intervalo);
    }
  }

  getDateTimeWithStartTime(startime: number): string {
    // 1. Obtener la fecha actual en UTC (sin la hora)
    const now = new Date();
    const currentDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  
    // 2. Convertir el decimal startime a total de segundos
    const totalSeconds = startime * 24 * 60 * 60;
  
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
  
    // 3. Asignar la hora calculada a la fecha actual
    currentDate.setUTCHours(hours, minutes, seconds, 0);
  
    // 4. Convertir a formato ISO 8601 con +00:00
    const isoString = currentDate.toISOString().replace('Z', '+00:00');
  
    return isoString;
  }
  
  optimizeRoutes() {


    if (!this.esquemaSeleccionado) {
      this.toastr.errorToastr('Por favor seleccione un esquema');
      return;
    }
  
    if (!this.criterioReabastecimiento) {
      this.toastr.errorToastr('Por favor seleccione un criterio de reabastecimiento');
      return;
    }
  
    if (this.permitirReabastecimiento === undefined || this.permitirReabastecimiento === null) {
      this.toastr.errorToastr('Por favor defina si permite reabastecimiento');
      return;
    }

    console.log("data error validar request ", this.data );
     if (this.data.length == 0 ) {
      this.toastr.errorToastr('Cargar un archivo');
      return;
    }
      
    console.log("data request ", this.mapVisitsToRequest(this.data));
     
    this.resumenService.getOptimizedTrip(this.mapVisitsToRequest(this.data)) .subscribe(
      (response) => {
        console.log('✅ Respuesta del getOptimizedTrip :', response);
        this.token = response.id
        this.toastr.successToastr('Ruteo en progreso ' +  this.token , 'Notificación');
     
        //this.response = response;
       // this.processResponse(response); // Procesar la respuesta
      },
      (error) => {
        console.error('❌ Error:', error);
        this.toastr.errorToastr("Error en el archivo inicial");
      }
    );
    

  }

  resultado() {

    this.resumenService.getTripStatus(this.token) .subscribe(
      (response) => {


        if (response.code === "unsolvable") {
          this.toastr.warningToastr(response.message) ; // Guardamos el mensaje para mostrarlo
          return;
        } 

        if (response.status === "processing") {
          this.toastr.infoToastr("En progreso")
          return;
        } 

   

        console.log('✅ Respuesta del getTripStatus:', response);
  

        console.log('✅ Respuesta del getLocationVehiclePairs:',  this.getLocationVehiclePairs(response.routes));


         this.visitsFromResponse =  this.getLocationVehiclePairs(response.routes)
       
        if (!this.visitsFromResponse || this.visitsFromResponse.length === 0) {
          this.toastr.warningToastr("Problema de optimización sin solucion") 
          return; // o continuar con el flujo según tu lógica
        }
        
        
        this.isPrintinginProgress = true
        this.mergedData = this.data.map(originalRow => {
          // Busca la visita correspondiente al registro original
          const visitIndex = this.visitsFromResponse.findIndex(visit => visit.visitLabel === originalRow.visitLabel);
          const visitMatch = this.visitsFromResponse[visitIndex];
        
  
        
          // Devuelve el registro original, combinando los datos y agregando el orden (visitIndex)
          return {
            ...originalRow,
            ...visitMatch,                // fusiona los datos de la visita
            ordenVisita: visitIndex >= 0 ? visitIndex + 1 : null // si lo encontró, usa el índice + 1 (opcional)
          };
        });
        
    
    
      // 1️⃣ Agrupás solo una vez por ruta
    const tareasAgrupadasPorRuta = this.mergedData.reduce((acc, tarea) => {
      const ruta = tarea.nombreRuta;
    
      if (!acc[ruta]) {
        acc[ruta] = [];
      }
    
      acc[ruta].push(tarea);
    
      return acc;
    }, {} as { [key: string]: any[] });
    
    // 2️⃣ Iniciás el mapa UNA vez (Centro de Colombia en el ejemplo)
    const colombiaCenter = { longitud: 4.5709, latitud: -74.2973 };
    this.initMap(colombiaCenter);
    
    
    this.ultimaUbicacionlista  =  []
    console.log('Tareas en la tareasAgrupadasPorRuta:', tareasAgrupadasPorRuta);
    const mapasContainer = document.getElementById('mapas-container');
    mapasContainer.innerHTML = '';
    
    // 3️⃣ Recorres cada ruta con sus tareas
    for (const ruta in tareasAgrupadasPorRuta) {
    
    
      if (tareasAgrupadasPorRuta.hasOwnProperty(ruta)) {
      
        const colorGen = new ColorGenerator();
        const color = colorGen.generarColorFosforescenteOscuro();
    
        const tareas = tareasAgrupadasPorRuta[ruta];
        this.ultimaUbicacionlista.push( { usuario: ruta , total:tareas.length})
        console.log('Ruta:', ruta);
        console.log('Tareas en la ruta:', tareas);
    
      
        const guiadeopereacion = this.visitsFromResponse.filter(tarea => tarea.nombreRuta === ruta);
        console.log('Tareas guiadeopereacion', guiadeopereacion);
    
        if (mapasContainer) {
          const mapWrapper = document.createElement('div');
          mapWrapper.classList.add('mapa-wrapper');
        
          // Click para seleccionar
          mapWrapper.addEventListener('click', () => {
            document.querySelectorAll('.mapa-wrapper').forEach(card => card.classList.remove('selected'));
            mapWrapper.classList.add('selected');
          });
        
          const titulo = document.createElement('h3');
          titulo.textContent = `${ruta}`;
          titulo.classList.add('mapa-titulo');
        
          // Contenedor del mapa y la tabla en columna
          const contentContainer = document.createElement('div');
          contentContainer.classList.add('content-container');
        
          // Contenedor del mapa
          const mapContainer = document.createElement('div');
          mapContainer.id = `map-${ruta}`;
          mapContainer.classList.add('mapa-container');
        
          // Contenedor de la tabla
          const tablaContainer = document.createElement('div');
          tablaContainer.classList.add('tabla-container');
        
          const table = document.createElement('table');
          table.classList.add('styled-table');
        
          // Encabezados de la tabla
          const thead = document.createElement('thead');
          thead.innerHTML = `
            <tr>
              <th>📌 Tarea</th>
              <th>📍 Tipo</th>
              <th>⏳ Duración</th>
              <th>📦 Carga</th>
            </tr>
          `;
        
          const tbody = document.createElement('tbody');
        
          // Paginación
          let currentPage = 1;
          const rowsPerPage = 5;
          const totalPages = Math.ceil(guiadeopereacion.length / rowsPerPage);
        
          const renderTablePage = (page: number) => {
            tbody.innerHTML = "";
            const start = (page - 1) * rowsPerPage;
            const end = start + rowsPerPage;
            const tareasPaginadas = guiadeopereacion.slice(start, end);
          
            tareasPaginadas.forEach(tarea => {
              const row = document.createElement('tr');
              row.innerHTML = `
                <td>${tarea.visitLabel}</td>
                <td>${tarea.type}</td>
                <td>${tarea.duration}</td>
                <td>${tarea.pickups}</td>
              `;
              tbody.appendChild(row);
            });
          
            // Actualizar contador de página
            pageIndicator.textContent = `Página ${currentPage} de ${totalPages} | ${guiadeopereacion.length}`;
          };
          
        
          table.appendChild(thead);
          table.appendChild(tbody);
          tablaContainer.appendChild(table);
        
          // Controles de paginación
          const paginationContainer = document.createElement('div');
          paginationContainer.classList.add('pagination-container');
        
          const prevButton = document.createElement('button');
          prevButton.textContent = "⬅️ Anterior";
          prevButton.disabled = true;
          prevButton.addEventListener("click", () => {
            if (currentPage > 1) {
              currentPage--;
              renderTablePage(currentPage);
              nextButton.disabled = false;
              if (currentPage === 1) prevButton.disabled = true;
            }
          });
        
          const pageIndicator = document.createElement('span');
          pageIndicator.classList.add('page-indicator');
        
          const nextButton = document.createElement('button');
          nextButton.textContent = "Siguiente ➡️";
          if (guiadeopereacion.length <= rowsPerPage) nextButton.disabled = true;
          nextButton.addEventListener("click", () => {
            if (currentPage < totalPages) {
              currentPage++;
              renderTablePage(currentPage);
              prevButton.disabled = false;
              if (currentPage === totalPages) nextButton.disabled = true;
            }
          });
        
          paginationContainer.appendChild(prevButton);
          paginationContainer.appendChild(pageIndicator);
          paginationContainer.appendChild(nextButton);
          tablaContainer.appendChild(paginationContainer);
        
          // Renderizar la primera página de la tabla
          renderTablePage(currentPage);
        
          // Agregar mapa y tabla al contenedor principal
          contentContainer.appendChild(mapContainer);
          contentContainer.appendChild(tablaContainer);
        
          // Agregar elementos al wrapper
          mapWrapper.appendChild(titulo);
          mapWrapper.appendChild(contentContainer);
          mapasContainer.appendChild(mapWrapper);
        
          // Inicializar el mapa con Google Maps
          const mapa = new google.maps.Map(mapContainer, {
            center: { lat: tareas[0].Latitude, lng: tareas[0].Longitude },
            zoom: 14,
          });
        
          this.addGeozona(tareas, color, mapa);
        }
        
        
        
        
        
        
    
    
        // Si querés, agregás otros métodos:
        // this.addMarkers(tareas, color);
      }
    }
    
        //this.response = response;
       // this.processResponse(response); // Procesar la respuesta
      },
      (error) => {
        console.error('❌ Error:', error);
      }
    );
    
  
  }

  obtenerEsquemas() {
    this.resumenService.getEsquemas().subscribe(
      data => {
        this.esquemas = data;
        console.error(' esquemas', data);


      },
      error => {
        console.error('Error al obtener esquemas', error);
      }
    );
  }


  
  

  descargar() {
  
    this.exportVisitsToExcel(this.mergedData);
    this.exportVisitsToExcelPlan(this.visitsFromResponse);
  }
  
  exportVisitsToExcel = (visits: any[]) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(visits);
    const workbook: XLSX.WorkBook = { Sheets: { 'Visits': worksheet }, SheetNames: ['Visits'] };
  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
  
    FileSaver.saveAs(data, 'rutas.xlsx');
  };

  exportVisitsToExcelPlan = (visits: any[]) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(visits);
    const workbook: XLSX.WorkBook = { Sheets: { 'Visits': worksheet }, SheetNames: ['Visits'] };
  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
  
    FileSaver.saveAs(data, 'guiadeoperacion.xlsx');
  };

  processResponse(response: Response | null) {
    if (!response || !response.routes) {
      console.warn('Invalid or empty response');
      return;
    }
  
    
    
    
    const visitsFromResponse: any[] = [];

    if (response.routes && response.routes.length > 0) {
      response.routes.forEach((route, index) => {
        const nombreRuta = `Ruta ${index + 1}`; // Nombre dinámico para la ruta
    
        if (route.visits && route.visits.length > 0) {
          console.warn(`La ${nombreRuta} tiene visitas`, route.visits);
    
          route.visits.forEach(visit => {
            // Agregar el campo nombreRuta a cada visit
            visit.nombreRuta = nombreRuta;
    
            visitsFromResponse.push(visit);
          });
    
        } else {
          console.warn(`La ${nombreRuta} no tiene visitas.`);
        }
      });
    } else {
      console.warn('No hay rutas en la respuesta.');
    }
    
    console.warn('Visitas encontradas:', visitsFromResponse);
    

    // Exporta la lista que creamos en el paso anterior


    this.mergedData = this.data.map(originalRow => {
      // Busca la visita correspondiente al registro original
      const visitIndex = visitsFromResponse.findIndex(visit => visit.visitLabel === originalRow.visitLabel);
      const visitMatch = visitsFromResponse[visitIndex];
    
  
    
      // Devuelve el registro original, combinando los datos y agregando el orden (visitIndex)
      return {
        ...originalRow,
        ...visitMatch,                // fusiona los datos de la visita
        ordenVisita: visitIndex >= 0 ? visitIndex + 1 : null // si lo encontró, usa el índice + 1 (opcional)
      };
    });
    


  // 1️⃣ Agrupás solo una vez por ruta
const tareasAgrupadasPorRuta = this.mergedData.reduce((acc, tarea) => {
  const ruta = tarea.nombreRuta;

  if (!acc[ruta]) {
    acc[ruta] = [];
  }

  acc[ruta].push(tarea);

  return acc;
}, {} as { [key: string]: any[] });

// 2️⃣ Iniciás el mapa UNA vez (Centro de Colombia en el ejemplo)
const colombiaCenter = { longitud: 4.5709, latitud: -74.2973 };
this.initMap(colombiaCenter);


this.ultimaUbicacionlista  =  []
console.log('Tareas en la tareasAgrupadasPorRuta:', tareasAgrupadasPorRuta);
const mapasContainer = document.getElementById('mapas-container');
mapasContainer.innerHTML = '';

// 3️⃣ Recorres cada ruta con sus tareas
for (const ruta in tareasAgrupadasPorRuta) {


  if (tareasAgrupadasPorRuta.hasOwnProperty(ruta)) {
  
    const colorGen = new ColorGenerator();
    const color = colorGen.generarColorFosforescenteOscuro();

    const tareas = tareasAgrupadasPorRuta[ruta];
    this.ultimaUbicacionlista.push( { usuario: ruta , total:tareas.length})
    console.log('Ruta:', ruta);
    console.log('Tareas en la ruta:', tareas);

  


 
    if (mapasContainer) {
      const mapWrapper = document.createElement('div');
      mapWrapper.classList.add('mapa-wrapper');
    
      // Click para seleccionar
      mapWrapper.addEventListener('click', () => {
        // Quita la clase 'selected' de todas las cards
        document.querySelectorAll('.mapa-wrapper').forEach(card => card.classList.remove('selected'));
    
        // Agrega la clase solo a esta card
        mapWrapper.classList.add('selected');
      });
    
      const titulo = document.createElement('h3');
      titulo.textContent = `${ruta}`;
      titulo.classList.add('mapa-titulo');
    
      const mapContainer = document.createElement('div');
      mapContainer.id = `map-${ruta}`;
      mapContainer.classList.add('mapa-container');
    
      mapWrapper.appendChild(titulo);
      mapWrapper.appendChild(mapContainer);
    
      mapasContainer.appendChild(mapWrapper);
    
      const mapa = new google.maps.Map(mapContainer, {
        center: { lat: tareas[0].Latitude, lng: tareas[0].Longitude },
        zoom: 14,
      });
    
      this.addGeozona(tareas, color, mapa);
    }
    
    


    // Si querés, agregás otros métodos:
    // this.addMarkers(tareas, color);
  }
}


   // exportVisitsToExcel(mergedData);

   // this.routes = response.routes.map(route => this.mapRoute(route));
  }

  getLocationVehiclePairs(routesData: any[] | null | undefined): {visitLabel: string, nombreRuta: string, type:String,pickups:Number, duration:Number, eta:String}[] {
    // Validar si routesData es null/undefined o no es un array
    if (!routesData || !Array.isArray(routesData)) {
      return [];
    }

    const pairs: {visitLabel: string, nombreRuta: string,type:String,pickups:Number, duration:Number, eta:String}[] = [];

    routesData.forEach(route => {
      // Validar si route es null/undefined o no tiene vehicle/stops
      if (!route || !route.vehicle || !route.stops || !Array.isArray(route.stops)) {
        return; // Continuar con el siguiente elemento
      }

      const vehicleId = route.vehicle;
      
      route.stops.forEach(stop => {
        // Validar si stop es null/undefined o no tiene location
        if (!stop || !stop.location) {
          return;
        }
        stop.pickups = stop.pickups || [];
        stop.duration = stop.duration || 0;
        pairs.push({
          visitLabel: stop.location,
          nombreRuta: vehicleId,
          type:stop.type,
          pickups:stop.pickups.length,
          duration:stop.duration,
          eta:stop.eta
        });
      });
    });

    return pairs;
  }

  mapRoute(route: Route) {
    return {
      vehicleStartTime: route.vehicleStartTime || '',
      vehicleEndTime: route.vehicleEndTime || '',
      visits: this.mapVisits(route.visits),
      transitions: this.mapTransitions(route.transitions),
      metrics: this.mapMetrics(route.metrics),
      routeCosts: route.routeCosts || [],
      routeTotalCost: route.routeTotalCost || 0,
    };
  }
  
   mapVisits(visits: Visit[]) {
    if (!visits) return [];
    return visits.map(visit => ({
      isPickup: visit.isPickup || false,
      startTime: visit.startTime || '',
      detour: visit.detour || 0,
    }));
  }
  
   mapTransitions(transitions: Transition[]) {
    if (!transitions) return [];
    return transitions.map(transition => ({
      travelDuration: transition.travelDuration || '',
      travelDistanceMeters: transition.travelDistanceMeters || 0,
      waitDuration: transition.waitDuration || '',
      totalDuration: transition.totalDuration || '',
      startTime: transition.startTime || '',
    }));
  }
  
   mapMetrics(metrics: Metrics) {
    if (!metrics) return {} as Metrics;
    return {
      performedShipmentCount: metrics.performedShipmentCount || 0,
      travelDuration: metrics.travelDuration || '',
      waitDuration: metrics.waitDuration || '',
      delayDuration: metrics.delayDuration || '',
      breakDuration: metrics.breakDuration || '',
      visitDuration: metrics.visitDuration || '',
      totalDuration: metrics.totalDuration || '',
      travelDistanceMeters: metrics.travelDistanceMeters || 0,
    };
  }

  ngOnInit() {
   this.obtenerEsquemas()

   const colombiaCenter = { longitud: 4.5709, latitud: -74.2973 }; // 🔹 Centro de Colombia
   this.initMap(colombiaCenter);
  
  }

  ngAfterViewInit(): void {
    negocio = localStorage.getItem("negocio");
    agencia = localStorage.getItem("agencia");
    const colorGen = new ColorGenerator();

    const colombiaCenter = { longitud: 4.5709, latitud: -74.2973 }; // 🔹 Centro de Colombia
    this.initMap(colombiaCenter);

    this.dataSource.paginator = this.paginator;
    /*
    this.resumenService.optimizeRoutes(this.vehicles, this.shipments).subscribe({
      next: (data) => {
        console.log('✅ Optimización recibida:', data);
        this.result = data;

        data.routes.forEach(route => {
          console.log("Vehículo sale:", route.vehicleStartTime);
          console.log("Vehículo termina:", route.vehicleEndTime);
        });

        data.visits.forEach((visit, index) => {
          const action = visit.isPickup ? "Recolectar" : "Entregar";
          console.log(`#${index + 1}: ${action} a las ${visit.startTime}`);
        });
      },
      error: (err) => {
        console.error('❌ Error al optimizar:', err);
        this.error = err;
      }
    });

  */
  
    // Llamar al servicio para obtener las ubicaciones


      

}

  initMap(center: any): void {
    /*
    const options: google.maps.MapOptions = {
      center: { lat: center.longitud, lng: center.latitud }, // 🔹 Centro en Colombia
      zoom: 6 // 🔹 Zoom inicial (ajusta según sea necesario)
  };

    this.map = new google.maps.Map(this.mapContainer.nativeElement, options);

    console.error('❌ Error al optimizar: ingreso');
    */
  }

  initMapFilter(center: any): void {

    /*
    const options: google.maps.MapOptions = {
      center: { lat: center.latitud, lng: center.longitud }, // 🔹 Centro en Colombia
      zoom: 13 // 🔹 Zoom inicial (ajusta según sea necesario)
   
  };

    this.map = new google.maps.Map(this.mapContainer.nativeElement, options);

    console.error('❌ Error al optimizar: ingreso');
       */
  }
  toggleTodos() {
    if (this.seleccionarTodos) {
      this.usuarioSeleccionado = null; // Se desmarca cualquier selección individual
    }

  }

  // Verifica si el checkbox "Seleccionar Todos" debe estar activado o no
  verificarSeleccion() {
    if (this.usuarioSeleccionado) {
      this.seleccionarTodos = false; // Si seleccionas un usuario, desmarca "Seleccionar Todos"
    }



  }

  createConvexHull(points: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral[] {
    if (points.length < 3) return points;
  
    // Eliminar puntos duplicados
    const uniquePoints = Array.from(new Set(points.map(p => `${p.lat},${p.lng}`)))
      .map(p => {
        const [lat, lng] = p.split(',');
        return { lat: parseFloat(lat), lng: parseFloat(lng) };
      });
  
    // Ordenar puntos por latitud y luego por longitud
    const sorted = uniquePoints.sort((a, b) => a.lat === b.lat ? a.lng - b.lng : a.lat - b.lat);
  
    // Función para calcular el producto cruzado
    const crossProduct = (o: google.maps.LatLngLiteral, a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral) =>
      (a.lat - o.lat) * (b.lng - o.lng) - (a.lng - o.lng) * (b.lat - o.lat);
  
    // Construir la parte inferior del convex hull
    const lower: google.maps.LatLngLiteral[] = [];
    for (const point of sorted) {
      while (lower.length >= 2 && crossProduct(lower[lower.length - 2], lower[lower.length - 1], point) < 0) {
        lower.pop();
      }
      lower.push(point);
    }
  
    // Construir la parte superior del convex hull
    const upper: google.maps.LatLngLiteral[] = [];
    for (const point of sorted.reverse()) {
      while (upper.length >= 2 && crossProduct(upper[upper.length - 2], upper[upper.length - 1], point) < 0) {
        upper.pop();
      }
      upper.push(point);
    }
  
    // Eliminar el último punto de cada parte porque está duplicado
    upper.pop();
    lower.pop();
  
    // Combinar las partes inferior y superior
    return lower.concat(upper);
  }

  addGeozona(recorridos: any[], color:String, mapa: google.maps.Map): void {

    const points: PuntoConOrden[] = recorridos.map((location, index) => ({
      lat:  parseFloat(location.Latitude) ,
      lng:   parseFloat(location.Longitude),
      ordenVisita: location.ordenVisita 
    }));


   
    // 🔹 1. Calcular el Convex Hull (envolvente convexa)
    const geozonaCoords = this.createConvexHull(points);
  

    // 🔹 3. Dibujar la geozona en el mapa
    const geozona = new google.maps.Polygon({
      paths: geozonaCoords,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.3
    });

    geozona.setMap(mapa);

   
          
    recorridos.forEach((location: any) => {
  
      
      const marker =    new google.maps.Marker({
        position: { lat: location.Latitude, lng: location.Longitude },
        map: mapa,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: color,
          fillOpacity: 0.8,
          strokeColor: color,
          strokeWeight: 1,
          scale: 10, // Tamaño del círculo
        }
      });
  
  
  
  
      marker.addListener("click", () => {
        mapa.setCenter(marker.getPosition() as google.maps.LatLng);
        mapa.setZoom(14); // Nivel de zoom al hacer clic
    });
  
  
    new google.maps.Marker({
      position: { lat: location.Latitude, lng: location.Longitude },
      map: mapa,
      label: {
        text:location.ordenVisita.toString(), // Muestra el número en el centro
        color: "#000", // Color del número
        fontSize: "8px",
        fontWeight: "normal",
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 0, // Hace invisible el ícono, solo se verá el texto
      }
    });


    });

    const esquemaObj = this.esquemas.find(e => e.id === this.esquemaSeleccionado);
    const init =  [esquemaObj.modeloGestion.modelo.longitudInicial, esquemaObj.modeloGestion.modelo.latitudInicial]
    const iconUrl = 'assets/trackrojo.svg'; // 🔹 Reemplaza con la URL de tu icono

    const marker = new google.maps.Marker({
      position: { lat:  esquemaObj.modeloGestion.modelo.latitudInicial, lng: esquemaObj.modeloGestion.modelo.longitudInicial}, // 🔹 Asegúrate de que lat y lng estén en el orden correcto
      map: mapa,
      title: "",
      icon: {
          url: iconUrl, // 🔹 URL del icono personalizado
          scaledSize: new google.maps.Size(30, 30) // 🔹 Tamaño del icono (ancho, alto)
      }
  });
  }

  consultarFecha() {
  

    
    if (this.seleccionarTodos) {
    ///

        // 1️⃣ Agrupás solo una vez por ruta
        const tareasAgrupadasPorRuta = this.mergedData.reduce((acc, tarea) => {
          const ruta = tarea.nombreRuta;
        
          if (!acc[ruta]) {
            acc[ruta] = [];
          }
        
          acc[ruta].push(tarea);
        
          return acc;
        }, {} as { [key: string]: any[] });
        
        // 2️⃣ Iniciás el mapa UNA vez (Centro de Colombia en el ejemplo)
        const colombiaCenter = { longitud: 4.5709, latitud: -74.2973 };
        this.initMap(colombiaCenter);
        
        
        this.ultimaUbicacionlista  =  []
        console.log('Tareas en la tareasAgrupadasPorRuta:', tareasAgrupadasPorRuta);
        const mapasContainer = document.getElementById('mapas-container');
        mapasContainer.innerHTML = '';
        
        // 3️⃣ Recorres cada ruta con sus tareas
        for (const ruta in tareasAgrupadasPorRuta) {
        
        
          if (tareasAgrupadasPorRuta.hasOwnProperty(ruta)) {
          
            const colorGen = new ColorGenerator();
            const color = colorGen.generarColorFosforescenteOscuro();
        
            const tareas = tareasAgrupadasPorRuta[ruta];
            this.ultimaUbicacionlista.push( { usuario: ruta , total:tareas.length})
            console.log('Ruta:', ruta);
            console.log('Tareas en la ruta:', tareas);
        
          
            const guiadeopereacion = this.visitsFromResponse.filter(tarea => tarea.nombreRuta === ruta);
            console.log('Tareas guiadeopereacion', guiadeopereacion);
        
            if (mapasContainer) {
              const mapWrapper = document.createElement('div');
              mapWrapper.classList.add('mapa-wrapper');
            
              // Click para seleccionar
              mapWrapper.addEventListener('click', () => {
                document.querySelectorAll('.mapa-wrapper').forEach(card => card.classList.remove('selected'));
                mapWrapper.classList.add('selected');
              });
            
              const titulo = document.createElement('h3');
              titulo.textContent = `${ruta}`;
              titulo.classList.add('mapa-titulo');
            
              // Contenedor del mapa y la tabla en columna
              const contentContainer = document.createElement('div');
              contentContainer.classList.add('content-container');
            
              // Contenedor del mapa
              const mapContainer = document.createElement('div');
              mapContainer.id = `map-${ruta}`;
              mapContainer.classList.add('mapa-container');
            
              // Contenedor de la tabla
              const tablaContainer = document.createElement('div');
              tablaContainer.classList.add('tabla-container');
            
              const table = document.createElement('table');
              table.classList.add('styled-table');
            
              // Encabezados de la tabla
              const thead = document.createElement('thead');
              thead.innerHTML = `
                <tr>
                  <th>📌 Tarea</th>
                  <th>📍 Tipo</th>
                  <th>⏳ Duración</th>
                  <th>📦 Carga</th>
                </tr>
              `;
            
              const tbody = document.createElement('tbody');
            
              // Paginación
              let currentPage = 1;
              const rowsPerPage = 5;
              const totalPages = Math.ceil(guiadeopereacion.length / rowsPerPage);
            
              const renderTablePage = (page: number) => {
                tbody.innerHTML = "";
                const start = (page - 1) * rowsPerPage;
                const end = start + rowsPerPage;
                const tareasPaginadas = guiadeopereacion.slice(start, end);
              
                tareasPaginadas.forEach(tarea => {
                  const row = document.createElement('tr');
                  row.innerHTML = `
                    <td>${tarea.visitLabel}</td>
                    <td>${tarea.type}</td>
                    <td>${tarea.duration}</td>
                    <td>${tarea.pickups}</td>
                  `;
                  tbody.appendChild(row);
                });
              
                // Actualizar contador de página
                pageIndicator.textContent = `Página ${currentPage} de ${totalPages} | ${guiadeopereacion.length}`;
              };
              
            
              table.appendChild(thead);
              table.appendChild(tbody);
              tablaContainer.appendChild(table);
            
              // Controles de paginación
              const paginationContainer = document.createElement('div');
              paginationContainer.classList.add('pagination-container');
            
              const prevButton = document.createElement('button');
              prevButton.textContent = "⬅️ Anterior";
              prevButton.disabled = true;
              prevButton.addEventListener("click", () => {
                if (currentPage > 1) {
                  currentPage--;
                  renderTablePage(currentPage);
                  nextButton.disabled = false;
                  if (currentPage === 1) prevButton.disabled = true;
                }
              });
            
              const pageIndicator = document.createElement('span');
              pageIndicator.classList.add('page-indicator');
            
              const nextButton = document.createElement('button');
              nextButton.textContent = "Siguiente ➡️";
              if (guiadeopereacion.length <= rowsPerPage) nextButton.disabled = true;
              nextButton.addEventListener("click", () => {
                if (currentPage < totalPages) {
                  currentPage++;
                  renderTablePage(currentPage);
                  prevButton.disabled = false;
                  if (currentPage === totalPages) nextButton.disabled = true;
                }
              });
            
              paginationContainer.appendChild(prevButton);
              paginationContainer.appendChild(pageIndicator);
              paginationContainer.appendChild(nextButton);
              tablaContainer.appendChild(paginationContainer);
            
              // Renderizar la primera página de la tabla
              renderTablePage(currentPage);
            
              // Agregar mapa y tabla al contenedor principal
              contentContainer.appendChild(mapContainer);
              contentContainer.appendChild(tablaContainer);
            
              // Agregar elementos al wrapper
              mapWrapper.appendChild(titulo);
              mapWrapper.appendChild(contentContainer);
              mapasContainer.appendChild(mapWrapper);
            
              // Inicializar el mapa con Google Maps
              const mapa = new google.maps.Map(mapContainer, {
                center: { lat: tareas[0].Latitude, lng: tareas[0].Longitude },
                zoom: 14,
              });
            
              this.addGeozona(tareas, color, mapa);
            }
            
            
            
            
            
            
        
        
            // Si querés, agregás otros métodos:
            // this.addMarkers(tareas, color);
          }
        }

    ///
    } else {
      console.error("El valor no es un arreglo o está this.usuarioSeleccionado.usuario:", this.usuarioSeleccionado.usuario);
   
      const datosFiltrados = this.mergedData.filter((item) => item.nombreRuta === this.usuarioSeleccionado.usuario);
  
     
      if (Array.isArray(datosFiltrados) && datosFiltrados.length > 0) {

        
        // Filtrar ubicaciones con coordenadas válidas
        console.error("El ubicaciones:", datosFiltrados);
      
  


        if (datosFiltrados.length > 0) {
          // Obtener las coordenadas del primer ítem
          const primerItem = datosFiltrados[0];
          const coordenadasPrimerItem = { latitud: primerItem.Latitude, longitud: primerItem.Longitude };
        
          // Inicializar el mapa en la posición del primer ítem
          this.initMapFilter(coordenadasPrimerItem);
        } else {
          console.error('No hay datos filtrados para mostrar.');
        }
  
  
        const colorGen = new ColorGenerator();
        const color = colorGen.generarColorFosforescenteOscuro();
    
        const guiadeopereacion = this.visitsFromResponse.filter(tarea => tarea.nombreRuta === this.usuarioSeleccionado.usuario);
        console.log('Tareas guiadeopereacion', guiadeopereacion);
     
        const mapasContainer = document.getElementById('mapas-container');
        mapasContainer.innerHTML = '';
        if (mapasContainer) {
          const mapWrapper = document.createElement('div');
          mapWrapper.classList.add('mapa-wrapperdos');
        
          // Click para seleccionar
          mapWrapper.addEventListener('click', () => {
            document.querySelectorAll('.mapa-wrapperdos').forEach(card => card.classList.remove('selected'));
            mapWrapper.classList.add('selected');
          });
        
          const titulo = document.createElement('h3');
          titulo.textContent = `${this.usuarioSeleccionado.usuario}`;
          titulo.classList.add('mapa-titulo');
        
          // Contenedor del mapa y la tabla en columna
          const contentContainer = document.createElement('div');
          contentContainer.classList.add('content-container');
        
          // Contenedor del mapa
          const mapContainer = document.createElement('div');
          mapContainer.id = `map-${this.usuarioSeleccionado.usuario}`;
          mapContainer.classList.add('mapa-container');
        
          // Contenedor de la tabla
          const tablaContainer = document.createElement('div');
          tablaContainer.classList.add('tabla-container');
        
          const table = document.createElement('table');
          table.classList.add('styled-table');
        
          // Encabezados de la tabla
          const thead = document.createElement('thead');
          thead.innerHTML = `
            <tr>
              <th>📌 Tarea</th>
              <th>📍 Tipo</th>
              <th>⏳ Duración</th>
              <th>📦 Carga</th>
            </tr>
          `;
        
          const tbody = document.createElement('tbody');
        
          // Paginación
          let currentPage = 1;
          const rowsPerPage = 5;
          const totalPages = Math.ceil(guiadeopereacion.length / rowsPerPage);
        
          const renderTablePage = (page: number) => {
            tbody.innerHTML = "";
            const start = (page - 1) * rowsPerPage;
            const end = start + rowsPerPage;
            const tareasPaginadas = guiadeopereacion.slice(start, end);
          
            tareasPaginadas.forEach(tarea => {
              const row = document.createElement('tr');
              row.innerHTML = `
                <td>${tarea.visitLabel}</td>
                <td>${tarea.type}</td>
                <td>${tarea.duration}</td>
                <td>${tarea.pickups}</td>
              `;
              tbody.appendChild(row);
            });
          
            // Actualizar contador de página
            pageIndicator.textContent = `Página ${currentPage} de ${totalPages} | ${guiadeopereacion.length}`;
          };
          
        
          table.appendChild(thead);
          table.appendChild(tbody);
          tablaContainer.appendChild(table);
        
          // Controles de paginación
          const paginationContainer = document.createElement('div');
          paginationContainer.classList.add('pagination-container');
        
          const prevButton = document.createElement('button');
          prevButton.textContent = "⬅️ Anterior";
          prevButton.disabled = true;
          prevButton.addEventListener("click", () => {
            if (currentPage > 1) {
              currentPage--;
              renderTablePage(currentPage);
              nextButton.disabled = false;
              if (currentPage === 1) prevButton.disabled = true;
            }
          });
        
          const pageIndicator = document.createElement('span');
          pageIndicator.classList.add('page-indicator');
        
          const nextButton = document.createElement('button');
          nextButton.textContent = "Siguiente ➡️";
          if (guiadeopereacion.length <= rowsPerPage) nextButton.disabled = true;
          nextButton.addEventListener("click", () => {
            if (currentPage < totalPages) {
              currentPage++;
              renderTablePage(currentPage);
              prevButton.disabled = false;
              if (currentPage === totalPages) nextButton.disabled = true;
            }
          });
        
          paginationContainer.appendChild(prevButton);
          paginationContainer.appendChild(pageIndicator);
          paginationContainer.appendChild(nextButton);
          tablaContainer.appendChild(paginationContainer);
        
          // Renderizar la primera página de la tabla
          renderTablePage(currentPage);
        
          // Agregar mapa y tabla al contenedor principal
          contentContainer.appendChild(mapContainer);
          contentContainer.appendChild(tablaContainer);
        
          // Agregar elementos al wrapper
          mapWrapper.appendChild(titulo);
          mapWrapper.appendChild(contentContainer);
          mapasContainer.appendChild(mapWrapper);
        
          // Inicializar el mapa con Google Maps
          const mapa = new google.maps.Map(mapContainer, {
            center: { lat: datosFiltrados[0].Latitude, lng: datosFiltrados[0].Longitude },
            zoom: 14,
          });
        
          this.addGeozona(datosFiltrados, color, mapa);
   this.calcularRutaConWaypoints(datosFiltrados,mapa)

        }
        // Acá pasás las tareas de la ruta al método que quieras

    
      } else {
        console.error("El valor no es un arreglo o está vacío:", datosFiltrados);
      }

  
    } 

    

   
   
  }
  
  onEsquemaChange() {
    this.esquemasView = []
    console.log('ID del esquema seleccionado:', this.esquemaSeleccionado);
  
    // Si quieres obtener todo el objeto del esquema, haz esto:
    const esquemaObj = this.esquemas.find(e => e.id === this.esquemaSeleccionado);
    this.esquemasView.push(esquemaObj)
    console.log('Objeto esquema seleccionado:', esquemaObj);

    const hoy = new Date();
  
    // Obtener la fecha en formato YYYY-MM-DD
    const fechaISO = hoy.toISOString().split('T')[0];
  
    // Construir fecha completa en UTC con la hora de inicio y fin
     // Construir fechas con la hora del modelo en UTC
    const earliest_start = new Date(`${fechaISO}T${esquemaObj.modeloGestion.modelo.horaInicio}:00Z`).toISOString();
    const latest_end = new Date(`${fechaISO}T${esquemaObj.modeloGestion.modelo.horaFinal}:00Z`).toISOString();
    
    var tipo = 2; // Valor por defecto (paquete)
    if (this.criterioReabastecimiento == "peso") {
      tipo = 1;
    } else if (this.criterioReabastecimiento == "volumen") {
      tipo = 3;
    }
    

    this.vehiclesesquemaview = esquemaObj.recursos.flatMap((recurso: any, index: number) =>
      Array.from({ length: recurso.cantidad }, (_, i) => ({
        name: recurso.nombre + " " +  (index + i).toString(), 
        routing_profile:"mapbox/driving-traffic", // Generar un identificador único basado en el índice
        capacities: { 
          amount: this.getCapacidadSegunTipo(recurso.recurso, tipo)
        }, // Capacidad por unidad
        earliest_start,
        latest_end,
        start_location:"init",
        end_location:"end",
      }))
    );


  
    console.error(' vehiclesesquema', this.vehiclesesquemaview);
  }

  getCapacidadSegunTipo(recurso: any, tipo: number): number {
    switch(tipo) {
      case 1: // Peso
        return recurso.weight || 0; // Usar el peso del recurso
      case 2: // Paquete (valor por defecto)
        return recurso.boxes || 0; // Usar la cantidad de paquetes
      case 3: // Volumen
        return recurso.volume || 0; // Usar el volumen del recurso
      default:
        return recurso.boxes || 0; // Default a paquetes
    }
  }

  calculateDestination(lat: number, lng: number, distanceKm: number, bearing: number): { lat: number, lng: number } {
    const R = 6371; // Radio de la Tierra en km
    const latRad = this.degToRad(lat);
    const lngRad = this.degToRad(lng);
    const bearingRad = this.degToRad(bearing);
  
    const newLat = Math.asin(
      Math.sin(latRad) * Math.cos(distanceKm / R) +
      Math.cos(latRad) * Math.sin(distanceKm / R) * Math.cos(bearingRad)
    );
  
    const newLng = lngRad + Math.atan2(
      Math.sin(bearingRad) * Math.sin(distanceKm / R) * Math.cos(latRad),
      Math.cos(distanceKm / R) - Math.sin(latRad) * Math.sin(newLat)
    );
  
    return { lat: this.radToDeg(newLat), lng: this.radToDeg(newLng) };
  }
  
  degToRad(degrees: number): number {
    return degrees * (Math.PI / 180);
  }
  
  radToDeg(radians: number): number {
    return radians * (180 / Math.PI);
  }
  

  calcularRutaConWaypoints(ubicaciones: any[],mapa: google.maps.Map): void {
    const chunkSize = 23; // Máximo de waypoints por solicitud
    const waypointsChunks = chunkArray(ubicaciones.slice(1, -1), chunkSize); // Excluir origen y destino
    console.error('❌ Error al optimizar: ubicaciones', ubicaciones);
    const origin = `${ubicaciones[0].Latitude},${ubicaciones[0].Longitude}`; // Origen
    const destination = `${ubicaciones[ubicaciones.length - 1].Latitude},${ubicaciones[ubicaciones.length - 1].Longitude}`; // Destino
  
    waypointsChunks.forEach((waypointsChunk, index) => {
      const waypoints = waypointsChunk.map((ubicacion) => `${ubicacion.Latitude},${ubicacion.Longitude}`);
  
      
      this.resumenService.getRoute(origin, destination, waypoints).subscribe((response: any) => {
        if (response.status === 'OK') {
          const overviewPolyline = response.routes[0].overview_polyline.points;
          const coordenadas = this.decodePolyline(overviewPolyline); // Decodifica la polilínea
  
          // Dibuja la ruta en el mapa
          const ruta = new google.maps.Polyline({
            path: coordenadas,
            geodesic: true,
            strokeColor: 'red',
            strokeOpacity: 1.0,
            strokeWeight: 1,
          });
  
          ruta.setMap(mapa);
        } else {
          console.error(`Error al calcular la ruta (grupo ${index + 1}):`, response.status);
        }
      });
      
    });
  }

  calcularRutados(): void {
   
  }


  decodePolyline(encoded: string): { lat: number; lng: number }[] {
    let index = 0;
    const len = encoded.length;
    const array: { lat: number; lng: number }[] = [];
    let lat = 0;
    let lng = 0;
  
    while (index < len) {
      let b: number;
      let shift = 0;
      let result = 0;
  
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
  
      const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;
  
      shift = 0;
      result = 0;
  
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
  
      const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;
  
      array.push({ lat: lat * 1e-5, lng: lng * 1e-5 });
    }
  
    return array;
  }

   filterOutliers(points: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral[] {
    const lngValues = points.map((p) => p.lng);
    const latValues = points.map((p) => p.lat);
  
    const lngMean = lngValues.reduce((a, b) => a + b, 0) / lngValues.length;
    const latMean = latValues.reduce((a, b) => a + b, 0) / latValues.length;
  
    const lngStdDev = Math.sqrt(lngValues.map((x) => Math.pow(x - lngMean, 2)).reduce((a, b) => a + b) / lngValues.length);
    const latStdDev = Math.sqrt(latValues.map((x) => Math.pow(x - latMean, 2)).reduce((a, b) => a + b) / latValues.length);
  
    return points.filter((p) => {
      const zLng = Math.abs((p.lng - lngMean) / lngStdDev);
      const zLat = Math.abs((p.lat - latMean) / latStdDev);
      return zLng < 2 && zLat < 2; // Ajusta este umbral
    });
  }
  
  createConcaveHull(points: PuntoConOrden[]): google.maps.LatLngLiteral[] {

    
    const outsideFeatures = points
    .map((point) => {
      try {
        return turf.point([point.lng, point.lat]);
      } catch (error) {
        console.error('Error al crear el punto:', point, error);
        return null; // Omitir el punto problemático
      }
    })
    .filter(Boolean); // Filtra los puntos nulos

  console.log('Features para convex hull:', outsideFeatures);
  
    if (points.length < 3) return points;
  
    const turf = require('@turf/turf');
    console.error('❌ Error al optimizar: 1');
    // Ordenar puntos por ordenVisita
    const puntosOrdenados = this.ordenarPorVisita(points);
    console.error('❌ Error al optimizar: 2');
    // Conectar puntos en el orden de visita
    const maxDistance = 0.1; // Ajusta este valor (en km)
    const puntosConectados = this.conectarPuntosEnOrden(puntosOrdenados, maxDistance);
    console.error('❌ Error al optimizar: 3');
    // Filtra puntos atípicos
    const filteredPoints = this.filterOutliers(puntosConectados);
    console.error('❌ Error al optimizar: 4');
    const pointsGeoJSON = turf.featureCollection(
      filteredPoints.map((point: any) => turf.point([point.lng, point.lat]))
    );
  
    const options = { maxEdge: 0.3 }; // Ajusta este valor
    const concaveHull = turf.concave(pointsGeoJSON, options);

    
    console.error('❌ Error al optimizar: 5');
    if (!concaveHull) {
      //const convex = turf.convex(pointsGeoJSON);

      let convex;
try {
  convex = turf.convex(pointsGeoJSON);
  console.log('Convex hull generado:', convex);
} catch (error) {
  console.error('Error al generar el convex hull:', error);

  convex = this.createConvexHull(points);
  console.log('Convex hull generado con createConvexHull:', convex);
}
      return convex.geometry.coordinates[0].map((coord: any) => ({
        lng: coord[0],
        lat: coord[1]
      }));
    }
    console.error('❌ Error al optimizar: 6');
    // Verifica si hay puntos fuera del concave hull
    const pointsOutside = points.filter((point) => !turf.booleanPointInPolygon(turf.point([point.lng, point.lat]), concaveHull));
    console.error('❌ Error al optimizar: 7');
    if (pointsOutside.length > 0) {


      const outsideFeatures = pointsOutside.map((point: any) => {
        if (!point.lng || !point.lat) {
          console.error('Punto inválido:', point);
          return null;
        }
        return turf.point([point.lng, point.lat]);
      }).filter(Boolean); // Filtra puntos nulos
      
      console.log('Features para convex hull:', outsideFeatures);
      
      if (outsideFeatures.length < 3) {
        console.error('Se necesitan al menos 3 puntos válidos para generar un convex hull.');
        return concaveHull.geometry.coordinates[0].map((coord: any) => ({
          lng: coord[0],
          lat: coord[1],
        }));
      }
      
      const outsideGeoJSON = turf.featureCollection(outsideFeatures);
      console.log('GeoJSON para convex hull:', outsideGeoJSON);


      const convexHull = turf.convex(turf.featureCollection(
        pointsOutside.map((point: any) => turf.point([point.lng, point.lat]))
      ));

      
      console.error('❌ Error al optimizar: 7.5', convexHull);
      const combinedHull = turf.union(concaveHull, convexHull);

      console.error('❌ Error al optimizar: 8', combinedHull);
      return combinedHull.geometry.coordinates[0].map((coord: any) => ({
        lng: coord[0],
        lat: coord[1]
      }));

    }

    return concaveHull.geometry.coordinates[0].map((coord: any) => ({
      lng: coord[0],
      lat: coord[1]
    }));
  }
  
  // Función para ordenar puntos por ordenVisita
   ordenarPorVisita(points: PuntoConOrden[]): PuntoConOrden[] {
    return points.sort((a, b) => a.ordenVisita - b.ordenVisita);
  }
  
  // Función para conectar puntos en el orden de visita
  conectarPuntosEnOrden(points: PuntoConOrden[], maxDistance: number): google.maps.LatLngLiteral[] {
    const connectedPoints: google.maps.LatLngLiteral[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      const currentPoint = points[i];
      const nextPoint = points[i + 1];
      const interpolated = this.interpolatePoints(currentPoint, nextPoint, maxDistance);
      connectedPoints.push(...interpolated);
    }
    // Cierra el polígono conectando el último punto con el primero
    const interpolated = this.interpolatePoints(points[points.length - 1], points[0], maxDistance);
    connectedPoints.push(...interpolated);
    return connectedPoints;
  }
  
  // Función para interpolar puntos
  interpolatePoints(point1: google.maps.LatLngLiteral, point2: google.maps.LatLngLiteral, maxDistance: number): google.maps.LatLngLiteral[] {
    const distance = this.calculateDistance(point1, point2);
    if (distance <= maxDistance) {
      return [point1, point2];
    }
  
    const numPoints = Math.ceil(distance / maxDistance);
    const interpolatedPoints = [];
    for (let i = 0; i <= numPoints; i++) {
      const fraction = i / numPoints;
      const lat = point1.lat + fraction * (point2.lat - point1.lat);
      const lng = point1.lng + fraction * (point2.lng - point1.lng);
      interpolatedPoints.push({ lat, lng });
    }
    return interpolatedPoints;
  }
  
  // Función para calcular la distancia entre dos puntos
  calculateDistance(point1: google.maps.LatLngLiteral, point2: google.maps.LatLngLiteral): number {
    const R = 6371; // Radio de la Tierra en km
    const dLat = (point2.lat - point1.lat) * (Math.PI / 180);
    const dLng = (point2.lng - point1.lng) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(point1.lat * (Math.PI / 180)) * Math.cos(point2.lat * (Math.PI / 180)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distancia en km
  }

   validarYConvertirCoordenada(valor: any): number | null {
    // Convierte el valor a número
    const numero = parseFloat(valor);
  
    // Verifica si el valor es un número finito
    if (isNaN(numero) || !isFinite(numero)) {
      console.error('Coordenada inválida:', valor);
      return null;
    }
  
    return numero;
  }
   validarYConvertirPuntos(puntos: any[]): { lng: number; lat: number }[] {
    return puntos
      .map((punto) => {
        const lng = this.validarYConvertirCoordenada(punto.lng);
        const lat = this.validarYConvertirCoordenada(punto.lat);
  
        // Si alguna coordenada es inválida, descarta el punto
        if (lng === null || lat === null) {
          console.error('Punto inválido descartado:', punto);
          return null;
        }
  
        return { lng, lat };
      })
      .filter(Boolean); // Filtra puntos nulos
  }

  convertDecimalToTime(decimalHour: number): string {
    const hours = Math.floor(decimalHour);
    const minutesDecimal = (decimalHour - hours) * 60;
    const minutes = Math.floor(minutesDecimal);
    const secondsDecimal = (minutesDecimal - minutes) * 60;
    const seconds = Math.round(secondsDecimal);
  
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }
  
  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

onDateChange(event: Event) {
  const input = event.target as HTMLInputElement;
  const selectedDate = input.value;


  // Aquí puedes filtrar los datos de los marcadores según la fecha seleccionada
  this.updateMarkersByDate(selectedDate);
}

updateMarkersByDate(date: string) {
  // 🔹 Filtra los datos que coincidan con la fecha seleccionada y actualiza el mapa
  
}



 drawDottedLine(map: google.maps.Map, start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral, steps: number = 10) {
  const line = new google.maps.Polyline({
    path: [start, end],
    geodesic: true,
    strokeOpacity: 0, // 🔹 Oculta la línea principal
    icons: [{
        icon: {
            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            scale: 1, // 🔹 Aumenta el tamaño de la flecha
            strokeOpacity: 1,
            strokeWeight: 2,
            fillOpacity: 1,
            fillColor: "#FF0000"
        },
        offset: "0",
        repeat: "10px" // 🔹 Mayor distancia entre flechas
    }],
    map: map
  });
}



generarColorFosforescenteOscuro(): string {
  
  const baseColors = [
    [180, 30, 30],  // Rojo profundo
    [30, 180, 30],  // Verde intenso
    [30, 30, 180],  // Azul eléctrico
    [160, 30, 160], // Púrpura oscuro
    [30, 160, 160], // Cian profundo
    [180, 100, 30]  // Naranja oscuro
  ];

  // Selecciona un color base aleatorio
  const base = baseColors[Math.floor(Math.random() * baseColors.length)];

  // Genera una variación fuerte dentro de un rango oscuro
  const r = Math.max(30, Math.min(200, base[0] + Math.floor(Math.random() * 80 - 40))); 
  const g = Math.max(30, Math.min(200, base[1] + Math.floor(Math.random() * 80 - 40))); 
  const b = Math.max(30, Math.min(200, base[2] + Math.floor(Math.random() * 80 - 40))); 
  const alpha = 0.9; // Mantiene alta opacidad pero con un leve toque translúcido

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

}

class ColorGenerator {
  private usedColors: Set<string> = new Set();

  generarColorFosforescenteOscuro(): string {
    let color: string;
    let attempts = 0; // Evita bucles infinitos

    do {
      // Colores base oscuros pero vibrantes
      const baseColors = [
        [180, 30, 30],  // Rojo profundo
        [30, 180, 30],  // Verde intenso
        [30, 30, 180],  // Azul eléctrico
        [160, 30, 160], // Púrpura oscuro
        [30, 160, 160], // Cian profundo
        [180, 100, 30]  // Naranja oscuro
      ];

      // Selecciona un color base aleatorio
      const base = baseColors[Math.floor(Math.random() * baseColors.length)];

      // Genera una variación fuerte dentro de un rango oscuro
      const r = Math.max(30, Math.min(200, base[0] + Math.floor(Math.random() * 80 - 40))); 
      const g = Math.max(30, Math.min(200, base[1] + Math.floor(Math.random() * 80 - 40))); 
      const b = Math.max(30, Math.min(200, base[2] + Math.floor(Math.random() * 80 - 40))); 
      const alpha = 0.9;

      color = `rgba(${r}, ${g}, ${b}, ${alpha})`;
      attempts++;

      // Si se generan demasiados intentos sin éxito, se rompe el bucle
      if (attempts > 10) break;

    } while (this.usedColors.has(color)); // Verifica si ya existe en el historial

    // Agrega el color al historial para evitar duplicados
    this.usedColors.add(color);

    return color;
  }

  resetColors(): void {
    this.usedColors.clear(); // Limpia el historial si es necesario
  }
  
 
  
}


function chunkArray(array: any[], chunkSize: number): any[][] {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}


const EARTH_RADIUS = 6371e3; // Radio de la Tierra en metros
const MAX_DISTANCE = 500; // Distancia máxima en metros para agrupar en una geozona



// 📌 Función para calcular la distancia entre dos puntos con la fórmula de Haversine
function haversineDistance(loc1: Location, loc2: Location): number {
  const R = 6371; // Radio de la Tierra en km
  const toRadians = (deg: number) => (deg * Math.PI) / 180;

  const dLat = toRadians(loc2.latitude - loc1.latitude);
  const dLon = toRadians(loc2.longitude - loc1.longitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(loc1.latitude)) * Math.cos(toRadians(loc2.latitude)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distancia en km
}

// 📌 Algoritmo de Dijkstra
function dijkstra(locations: Location[], startId: number): Map<number, number | null> {
  const distances = new Map<number, number>(); // Distancia mínima a cada nodo
  const previous = new Map<number, number | null>(); // Nodo anterior en la mejor ruta
  const unvisited = new Set<number>(); // Nodos no visitados

  // Inicializar todos los nodos con distancia infinita y sin predecesores
  locations.forEach(loc => {
    distances.set(loc.id, Infinity);
    previous.set(loc.id, null);
    unvisited.add(loc.id);
  });

  distances.set(startId, 0); // Nodo de inicio con distancia 0

  while (unvisited.size > 0) {
    // Encontrar el nodo no visitado con la menor distancia conocida
    let currentId: number | null = null;
    let minDistance = Infinity;

    for (const id of unvisited) {
      if (distances.get(id)! < minDistance) {
        minDistance = distances.get(id)!;
        currentId = id;
      }
    }

    if (currentId === null) break; // Si no hay nodo válido, salir del bucle

    unvisited.delete(currentId); // Marcar como visitado
    const currentLocation = locations.find(loc => loc.id === currentId);
    if (!currentLocation) continue;

    // Actualizar las distancias de los vecinos
    for (const neighbor of locations) {
      if (!unvisited.has(neighbor.id)) continue; // Ignorar nodos ya visitados

      const distance = haversineDistance(currentLocation, neighbor);
      const newDistance = distances.get(currentId)! + distance;

      if (newDistance < distances.get(neighbor.id)!) {
        distances.set(neighbor.id, newDistance);
        previous.set(neighbor.id, currentId);
      }
    }
  }

  return previous; // Retorna el mapa de rutas más cortas
}



export function groupLocationsByGeoZone(locations: Location[]): GeoZone[] {
  let geoZones: GeoZone[] = [];
  let zoneId = 1;

  locations.forEach(location => {
    let foundZone = false;

    for (let zone of geoZones) {
      if (zone.locations.some(loc => haversineDistance(loc, location) <= MAX_DISTANCE)) {
        zone.locations.push(location);
        foundZone = true;
        break;
      }
    }

    if (!foundZone) {
      geoZones.push({ id: zoneId++, locations: [location] });
    }
  });

  return geoZones;
}

function findOptimalRoute(locations: Location[]): Location[] {
  if (locations.length <= 1) return locations;

  const route: Location[] = [];
  const remaining = [...locations];

  // Iniciar desde el primer punto
  let current = remaining.shift()!;
  route.push(current);

  while (remaining.length > 0) {
    // Buscar el punto más cercano
    let nearestIndex = 0;
    let minDistance = haversineDistance(current, remaining[0]);

    for (let i = 1; i < remaining.length; i++) {
      const distance = haversineDistance(current, remaining[i]);
      if (distance < minDistance) {
        minDistance = distance;
        nearestIndex = i;
      }
    }

    // Mover el punto más cercano a la ruta
    current = remaining.splice(nearestIndex, 1)[0];
    route.push(current);
  }

  return route;
}





