import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';
import { take } from 'rxjs/operators';
interface EstadoResumen {
  estado: string;
  count: number;
  totalPesoNeto: number;
  totalCosto: number;
}

interface RutaResumen {
  rutaVLI: string;
  estados: EstadoResumen[];
}

 interface ResumenTareas {
  rutas: RutaResumen[];
  totalPorEstado: EstadoResumen[];
  totalDeTareas: number;
  totalDelCosto: number;
}

interface ResultadoFiltrado {
  rutasFiltradas: RutaResumen[];
  novedades: EstadoResumen[];
  totalNovedades: number;
  totalCostoNovedades: number;
}

interface RutaResumen {
  ruta: string;
  txtTotalFac: number;
  tareas: number;
  CanExi: number;
  CanPar: number;
  CanPen: number;
  CanNov: number;
}

interface EstadoTareas {
  count: number;
  totalCosto: number;
}
@Component({
  selector: 'app-fragmentIndicadoresapi',
  templateUrl: './fragmentIndicadoresapi.component.html',
  styleUrls: ['./fragmentIndicadoresapi.component.scss'],
  encapsulation: ViewEncapsulation.None
})



@Injectable()
export class FragmentIndicadoresapi implements OnInit {
  mesSeleccionado: number = this.orderService.getMesActual();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('MatPaginator2', { static: false }) paginatordev: MatPaginator;
  @ViewChild('MatPaginator3', { static: false }) paginatorzona: MatPaginator;
  @ViewChild('MatPaginator4', { static: false }) paginatornov: MatPaginator;
  @ViewChild('MatPaginator5', { static: false }) paginatortipo: MatPaginator;
  @ViewChild('MatPaginator6', { static: false }) paginatorestado: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator9', { static: false }) paginatorestadovalor: MatPaginator;
  fechaInicio: string = '';
  fechaFin: string = '';

  private fechaSubs: Subscription = new Subscription();
  private negocio: any;
  private agencia: any;
  private txtTotalFac: any;
  private txtTotalFacRuta: any;
  private txtPesoKG: any;
  private txtPesoKGDe: any;
  private txtPedidos: any;
  private txtPedidosRuta: any;
  private txtDias: any;
  private txtRutas: any;
  private txtNovedades: any;
  private txtPedidosDev: any;
  private txtPedidosDevZona: any;
  private txtCanGrupo: any;
  private txtDevGrupo: any;
  private txtGrupoPedidos: any;
  private txtGrupoDev: any;
  private txttipoNovedad: any;
  private txtFecha: any;

  private estadoExitosa: any;
  private estadoParcial: any;
  private estadoRepisar: any;
  private estadoPendiente: any;
  private estadoNovedad: any;
  private subscription: Subscription;

  //novedades


  Remove(args): void {
    args.cancel = true;
  }

  constructor(private route: Router,private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager,private resumenTareasService: ResumenTareasService) {

 
  }


  displayedColumnsRuta: string[] = ['ruta', 'txtTotalFac', 'totaltareas', 'CanExi', 'CanPar', 'CanPen', 'CanNov'];
  dataSourceRuta = new MatTableDataSource();



  ngOnInit(): void {
    
    if (!this.subscription) {
      this.subscription = this.resumenTareasService.resumen$.subscribe((data) => {
        console.log('Usuario api:', data);
        this.procesarDatos(data); // Procesar los datos recibidos
      });
    }


  
  }

  procesarDatos(data: any) {
              

      
    if (data !== null){
     this.txtFecha = `${this.orderService.getFechaInicio()} / ${this.orderService.getFechaFin()}`
    const novedades = this.filterNovedades(data);
    const exitosa = this.filterExitosa(data);
    const parcial = this.filterParcial(data);
    const repisar = this.filterRepisar(data);
    const pendientes = this.filterPendientes(data);
    this.txtTotalFac = data.totalDelCosto
    this.txtPedidos = data.totalDeTareas
    this.txtRutas = data.rutas.length
    this.txtPedidosDev = novedades.count
    this.txtNovedades = novedades.totalCosto
    this.txtPesoKG = data.totalPesoNeto
    this.txtPesoKGDe = novedades.totalPesoNeto

      this.dataSourceRuta.data = data.tareasruta

      this.estadoExitosa = exitosa.totalCosto
      this.estadoParcial = parcial.totalCosto
      this.estadoNovedad = novedades.totalCosto
      this.estadoPendiente = pendientes.totalCosto
      this.estadoRepisar = repisar.totalCosto
     }
  }


  filterNovedades(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "NOVEDAD");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  filterExitosa(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "EXITOSA");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  filterParcial(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "PARCIAL");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  filterRepisar(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "REPISAR");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }


  filterPendientes(resumen: ResumenTareas): { count: number; totalCosto: number ,totalPesoNeto: number} {
    const novedad = resumen.totalPorEstado.find(e => e.estado === "PENDIENTE");
  
    return {
      count: novedad ? novedad.count : 0,
      totalCosto: novedad ? novedad.totalCosto : 0,
      totalPesoNeto: novedad ? novedad.totalPesoNeto : 0,
    };
  }

  ngAfterViewInit() {
  
    this.dataSourceRuta.paginator = this.paginatorruta;
    
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      console.log('Suscripción cancelada');
    }
  }
  atras():void {

    this.route.navigate(['/OrdenTransporte']);
    
  }

}

