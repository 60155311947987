import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';


declare var google: any; // Declara la variable global de Google Maps
var negocio: any;
var agencia: any;

interface Visit {
  isPickup: boolean;
  startTime: string;
  detour: number;
}

interface Transition {
  travelDuration: string;
  travelDistanceMeters: number;
  waitDuration: string;
  totalDuration: string;
  startTime: string;
}

interface Metrics {
  performedShipmentCount: number;
  travelDuration: string;
  waitDuration: string;
  delayDuration: string;
  breakDuration: string;
  visitDuration: string;
  totalDuration: string;
  travelDistanceMeters: number;
}

interface Route {
  vehicleStartTime: string;
  vehicleEndTime: string;
  visits: Visit[];
  transitions: Transition[];
  metrics: Metrics;
  routeCosts: number[];
  routeTotalCost: number;
}

interface Response {
  routes: Route[];
}
@Component({
  selector: 'app-map',
  templateUrl: './FragmentMapaGeneral.component.html', // Usa solo templateUrl
  styleUrls: ['./FragmentMapaGeneral.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FragmentMapaGeneral implements OnInit, OnDestroy,AfterViewInit {
  fechaInicio: string = new Date().toISOString().split('T')[0]; // Fecha actual
  intervalo: any;
  ultimaUbicacionlista : any[] =  []
  response: any; // Almacena la respuesta de la API
  routes: any[] = []; // Almacena las rutas procesadas
  seleccionarTodos: boolean = true; // No selecciona todos al inicio
  usuarioSeleccionado: any = null;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  map!: google.maps.Map;
  vehicles: any[] = [
    {
      loadLimits: {
        weight: {
          maxLoad: 50,
        },
      },
      endLocation: {
        latitude: 48.86311,
        longitude: 2.341205,
      },
      startLocation: {
        latitude: 48.863102,
        longitude: 2.341204,
      },
    },
    {
      loadLimits: {
        weight: {
          maxLoad: 60,
        },
      },
      endLocation: {
        latitude: 48.86312,
        longitude: 2.341215,
      },
      startLocation: {
        latitude: 48.863112,
        longitude: 2.341214,
      },
    },
  ];

  shipments: any[] = [
    {
      deliveries: [
        {
          arrivalLocation: {
            latitude: 48.880942,
            longitude: 2.323866,
          },
          duration: '250s',
          timeWindows: [
            {
              endTime: '1970-01-01T01:06:40Z',
              startTime: '1970-01-01T00:50:00Z',
            },
          ],
        },
      ],
      loadDemands: {
        weight: {
          amount: '10',
        },
      },
      pickups: [
        {
          arrivalLocation: {
            latitude: 48.874507,
            longitude: 2.30361,
          },
          duration: '150s',
          timeWindows: [
            {
              endTime: '1970-01-01T00:33:20Z',
              startTime: '1970-01-01T00:16:40Z',
            },
          ],
        },
      ],
    },
    {
      deliveries: [
        {
          arrivalLocation: {
            latitude: 48.88094,
            longitude: 2.323844,
          },
          duration: '251s',
          timeWindows: [
            {
              endTime: '1970-01-01T01:06:41Z',
              startTime: '1970-01-01T00:50:01Z',
            },
          ],
        },
      ],
      loadDemands: {
        weight: {
          amount: '20',
        },
      },
      pickups: [
        {
          arrivalLocation: {
            latitude: 48.880943,
            longitude: 2.323867,
          },
          duration: '151s',
          timeWindows: [
            {
              endTime: '1970-01-01T00:33:21Z',
              startTime: '1970-01-01T00:16:41Z',
            },
          ],
        },
      ],
    },
  ];

  result: any;
  error: any;
  private coloresUsuarios: Record<string, string> = {}; // Colores por usuario
  constructor(private resumenService: ResumenTareasService) {

  }
  ngOnDestroy(): void {
    if (this.intervalo) {
      clearInterval(this.intervalo);
    }
  }

  optimizeRoutes() {
    this.resumenService
      .optimizeRoutes(this.vehicles, this.shipments)
      .subscribe(
        (response) => {
          console.log('✅ Respuesta del backend:', response);
          this.response = response;
          this.processResponse(response); // Procesar la respuesta
        },
        (error) => {
          console.error('❌ Error:', error);
        }
      );
  }

  processResponse(response: Response | null) {
    if (!response || !response.routes) {
      console.warn('Invalid or empty response');
      return;
    }
  
    this.routes = response.routes.map(route => this.mapRoute(route));
  }
  
  mapRoute(route: Route) {
    return {
      vehicleStartTime: route.vehicleStartTime || '',
      vehicleEndTime: route.vehicleEndTime || '',
      visits: this.mapVisits(route.visits),
      transitions: this.mapTransitions(route.transitions),
      metrics: this.mapMetrics(route.metrics),
      routeCosts: route.routeCosts || [],
      routeTotalCost: route.routeTotalCost || 0,
    };
  }
  
   mapVisits(visits: Visit[]) {
    if (!visits) return [];
    return visits.map(visit => ({
      isPickup: visit.isPickup || false,
      startTime: visit.startTime || '',
      detour: visit.detour || 0,
    }));
  }
  
   mapTransitions(transitions: Transition[]) {
    if (!transitions) return [];
    return transitions.map(transition => ({
      travelDuration: transition.travelDuration || '',
      travelDistanceMeters: transition.travelDistanceMeters || 0,
      waitDuration: transition.waitDuration || '',
      totalDuration: transition.totalDuration || '',
      startTime: transition.startTime || '',
    }));
  }
  
   mapMetrics(metrics: Metrics) {
    if (!metrics) return {} as Metrics;
    return {
      performedShipmentCount: metrics.performedShipmentCount || 0,
      travelDuration: metrics.travelDuration || '',
      waitDuration: metrics.waitDuration || '',
      delayDuration: metrics.delayDuration || '',
      breakDuration: metrics.breakDuration || '',
      visitDuration: metrics.visitDuration || '',
      totalDuration: metrics.totalDuration || '',
      travelDistanceMeters: metrics.travelDistanceMeters || 0,
    };
  }

  ngOnInit() {
    // Iniciar el intervalo al cargar el componente
    this.intervalo = setInterval(() => {
     // this.consultarFecha();
    }, 180000); // 180,000 ms = 3 minutos
  }

  ngAfterViewInit(): void {
    negocio = localStorage.getItem("negocio");
    agencia = localStorage.getItem("agencia");
    const colorGen = new ColorGenerator();

    /*
    this.resumenService.optimizeRoutes(this.vehicles, this.shipments).subscribe({
      next: (data) => {
        console.log('✅ Optimización recibida:', data);
        this.result = data;

        data.routes.forEach(route => {
          console.log("Vehículo sale:", route.vehicleStartTime);
          console.log("Vehículo termina:", route.vehicleEndTime);
        });

        data.visits.forEach((visit, index) => {
          const action = visit.isPickup ? "Recolectar" : "Entregar";
          console.log(`#${index + 1}: ${action} a las ${visit.startTime}`);
        });
      },
      error: (err) => {
        console.error('❌ Error al optimizar:', err);
        this.error = err;
      }
    });

  */
  
    // Llamar al servicio para obtener las ubicaciones
    this.resumenService.getUbicaciones(negocio, agencia, this.fechaInicio)
        .subscribe({
            next: (data) => {
              console.log('📌 Resumen consultarTareas:', data);
              this.ultimaUbicacionlista = data.ultimasUbicaciones
        
                // Inicializar el mapa
                this.initMap(data.centroide);

                // Pintar los marcadores con los datos recuperados
             


                data.ultimasUbicaciones.forEach((usuario: any) => {

                  
           
                  const color =  colorGen.generarColorFosforescenteOscuro()
                  this.addMarkers(usuario, color); // 🔹 Pasar los datos al método
                  // 📍 3️⃣ Agregar marcador con color
                  new google.maps.Marker({
                    position: { lat: usuario.latitud, lng: usuario.longitud },
                    map: this.map,
                    title: usuario.usuario,
                    icon: {
                      path: google.maps.SymbolPath.CIRCLE,
                      scale: 8,
                      fillColor: color,
                      fillOpacity: 1,
                      strokeWeight: 1
                    }
                  });

            
                  this.addMarkersUbi(usuario.tareas,color); // 🔹 Pasar los datos al método
          
                  //const geozonaCoords = tarea.tareas.map((p: any) => ({ lat: p.lat, lng: p.lon }));
          
                  this.addGeozona(usuario.tareas,color);


                });
          
              
               
            },
            error: (error) => {
    
            }
        });

      

}

  initMap(center: any): void {
    const options: google.maps.MapOptions = {
      center: { lat: center.longitud, lng: center.latitud }, // 🔹 Centro en Colombia
      zoom: 7 // 🔹 Zoom inicial (ajusta según sea necesario)
  };

    this.map = new google.maps.Map(this.mapContainer.nativeElement, options);

 
  }

  toggleTodos() {
    if (this.seleccionarTodos) {
      this.usuarioSeleccionado = null; // Se desmarca cualquier selección individual
    }

  }

  // Verifica si el checkbox "Seleccionar Todos" debe estar activado o no
  verificarSeleccion() {
    if (this.usuarioSeleccionado) {
      this.seleccionarTodos = false; // Si seleccionas un usuario, desmarca "Seleccionar Todos"
    }



  }

  consultarFecha() {


    
    negocio = localStorage.getItem("negocio");
    agencia = localStorage.getItem("agencia");
    const colorGen = new ColorGenerator();

    if (this.seleccionarTodos) {
      this.usuarioSeleccionado = null; // Se desmarca cualquier selección individual
      this.resumenService.getUbicaciones(negocio, agencia, this.fechaInicio)
      .subscribe({
          next: (data) => {
            console.log('📌 Resumen consultarTareas:', data);
            this.ultimaUbicacionlista = data.ultimasUbicaciones
              
  
              // Inicializar el mapa
              this.initMap(data.centroide);
  
              // Pintar los marcadores con los datos recuperados
           
  
  
              data.ultimasUbicaciones.forEach((usuario: any) => {
  
                
         
                const color =  colorGen.generarColorFosforescenteOscuro()
                this.addMarkers(usuario, color); // 🔹 Pasar los datos al método
                // 📍 3️⃣ Agregar marcador con color
                new google.maps.Marker({
                  position: { lat: usuario.latitud, lng: usuario.longitud },
                  map: this.map,
                  title: usuario.usuario,
                  icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 8,
                    fillColor: color,
                    fillOpacity: 1,
                    strokeWeight: 1
                  }
                });
  
              
  
                this.addMarkersUbi(usuario.tareas,color); // 🔹 Pasar los datos al método
        
                //const geozonaCoords = tarea.tareas.map((p: any) => ({ lat: p.lat, lng: p.lon }));
        
                this.addGeozona(usuario.tareas,color);
  
  
              });


            
            
             
          },
          error: (error) => {
              console.error('❌ Error al obtener ubicaciones:', error);
          }
      });
    }else {

      negocio = localStorage.getItem("negocio");
      agencia = localStorage.getItem("agencia");
      const colorGen = new ColorGenerator();
      this.resumenService.getUbicacionesUsuario(negocio, agencia, this.fechaInicio,this.usuarioSeleccionado.usuario)
      .subscribe({
          next: (data) => {
           
            console.log('📌 Resumen consultarTareas:', data);
              // Inicializar el mapa
              this.initMap(data.centroide);
  
              // Pintar los marcadores con los datos recuperados
           
  
          
              data.ultimasUbicaciones.forEach((usuario: any) => {
  
                
         
                const color =  colorGen.generarColorFosforescenteOscuro()
                this.addMarkers(usuario, color); // 🔹 Pasar los datos al método
                // 📍 3️⃣ Agregar marcador con color
                new google.maps.Marker({
                  position: { lat: usuario.latitud, lng: usuario.longitud },
                  map: this.map,
                  title: usuario.usuario,
                  icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 8,
                    fillColor: color,
                    fillOpacity: 1,
                    strokeWeight: 1
                  }
                });
  
             
  
                this.addMarkersUbi(usuario.tareas,color); // 🔹 Pasar los datos al método
        
                //const geozonaCoords = tarea.tareas.map((p: any) => ({ lat: p.lat, lng: p.lon }));
        
                this.addGeozona(usuario.tareas,color);
  
  
              });

    

              const ubicaciones = Object.values(data.todasLasUbicaciones)[0];
              console.error("uno El valor no es un arreglo o está vacío:", ubicaciones);

              if (Array.isArray(ubicaciones) && ubicaciones.length > 0) {
                // Filtrar ubicaciones con coordenadas válidas
                console.error("El ubicaciones:", ubicaciones);
                this.calcularRutaConWaypoints(ubicaciones)
              } else {
                console.error("El valor no es un arreglo o está vacío:", ubicaciones);
              }
   
          },
          error: (error) => {
              console.error('❌ Error al obtener ubicaciones:', error);
          }
      });

  }
  
  

  this.calcularRutados()
    
  }

  addGeozona(recorridos: any[], color:String): void {
    const points: google.maps.LatLngLiteral[] = recorridos.map(location => ({
      lat: location.lat,
      lng: location.lon
    }));
  
    // 🔹 1. Calcular el Convex Hull (envolvente convexa)
    const geozonaCoords = this.createConvexHull(points);
  
    // 🔹 2. Generar un color dinámico

  
    // 🔹 3. Dibujar la geozona en el mapa
    const geozona = new google.maps.Polygon({
      paths: geozonaCoords,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.3
    });
  
    geozona.setMap(this.map);
  }

  calcularRutaConWaypoints(ubicaciones: any[]): void {
    const chunkSize = 23; // Máximo de waypoints por solicitud
    const waypointsChunks = chunkArray(ubicaciones.slice(1, -1), chunkSize); // Excluir origen y destino
  
    const origin = `${ubicaciones[0].longitud},${ubicaciones[0].latitud}`; // Origen
    const destination = `${ubicaciones[ubicaciones.length - 1].longitud},${ubicaciones[ubicaciones.length - 1].latitud}`; // Destino
  
    waypointsChunks.forEach((waypointsChunk, index) => {
      const waypoints = waypointsChunk.map((ubicacion) => `${ubicacion.longitud},${ubicacion.latitud}`);
  
      const originLat = 4.60971; // ejemplo Bogotá
    const originLng = -74.08175;
    const destLat = 4.65607; // ejemplo Usaquén
    const destLng = -74.05959;

    
    this.resumenService.optimizeRoutes(this.vehicles, this.shipments).subscribe({
      next: (data) => {
        console.log('✅ Optimización recibida:', data);
        this.result = data;
      },
      error: (err) => {
        console.error('❌ Error al optimizar:', err);
        this.error = err;
      }
    });

   
      
      this.resumenService.getRoute(origin, destination, waypoints).subscribe((response: any) => {
        if (response.status === 'OK') {
          const overviewPolyline = response.routes[0].overview_polyline.points;
          const coordenadas = this.decodePolyline(overviewPolyline); // Decodifica la polilínea
  
          // Dibuja la ruta en el mapa
          const ruta = new google.maps.Polyline({
            path: coordenadas,
            geodesic: true,
            strokeColor: 'red',
            strokeOpacity: 1.0,
            strokeWeight: 4,
          });
  
          ruta.setMap(this.map);
        } else {
          console.error(`Error al calcular la ruta (grupo ${index + 1}):`, response.status);
        }
      });
      
    });
  }

  calcularRutados(): void {
   
  }


  decodePolyline(encoded: string): { lat: number; lng: number }[] {
    let index = 0;
    const len = encoded.length;
    const array: { lat: number; lng: number }[] = [];
    let lat = 0;
    let lng = 0;
  
    while (index < len) {
      let b: number;
      let shift = 0;
      let result = 0;
  
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
  
      const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;
  
      shift = 0;
      result = 0;
  
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
  
      const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;
  
      array.push({ lat: lat * 1e-5, lng: lng * 1e-5 });
    }
  
    return array;
  }

  createConvexHull(points: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral[] {
    if (points.length < 3) return points;
  
    // Eliminar puntos duplicados
    const uniquePoints = Array.from(new Set(points.map(p => `${p.lat},${p.lng}`)))
      .map(p => {
        const [lat, lng] = p.split(',');
        return { lat: parseFloat(lat), lng: parseFloat(lng) };
      });
  
    // Ordenar puntos por latitud y luego por longitud
    const sorted = uniquePoints.sort((a, b) => a.lat === b.lat ? a.lng - b.lng : a.lat - b.lat);
  
    // Función para calcular el producto cruzado
    const crossProduct = (o: google.maps.LatLngLiteral, a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral) =>
      (a.lat - o.lat) * (b.lng - o.lng) - (a.lng - o.lng) * (b.lat - o.lat);
  
    // Construir la parte inferior del convex hull
    const lower: google.maps.LatLngLiteral[] = [];
    for (const point of sorted) {
      while (lower.length >= 2 && crossProduct(lower[lower.length - 2], lower[lower.length - 1], point) < 0) {
        lower.pop();
      }
      lower.push(point);
    }
  
    // Construir la parte superior del convex hull
    const upper: google.maps.LatLngLiteral[] = [];
    for (const point of sorted.reverse()) {
      while (upper.length >= 2 && crossProduct(upper[upper.length - 2], upper[upper.length - 1], point) < 0) {
        upper.pop();
      }
      upper.push(point);
    }
  
    // Eliminar el último punto de cada parte porque está duplicado
    upper.pop();
    lower.pop();
  
    // Combinar las partes inferior y superior
    return lower.concat(upper);
  }
  

addMarkers(location: any, color:String): void {
    // URL de la imagen personalizada para el icono
    const iconUrl = 'assets/trackrojo.svg'; // 🔹 Reemplaza con la URL de tu icono

    new google.maps.Marker({
      position: { lat: location.longitud, lng: location.latitud },
      map: this.map,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 0.8,
        strokeColor: "#000",
        strokeWeight: 1,
        scale: 30, // Tamaño del círculo
      }
    });

    const marker = new google.maps.Marker({
      position: { lat: location.longitud, lng: location.latitud }, // 🔹 Asegúrate de que lat y lng estén en el orden correcto
      map: this.map,
      title: location.usuario,
      icon: {
          url: iconUrl, // 🔹 URL del icono personalizado
          scaledSize: new google.maps.Size(30, 30) // 🔹 Tamaño del icono (ancho, alto)
      }
  });

  new google.maps.Marker({
    position: { lat: location.longitud, lng: location.latitud },
    map: this.map,
    label: {
      text:location.usuario.toString(), // Muestra el número en el centro
      color: "#FFF", // Color del número
      fontSize: "8px",
      fontWeight: "normal",
    },
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 0, // Hace invisible el ícono, solo se verá el texto
    }
  });

  const infoWindow = new google.maps.InfoWindow({
    content: `
        <div style="
            background-color: ${color}; /* Color dinámico */
            color: white; /* Color del texto */
            font-size: 6px;
            padding: 1px;
            max-width: 160px;
            border-radius: 2px;
            text-align: center;
        ">
            <h4 style="margin: 0; font-size: 14px;">${location.usuario}</h4>
            <p style="margin: 0px 0; font-size: 12px;"><strong>Dirección:</strong> ${location.direccion_geo}</p>
            <p style="margin: 0px 0; font-size: 12px;"><strong>Hora:</strong> ${location.hora}</p>
        </div>
    `
});


  // Hacer zoom y centrar el mapa al hacer clic en el marcador
  marker.addListener("click", () => {
      this.map.setCenter(marker.getPosition() as google.maps.LatLng);
      this.map.setZoom(14); // Nivel de zoom al hacer clic
      infoWindow.open(this.map, marker);
  });

  //infoWindow.open(this.map, marker);
}

onDateChange(event: Event) {
  const input = event.target as HTMLInputElement;
  const selectedDate = input.value;


  // Aquí puedes filtrar los datos de los marcadores según la fecha seleccionada
  this.updateMarkersByDate(selectedDate);
}

updateMarkersByDate(date: string) {
  // 🔹 Filtra los datos que coincidan con la fecha seleccionada y actualiza el mapa
  
}

addMarkersUbi(recorridos: any[], color:String): void {
  recorridos.forEach((location, index) => {

    if(location.latTarea > 0.0 && location.estado != "PENDIENTE"){
    const markerEnvio =  new google.maps.Marker({
      position: { lat: location.latTarea, lng: location.lonTarea },
      map: this.map,
      icon: {
        path: "M -5,-5 L 5,-5 L 5,5 L -5,5 Z",
        fillColor: "#FFFF",
        fillOpacity: 0.5,
        strokeColor: location.sitio_reporte === "SI" ? "green" : "red", // 🔹 Borde verde si "SI", rojo si no
        strokeWeight: 2, // 🔹 Puedes ajustar el grosor del borde si es necesario
        scale: 3, // Tamaño del círculo
      }
    });

    const infoWindowEnvio = new google.maps.InfoWindow({
      content: `
          <h4>${location.NombredeCliente}</h4>
          <p><strong>Dirección:</strong> ${location.Direccion}</p>
          <p><strong>Geo Dirección:</strong> ${location.direccion_geo}</p>
          <p><strong>Placa:</strong> ${location.Placa}</p>
          <p><strong>Punto:</strong> ${location.lon} / ${location.lat}</p>
          <p><strong>Reporte:</strong> ${location.lonTarea} / ${location.latTarea}</p>
          <p><strong>Estado:</strong> ${location.estado}</p>
          <p><strong>Causal:</strong> ${location.causal_estado}</p>
          <div style="display: flex; gap: 10px; margin-top: 10px;">
              <div>
                  <p><strong>Foto Local:</strong></p>
                  <img src="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F${this.fechaInicio}%2F${negocio}%2F${agencia}%2FE${location.NumeroPed}2.jpg?alt=media" alt="Foto Local" 
                       style="width: 300px; height: auto; border-radius: 5px; display: none;" 
                       onload="this.style.display='block';" 
                       onerror="this.style.display='none';">
              </div>
              <div>
                  <p><strong>Foto Factura:</strong></p>
                  <img src="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F${this.fechaInicio}%2F${negocio}%2F${agencia}%2FE${location.NumeroPed}1.jpg?alt=media" alt="Foto Factura" 
                       style="width: 300px; height: auto; border-radius: 5px; display: none;" 
                       onload="this.style.display='block';" 
                       onerror="this.style.display='none';">
              </div>
          </div>
      ` 
    });

    markerEnvio.addListener("click", () => {
      this.map.setCenter(marker.getPosition() as google.maps.LatLng);
      this.map.setZoom(16); // Nivel de zoom al hacer clic
      infoWindowEnvio.open(this.map, markerEnvio);
    });
  }
    // 🔹 Marcador con Círculo de Color
    const marker =    new google.maps.Marker({
      position: { lat: location.lat, lng: location.lon },
      map: this.map,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 0.8,
        strokeColor: location.estado === "EXITOSA" ? "green" : 
        location.estado === "NOVEDAD" ? "orange" : 
        location.estado === "PARCIAL" ? "blue" : "red",
        strokeWeight: 3,
        scale: 10, // Tamaño del círculo
      }
    });







  new google.maps.Marker({
    position: { lat: location.latTarea, lng: location.lonTarea },
    map: this.map,
    label: {
      text:location.orden.toString(), // Muestra el número en el centro
      color: "#000", // Color del número
      fontSize: "8px",
      fontWeight: "normal",
    },
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 0, // Hace invisible el ícono, solo se verá el texto
    }
  });


    if(location.latTarea > 0.0 && location.estado != "PENDIENTE"){

    

    this.drawDottedLine(this.map, { lat: location.latTarea, lng: location.lonTarea },{ lat: location.lat, lng: location.lon })
    
  
  }
const infoWindow = new google.maps.InfoWindow({
  content: `
      <h4>${location.NombredeCliente}</h4>
      <p><strong>Dirección:</strong> ${location.Direccion}</p>
      <p><strong>Geo Dirección:</strong> ${location.direccion_geo}</p>
      <p><strong>Placa:</strong> ${location.Placa}</p>
      <p><strong>Punto:</strong> ${location.lon} / ${location.lat}</p>
      <p><strong>Reporte:</strong> ${location.lonTarea} / ${location.latTarea}</p>
      <p><strong>Estado:</strong> ${location.estado}</p>
      <p><strong>Causal:</strong> ${location.causal_estado}</p>
      <div style="display: flex; gap: 10px; margin-top: 10px;">
          <div>
              <p><strong>Foto Local:</strong></p>
              <img src="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F${this.fechaInicio}%2F${negocio}%2F${agencia}%2FE${location.NumeroPed}2.jpg?alt=media" alt="Foto Local" 
                   style="width: 300px; height: auto; border-radius: 5px; display: none;" 
                   onload="this.style.display='block';" 
                   onerror="this.style.display='none';">
          </div>
          <div>
              <p><strong>Foto Factura:</strong></p>
              <img src="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F${this.fechaInicio}%2F${negocio}%2F${agencia}%2FE${location.NumeroPed}1.jpg?alt=media" alt="Foto Factura" 
                   style="width: 300px; height: auto; border-radius: 5px; display: none;" 
                   onload="this.style.display='block';" 
                   onerror="this.style.display='none';">
          </div>
      </div>
  ` 
});



  // Hacer zoom y centrar el mapa al hacer clic en el marcador
  marker.addListener("click", () => {
      this.map.setCenter(marker.getPosition() as google.maps.LatLng);
      this.map.setZoom(16); // Nivel de zoom al hacer clic
      infoWindow.open(this.map, marker);
  });
    // 🔹 Marcador con Número en el Centro
    new google.maps.Marker({
      position: { lat: location.lat, lng: location.lon },
      map: this.map,
      label: {
        text:location.orden.toString(), // Muestra el número en el centro
        color: "#FFF", // Color del número
        fontSize: "12px",
        fontWeight: "bold",
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 0, // Hace invisible el ícono, solo se verá el texto
      }
    });
  });

  
}

 drawEllipse(pointA: google.maps.LatLngLiteral, pointB: google.maps.LatLngLiteral) {
  const numPoints = 100; // Más puntos = más suavidad
  const center = {
      lat: (pointA.lat + pointB.lat) / 2,
      lng: (pointA.lng + pointB.lng) / 2
  };

  const semiMajorAxis = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(pointA),
      new google.maps.LatLng(center)
  );

  const semiMinorAxis = semiMajorAxis * 0.1; // Ajusta según la forma deseada

  const angle = Math.atan2(pointB.lat - pointA.lat, pointB.lng - pointA.lng);
  const ellipseCoords: google.maps.LatLngLiteral[] = [];

  for (let i = 0; i < numPoints; i++) {
      const theta = (i / numPoints) * 2 * Math.PI;
      const x = semiMajorAxis * Math.cos(theta);
      const y = semiMinorAxis * Math.sin(theta);

      // Rotar el punto usando la orientación entre los puntos
      const newX = x * Math.cos(angle) - y * Math.sin(angle);
      const newY = x * Math.sin(angle) + y * Math.cos(angle);

      ellipseCoords.push({
          lat: center.lat + newY / 111320, // Convertir metros a grados latitud
          lng: center.lng + newX / (111320 * Math.cos(center.lat * (Math.PI / 180))) // Convertir metros a grados longitud
      });
  }

  new google.maps.Polygon({
      paths: ellipseCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "",
      fillOpacity: 0.35,
      map: this.map
  });
}
 drawDottedLine(map: google.maps.Map, start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral, steps: number = 10) {
  const line = new google.maps.Polyline({
    path: [start, end],
    geodesic: true,
    strokeOpacity: 0, // 🔹 Oculta la línea principal
    icons: [{
        icon: {
            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            scale: 1, // 🔹 Aumenta el tamaño de la flecha
            strokeOpacity: 1,
            strokeWeight: 2,
            fillOpacity: 1,
            fillColor: "#FF0000"
        },
        offset: "0",
        repeat: "10px" // 🔹 Mayor distancia entre flechas
    }],
    map: map
  });
}



generarColorFosforescenteOscuro(): string {
  
  const baseColors = [
    [180, 30, 30],  // Rojo profundo
    [30, 180, 30],  // Verde intenso
    [30, 30, 180],  // Azul eléctrico
    [160, 30, 160], // Púrpura oscuro
    [30, 160, 160], // Cian profundo
    [180, 100, 30]  // Naranja oscuro
  ];

  // Selecciona un color base aleatorio
  const base = baseColors[Math.floor(Math.random() * baseColors.length)];

  // Genera una variación fuerte dentro de un rango oscuro
  const r = Math.max(30, Math.min(200, base[0] + Math.floor(Math.random() * 80 - 40))); 
  const g = Math.max(30, Math.min(200, base[1] + Math.floor(Math.random() * 80 - 40))); 
  const b = Math.max(30, Math.min(200, base[2] + Math.floor(Math.random() * 80 - 40))); 
  const alpha = 0.9; // Mantiene alta opacidad pero con un leve toque translúcido

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

}

class ColorGenerator {
  private usedColors: Set<string> = new Set();

  generarColorFosforescenteOscuro(): string {
    let color: string;
    let attempts = 0; // Evita bucles infinitos

    do {
      // Colores base oscuros pero vibrantes
      const baseColors = [
        [180, 30, 30],  // Rojo profundo
        [30, 180, 30],  // Verde intenso
        [30, 30, 180],  // Azul eléctrico
        [160, 30, 160], // Púrpura oscuro
        [30, 160, 160], // Cian profundo
        [180, 100, 30]  // Naranja oscuro
      ];

      // Selecciona un color base aleatorio
      const base = baseColors[Math.floor(Math.random() * baseColors.length)];

      // Genera una variación fuerte dentro de un rango oscuro
      const r = Math.max(30, Math.min(200, base[0] + Math.floor(Math.random() * 80 - 40))); 
      const g = Math.max(30, Math.min(200, base[1] + Math.floor(Math.random() * 80 - 40))); 
      const b = Math.max(30, Math.min(200, base[2] + Math.floor(Math.random() * 80 - 40))); 
      const alpha = 0.9;

      color = `rgba(${r}, ${g}, ${b}, ${alpha})`;
      attempts++;

      // Si se generan demasiados intentos sin éxito, se rompe el bucle
      if (attempts > 10) break;

    } while (this.usedColors.has(color)); // Verifica si ya existe en el historial

    // Agrega el color al historial para evitar duplicados
    this.usedColors.add(color);

    return color;
  }

  resetColors(): void {
    this.usedColors.clear(); // Limpia el historial si es necesario
  }
  

  
}
function chunkArray(array: any[], chunkSize: number): any[][] {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

