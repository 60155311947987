import { Component, OnInit, Input,SimpleChanges } from '@angular/core';
import { OrdersService } from '../../orders.service';
import { Subscription } from 'rxjs';
import { ClientesService } from '../../clientes.service';
import { PedidoService } from '../../pedidos.service';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';

@Component({
  selector: 'app-widget-relojefectividad',
  templateUrl: './relojefectividad.component.html',
  styleUrls: ['./relojefectividad.component.scss']

})
export class RelojEfectividadComponent implements OnInit {
  private subscription: Subscription;
  @Input() data: any = [];
  updateFlag = false;
  private rutasDescarga: any;
  myConfiges: any
  enviados=0
  operacion=0
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0

  fechaInicio: string = '';
  fechaFin: string = '';

  private fechaSubs: Subscription = new Subscription();
  private negocio: any;
  private agencia: any;

  constructor(private ClientesService: ClientesService,private pedidoService: PedidoService,private indicadoresService: OrdersService,private resumenService: ResumenTareasService) { }


  ngOnInit(): void {


    if (!this.subscription) {
      this.subscription = this.resumenService.resumen$.subscribe((data) => {
        console.log('📌 Resumen recibido:', data);
        this.estadoPendiente=0
        this.estadoExitosa=0
        this.estadoNovedad=0
        this.estadoRepisar=0
        this.estadoParcial=0

        if (data !== null){

        for (let tarea of data.totalPorEstado) {
  
          if(tarea.estado=="PENDIENTE"){
  
            this.estadoPendiente=tarea.count
        
           }
  
           if(tarea.estado=="EXITOSA"){
  
            this.estadoExitosa=tarea.count
        
           }
  
  
           if(tarea.estado=="NOVEDAD"){
  
            this.estadoNovedad=tarea.count + this.estadoNovedad
        
           }
  
  
           if(tarea.estado=="REPISAR"){
  
            this.estadoNovedad=tarea.count + this.estadoNovedad
        
           }
  
           if(tarea.estado=="PARCIAL"){
  
            this.estadoNovedad=tarea.count +  this.estadoNovedad
        
           }
  
        }
   }

        this.myConfiges = {
          type: "ring",
          plot: {
            borderColor: "#2B313B",
            borderWidth: 0.8,
            slice: "50%",
          
            tooltip: {
              fontSize: '18',
              fontFamily: "Open Sans",
              padding: "5 10",
              text: "%npv%"
            },
            animation: {
              effect: 2,
              method: 5,
              speed: 900,
              sequence: 1,
              delay: 3000
            }
          },
          source: {
            text: '',
            fontColor: "#8e99a9",
            fontFamily: "Open Sans"
          },
         
          plotarea: {
            margin: "0 0 0 0"
          },
          series: [{
              values: [this.estadoPendiente],
              text: "Pendiente",
              backgroundColor: '#ee630d',
            },
            {
              values: [this.estadoExitosa],
              text: 'Exitosa',
              backgroundColor: '#468189',
              detached: true
            },
            {
              text: 'Novedad',
              values: [this.estadoNovedad],
              backgroundColor: '#FFA060'
            }
          ]
        };
     
      });
    }

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}