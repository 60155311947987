import { BarriosService } from '../../shared/barrios.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ResumenTareasService } from 'src/app/services/resumen-tareas.service';


@Component({

  selector: 'app-FragmentRoutingModel',
  templateUrl: './FragmentRoutingModel.component.html',
  styleUrls: ['./FragmentRoutingModel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentRoutingModel implements OnInit {
  activeTab: string = 'formulario'; // Establece pestaña inicial

 
  Remove(args): void {
    args.cancel = true;
  }

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };


  constructor(private resumenTareasService: ResumenTareasService, private userd: AuthService, public toastr: ToastrManager) {

  }
  modelo = {
    nombre: '',
    sucursal: '',
    descripcion: '',
    horaInicio: '',
    horaFinal: '',
    latitudInicial: null,
    longitudInicial: null,
    latitudFinal: null,
    longitudFinal: null,
    agencia: '',
    negocio: '',
  };

  modelos: any[] = [];

  isMenuOpen: boolean = true; // Menú abierto por defecto
  selectedOption: string = 'model1'; // Opción inicial

  toggleMenu(sidenav: MatSidenav) {
    sidenav.toggle(); // Abre o cierra el menú
  }

  selectOption(option: string, sidenav: MatSidenav) {
    this.selectedOption = option;
    if (window.innerWidth < 768) {
      sidenav.close(); // Cierra el menú en dispositivos móviles
    }
  }
  cambiarVista(tab: string): void {
    this.activeTab = tab;
  }
  guardarModelo() {

    this.modelo.negocio = localStorage.getItem("negocio");
    this.modelo.agencia = localStorage.getItem("agencia");

    this.resumenTareasService.guardarModelo(this.modelo).subscribe(
      (res) => {
   
        this.resetFormulario();
        this.obtenerModelos()
      },
      (error) => {
        console.error('Error al guardar el modelo', error);

      }
    );


  }

  eliminarModelo(id: string, index: number) {
    if (confirm('¿Está seguro de eliminar este modelo?')) {
      this.resumenTareasService.eliminarModelo(id).subscribe(
        res => {
      
          // Eliminar el modelo del array para actualizar la vista
          this.modelos.splice(index, 1);
        },
        error => {
          console.error('Error al eliminar el modelo:', error);
        
        }
      );
    }
  }

  obtenerModelos() {
    this.resumenTareasService.getModelos(localStorage.getItem("negocio"), localStorage.getItem("agencia")).subscribe(
      (data) => {
        this.modelos = data;
  
      },
      (error) => {
        console.error('Error al obtener los modelos', error);
      }
    );
  }



  resetFormulario() {
    this.modelo = {
      nombre: '',
      sucursal: '',
      descripcion: '',
      horaInicio: '',
      horaFinal: '',
      latitudInicial: null,
      longitudInicial: null,
      latitudFinal: null,
      longitudFinal: null,
      agencia: '',
      negocio: '',
    };
  }
  ngOnInit(): void {
    this.obtenerModelos()
    this.activeTab = 'nuevo'; // Establece pestaña inicial


  }


}
