import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { FragmentTareas } from './modules/fragmentTareas/fragmentTareas.component';
import { FragmentIndicadoresapi } from './modules/fragmentIndicadoresapi/fragmentIndicadoresapi.component';
import { FragmentIndicadoresRuta } from './modules/fragmentIndicadoresRuta/fragmentIndicadoresRuta.component';
import { FragmentGeoTareas } from './modules/fragmentGeoTareas/fragmentGeoTareas.component';
import { LogininicioComponent } from './components/auth/loginincio/logininicio.component';
import { FragmentReporte } from 'src/app/modules/fragmentReportes/fragmentReporte.component';
import { FragmentRutas } from './modules/fragmentRutas/fragmentRutas.component';

import { FragmentArticulos } from './modules/fragmentArticulos/fragmentArticulos.component';
import { FragmentClientes } from './modules/fragmentClientes/fragmentClientes.component';
import { FragmentContactos } from './modules/fragmentContactos/fragmentContactos.component';
import { FragmentPrecios } from './modules/fragmentPrecios/fragmentPrecios.component';
import { FragmentRutero } from './modules/fragmentRutero/fragmentRutero.component';

import { FragmentPrefijos } from './modules/fragmentPrefijos/fragmentPrefijos.component';
import { FragmenClientesAprobacion } from './modules/fragmentClienteAprobacion/fragmenClientesAprobacion.component';
import { FragmentAprobar } from './modules/fragmentAprobar/fragmentAprobar.component';
import { FragmenPedidos } from './modules/fragmentPedidos/fragmentPedidos.component';
import { FragmentDetalle } from './modules/fragmentDetalle/fragmentDetalle.component';
import { FragmentIndicadoresPedidos } from './modules/fragmentIndicadorespedidos/fragmentIndicadorespedidos.component';
import { FragmentIndicadoresmes } from './modules/fragmentIndicadoresmes/fragmentIndicadoresmes.component';
import { FragmentIndicadoresRuteros } from './modules/fragmentIndicadoresRuteros/fragmentIndicadoresRuteros.component';
import { FragmentMapaGeneral } from './modules/fragmentMapaGeneral/fragmentMapaGeneral.component';
import { FragmentAsesores } from './modules/fragmentGeoAsesores/fragmentAsesores.component';
import { FragmenClientesActulizacion } from './modules/fragmentClienteActulizacion/fragmenClientesActulizacion.component';
import { FragmentActulizacion } from './modules/fragmentActulizacion/fragmentActulizacion.component';
import { FragmentReportePreventa } from './modules/fragmentReportesPreventa/fragmentReportePreventa.component';
import { FragmentNavegador } from './modules/fragmentNavegador/fragmentNavegador.component';
import { FragmentAdministracion } from './modules/fragmentAdministracion/fragmentAdministracion.component';
import { FragmentFotoTarea } from './modules/fragmentFotoTarea/fragmentFotoTarea.component';
import { FragmentCargarTareas } from './modules/slcweb/fragmentCargarTareas/fragmentCargarTareas.component';
import { FragmentDetalleOperacion } from './modules/slcweb/fragmentDetalleOperacion/fragmentDetalleOperacion.component';
import { FragmentOrdenTransportes } from './modules/slcweb/ordenTransporte/fragmentOrdenTransportes/fragmentOrdenTransportes.component';
import { fragmentMapsOT } from './modules/slcweb/fragmentMapsOT/fragmentMapsOT.component';
import { FragmentBancos } from './modules/slcweb/fragmentBancos/fragmentBancos.component';
import { FragmentAuditoriaBancos } from './modules/slcweb/fragmentAuditoriaBancos/fragmentAuditoriaBancos.component';
import { FragmentTipoDoc } from './modules/slcweb/tipoDoc/fragmentTipoDoc/fragmentTipoDoc.component';
import { FragmentAuditoriaTipoDoc } from './modules/slcweb/tipoDoc/fragmentAuditoriaTipoDoc/fragmentAuditoriaTipoDoc.component';
import { FragmentTipoTel } from './modules/slcweb/tipoTel/fragmentTipoTel/fragmentTipoTel.component';
import { FragmentAuditoriaTipoTel } from './modules/slcweb/tipoTel/fragmentAuditoriaTipoTel/fragmentAuditoriaTipoTel.component';
import { FragmentTipoOperacion } from './modules/slcweb/tipoOpereacion/fragmentTipoOperacion/fragmentTipoOperacion.component';
import { FragmentAuditoriaTipoOp } from './modules/slcweb/tipoOpereacion/fragmentAuditoriaTipoOp/fragmentAuditoriaTipoOp.component';
import { FragmentTipoVehiculo } from './modules/slcweb/tipoveniculos/fragmentTipoVehiculo/fragmentTipoVehiculo.component';
import { FragmentAuditoriaTipoVehiculo } from './modules/slcweb/tipoveniculos/fragmentAuditoriaTipoVehiculo/fragmentAuditoriaTipoVehiculo.component';
import { FragmentMarcaVehiculo } from './modules/slcweb/marcavehiculo/fragmentMarcaVehiculo/fragmentMarcaVehiculo.component';
import { FragmentAuditoriaMarca } from './modules/slcweb/marcavehiculo/fragmentAuditoriaMarca/fragmentAuditoriaMarca.component';
import { FragmentVehiculo } from './modules/slcweb/vehiculo/fragmentVehiculo/fragmentVehiculo.component';
import { FragmentConfiVh } from './modules/slcweb/configuracionVehiculo/fragmentConfiVh/fragmentConfiVh.component';
import { FragmentAuditoriaConfiVh } from './modules/slcweb/configuracionVehiculo/fragmentAuditoriaConfiVh/fragmentAuditoriaConfiVh.component';
import { FragmentAuditoriaVehiculo } from './modules/slcweb/vehiculo/fragmentAuditoriaVehiculo/fragmentAuditoriaVehiculo.component';
import { FragmentTercero } from './modules/slcweb/tercero/fragmentTercero/fragmentTercero.component';
import { FragmentDepartamentos } from './modules/slcweb/localizacion/deparmentos/fragmentDepartamentos/fragmentDepartamentos.component';
import { FragmentAuditoriaDepartamento } from './modules/slcweb/localizacion/deparmentos/fragmentAuditoriaDepartamento/fragmentAuditoriaDepartamento.component';
import { FragmentCiudades } from './modules/slcweb/localizacion/ciudad/fragmentCiudades/fragmentCiudades.component';
import { FragmentAuditoriaCiudad } from './modules/slcweb/localizacion/ciudad/fragmentAuditoriaCiudad/fragmentAuditoriaCiudad.component';
import { FragmentBarrio } from './modules/slcweb/localizacion/barrios/fragmentBarrio/fragmentBarrio.component';
import { FragmentAuditoriaBarrio } from './modules/slcweb/localizacion/barrios/fragmentAuditoriaBarrio/fragmentAuditoriaBarrio.component';
import { FragmentAuditoriaTerceros } from './modules/slcweb/tercero/fragmentAuditoriaTerceros/fragmentAuditoriaTerceros.component';
import { FragmentTransportador } from './modules/slcweb/transportador/fragmentTransportador/fragmentTransportador.component';
import { FragmentTransporte } from './modules/slcweb/transportador/fragmentTransporte/fragmentTransporte.component';
import { FragmentPlanilla } from './modules/slcweb/planilla/fragmentPlanilla/fragmentPlanilla.component';
import { FragmentPlanillaDetalle } from './modules/slcweb/planilla/fragmentPlanillaDetalle/fragmentPlanillaDetalle.component';
import { FragmentPlanillaRecaudo } from './modules/slcweb/planilla/fragmentPlanillaRecaudo/fragmentPlanillaRecaudo.component';
import { FragmentMaestroArticulos } from './modules/slcweb/maestros/fragmentMaestroArticulos/fragmentMaestroArticulos.component';
import { FragmentMaestroClientes } from './modules/slcweb/maestros/fragmentMaestroClientes/fragmentMaestroClientes.component';
import { FragmentIndicadoresVrt } from './modules/slcweb/fragmentIndicadoresVrt/fragmentIndicadoresVrt.component';
import { FragmentMpasTransporte } from './modules/slcweb/fragmentMpasTransporte/fragmentMpasTransporte.component';
import { FragmentIndicadoresapiTransporte } from './modules/fragmentIndicadoresapiTransporte/fragmentIndicadoresapiTransporte.component';
import { FragmentEncuestas } from './modules/slcweb/ordenTransporte/fragmentEncuestas/fragmentEncuestas.component';
import { FragmentDetalleEncuesta } from './modules/slcweb/planilla/fragmentDetalleEncuesta/fragmentDetalleEncuesta.component';
import { FragmentTerceroSeguridadVial } from './modules/slcweb/tercero/fragmentTerceroSeguridadVial/fragmentTerceroSeguridadVial.component';
import { FragmentIndicadoContent } from './modules/fragmentIndicadoContent/fragmentIndicadoContent.component';
import { FragmentTerceroCreacionEncuestas } from './modules/slcweb/tercero/fragmentTerceroCreacionEncuestas/fragmentTerceroCreacionEncuestas.component';
import { FragmentEncuestasParqueadero } from './modules/slcweb/ordenTransporte/fragmentEncuestasParqueadero/fragmentEncuestasParqueadero.component';
import { FragmentDetalleVehiculo } from './modules/slcweb/planilla/fragmentDetalleVehiculo/fragmentDetalleVehiculo.component';
import { FragmentMapaGeneralRuteo } from './modules/fragmentMapaGeneralRuteo/fragmentMapaGeneralRuteo.component';
import { FragmentRuteadorContent } from './modules/fragmentRuteadorContent/fragmentRuteadorContent.component';


const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{
    path: 'des',
    component: FragmentIndicadoContent
  },
  {
    path: 'Routing',
    component: FragmentRuteadorContent
  },
  {
    path: 'login',
    component: LogininicioComponent
  }
  ,
  {
    path: 'TareasExitosas',
    component: FragmentIndicadoresapiTransporte
  },
  {
    path: 'administracion',
    component: FragmentAdministracion
  },
  {
    path: 'Articulos',
    component: FragmentArticulos
  }
  ,
  {
    path: 'Planilla',
    component: FragmentPlanilla
  } 
  ,
  {
    path: 'RecaudoPlanilla',
    component: FragmentPlanillaRecaudo
  } 
  ,
  {
    path: 'PlanillaDetalle',
    component: FragmentPlanillaDetalle
  },
  {
    path: 'Clientes',
    component: FragmentClientes
  },
  {
    path: 'Contactos',
    component: FragmentContactos
  },
  {
    path: 'Precios',
    component: FragmentPrecios
  },
  {
    path: 'Ruteros',
    component: FragmentRutero
  }
  ,
  {
    path: 'Transportador',
    component: FragmentTransportador
  }
  ,
  {
    path: 'Transporte',
    component: FragmentTransporte
  }
  ,
  {
    path: 'Pedidos',
    component: FragmenPedidos
  }
  ,
  {
    path: 'Detalle',
    component: FragmentDetalle
  },
  {
    path: 'Prefijos',
    component: FragmentPrefijos
  },
  {
    path: 'reportetareas',
    component: FragmentReporte
  },
  {
    path: 'rutas',
    component: FragmentRutas
  },
  {
    path: 'mapruta',
    component: FragmentGeoTareas
  },
  {
    path: 'novedades',
    component: FragmentIndicadoresRuta
  }
  ,
  {
    path: 'actualizacion',
    component: FragmentActulizacion
  }
  ,
  {
    path: 'aprobacion',
    component: FragmentAprobar
  },
  {
    path: 'tareas',
    component: FragmentTareas
  }
  ,
  {
    path: 'clientesAprobacion',
    component: FragmenClientesAprobacion
  }
  ,
  {
    path: 'clientesActulizacion',
    component: FragmenClientesActulizacion
  }
  ,
  {
    path: 'IndicadoresPedidos',
    component: FragmentIndicadoresPedidos
  }
  ,
  
  {
    path: 'IndicadoresRuteros',
    component: FragmentIndicadoresRuteros
  },
  {
    path: 'IndicadoresMes',
    component: FragmentIndicadoresmes
  }
  ,
  {
    path: 'Activaciones',
    component: FragmentMapaGeneral
  }
  ,
  {
    path: 'AsesoresGeo',
    component: FragmentAsesores
  }
  ,
  {
    path: 'ReportePreventa',
    component: FragmentReportePreventa
  }
  ,
  {
    path: 'FragmentTerceroCreacionEncuestas',
    component: FragmentTerceroCreacionEncuestas
  }
  ,
  {
    path: '',
    component: LogininicioComponent
  }
  ,
  {
    path: 'CargarTareas',
    component: FragmentCargarTareas
  }
  ,
  {
    path: 'Departamentos',
    component: FragmentDepartamentos
  }
  ,
  {
    path: 'DetalleOperacion',
    component: FragmentDetalleOperacion
  }
  ,
  {
    path: 'OrdenTransporte',
    component: FragmentOrdenTransportes
  },
  {
    path: 'FragmentEncuestas',
    component: FragmentEncuestas
  },
  {
    path: 'FragmentDetalleEncuesta',
    component: FragmentDetalleEncuesta
  }
  ,
  
  {
    path: 'MapsOrden',
    component: fragmentMapsOT
  }
  ,
  {
    path: 'Bancos',
    component: FragmentBancos
  }
  ,
  {
    path: 'Terceros',
    component: FragmentTercero
  }
  ,
  {
    path: 'AuditoriaBancos',
    component: FragmentAuditoriaBancos
  }
  ,
  {
    path: 'AuditoriaTercero',
    component: FragmentAuditoriaTerceros
  }
  ,
  {
    path: 'AuditoriaRegistroVehiculo',
    component: FragmentAuditoriaVehiculo
  }
  ,
  {
    path: 'AuditoriaDepartamentos',
    component: FragmentAuditoriaDepartamento
  },
  {
    path: 'detalleVehiculo',
    component: FragmentDetalleVehiculo
  }
  ,
  {
    path: 'AuditoriaVehiculo',
    component: FragmentAuditoriaTipoVehiculo
  }
  
  ,
  {
    path: 'AuditoriaMarca',
    component: FragmentAuditoriaMarca
  }
  ,
  {
    path: 'AuditoriaBarrios',
    component: FragmentAuditoriaBarrio
  }
  ,
  {
    path: 'Ruteo',
    component: FragmentMapaGeneralRuteo
  }
  ,
  {
    path: 'AuditoriaTipoDoc',
    component: FragmentAuditoriaTipoDoc
  }
  ,
  {
    path: 'AuditoriaConfiVh',
    component: FragmentAuditoriaConfiVh
  }
  ,
  {
    path: 'TipoDoc',
    component: FragmentTipoDoc
  }
  ,
  {
    path: 'MarcasVehiculos',
    component: FragmentMarcaVehiculo
  }
  ,
  {
    path: 'ConfiVehiculos',
    component: FragmentConfiVh
  }
  ,
  {
    path: 'Vehiculos',
    component: FragmentVehiculo
  }
  ,
  {
    path: 'AuditorioTipoOp',
    component: FragmentAuditoriaTipoOp
  }
  ,
  {
    path: 'TipoTel',
    component: FragmentTipoTel
  }
  ,
  {
    path: 'Parqueadero',
    component: FragmentEncuestasParqueadero
  }
  ,
  {
    path: 'TipoOperacion',
    component: FragmentTipoOperacion
  }
  ,
  {
    path: 'TipoVehiculo',
    component: FragmentTipoVehiculo
  }
  ,
  {
    path: 'AuditoriaTipoTel',
    component: FragmentAuditoriaTipoTel
  }
  ,
  {
    path: 'AuditoriaCiudad',
    component: FragmentAuditoriaCiudad
  }
  ,
  {
    path: 'Ciudad',
    component: FragmentCiudades
  }
  ,
  {
    path: 'Barrios',
    component: FragmentBarrio
  }
  ,
  {
    path: 'FragmentMpasTransporte',
    component: FragmentMpasTransporte
  }
  ,
  {
    path: 'FragmentMaestroArticulos',
    component: FragmentMaestroArticulos
  }
  ,
  {
    path: 'FragmentMaestroClientes',
    component: FragmentMaestroClientes
  },
  {
    path: 'FragmentIndicadoresVrt',
    component: FragmentIndicadoresVrt
  },
  {
    path: 'FragmentTerceroSeguridadVial',
    component: FragmentTerceroSeguridadVial
  }
  ,
  {
    path: 'Navegador',
    component: FragmentNavegador
  },{
    path: 'fototarea',
    component: FragmentFotoTarea
  }]
}];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
