
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogEditarTarea',
  templateUrl: './dialogEditarTarea.component.html',
  styleUrls: ['./dialogEditarTarea.component.scss']
})
export class DialogEditarTarea {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogEditarTarea>,
    @Inject(MAT_DIALOG_DATA) public data: any // Datos de la tarea
  ) {
    // Inicializar el formulario con los datos de la tarea
    this.form = this.fb.group({
      NombredeCliente: [data.NombredeCliente, Validators.required],
      Direccion: [data.Direccion, Validators.required],
      RutaVLI: [data.RutaVLI, Validators.required],
      Placa: [data.Placa, Validators.required],
      NumeroPed: [data.NumeroPed, Validators.required],
      CostoMerc: [data.CostoMerc, Validators.required],
      horaentrega: [data.horaentrega, Validators.required],
      orden: [data.orden, Validators.required],
      lat: [data.lat, Validators.required],
      lon: [data.lon, Validators.required],
      PesoNeto: [data['Peso neto'], Validators.required],
      Zona: [data.Zona, Validators.required],
      Transporte: [data.Transporte, Validators.required],
      Telefono: [data.Telefono, Validators.required],
      usuario: [data.usuario, Validators.required],
      ventanaInicialAm: [data.ventanaInicialAm, Validators.required],
      ventanaFinalAm: [data.ventanaFinalAm, Validators.required]
    });
  }

  guardar(data:any): void {
    if (this.form.valid) {
      this.dialogRef.close(data); // Enviar los datos editados
    }
  }

  cancelar(): void {
    this.dialogRef.close(); // Cerrar el diálogo sin guardar
  }
}